import React from 'react'
import './ChatBox.css'
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { styled } from '@mui/material/styles';
import { Fab } from '@mui/material';
import { Close } from '@mui/icons-material';

const SmallFab = styled(Fab)({
  width: '24px', // Adjust the width to your preference
  height: '30px', // Adjust the height to your preference
});

const SmallCloseIcon = styled(Close)({
  fontSize: '24px', // Adjust the font size to make the icon smaller
});


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: '#F9AE00',
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  
  function stringAvatar(name) {
    if(name.split(' ').length > 1){
      return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
    }
    return {
      children: `${name.split(' ')[0][0]}`,
    };
  }
  

const ChatBox = ({user,message,removeMessage}) => {

const longDate = "2024-04-01 09:50:31";
const options = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "2-digit",
  hour12: true,
};

  const handleRemoveMessage = (message)=>{
    console.log(message)
    removeMessage(message.id);
  }

  return (
    <div className={`flex ${user ? 'flex-col-reverse':'flex-col'}`}>
        <div className={`flex ${user ? 'justify-end mt-2':'mb-2'}`}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              { message ? (
                <SmallAvatar {...stringAvatar(message?.user.name)} />
              ):(
                <SmallAvatar {...stringAvatar(message.user.name)} />
              )}
              
            </StyledBadge>
        </div>
        <div className="relative text-container">
          <div className={`${user ? 'block':'hidden'} absolute top-[-25px] right-[-25px]`}>
          <Fab style={{ transform: 'scale(0.4)' }} aria-label="edit" onClick={()=>handleRemoveMessage(message)}>
            <SmallCloseIcon />
          </Fab>
          </div>
          <p className={user ? 'chat_box_user text':'chat_box'}>{message.content} <p className='text-[0.75rem]'>{new Date(message.creationDate).toLocaleString("es-MX", options)
                                     .toLocaleUpperCase("es-MX")}</p></p>

        </div>
    </div>
  )
}

export default ChatBox