import React,{useEffect,useState,useRef} from 'react'
import {Chip,Modal} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid,esES } from '@mui/x-data-grid';
import { addVideo,getAllVideos } from '../../../services/videos';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { IconButton } from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';
import VideoUploadStepper from '../Stepper/Video/StepperVideoUpload';
import RemoveForm from '../Stepper/Video/RemoveForm';
import UpdateVideoForm from '../Stepper/Video/UpdateVideoForm';


const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES, // x-data-grid translations
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)'
  };

  const useStyles = {
    uploadBox: {
      background: "rgba(255, 255, 255, 0.25)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      borderRadius: "10px",
      border: "1px solid rgba(255, 255, 255, 0.18)",
      transition: "transform 0.3s ease-in-out", // Add a transition for a smooth effect
      '&:hover': {
        transform: 'scale(1.05)', // Example: Scale the box on hover
        // Add additional styles as needed
      },
    },
  };



  const filter = createFilterOptions();
  


const VideosTable = () => {

  const [videosList, setVideosList] = useState([]);
  const [currentVideo,setCurrentVideo] = useState();
  const [activeOption,setActiveOption] =useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error,setError] = useState();
  const [open, setOpen] = useState(false);


    useEffect(()=>{
      getAllVideosList();
    },[])

    const columns = [,
      { field: 'videoId', headerName: 'ID', width: 300 },
      { field: 'title', headerName: 'Titulo', width: 300 }, 
      { field: 'tags', headerName: 'Tags', width: 320, renderCell: (params) => (
        <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
        {params.value.slice(0, 2).map((plan, index) => (
          <Chip key={index} label={`${plan.substring(0, 7)}...`} style={{ marginRight: '4px',background:'#BED8DA' }} />
          ))}
          {params.value.length > 2 && (
              <Chip key="more" label={`+${params.value.length - 2} mas`} style={{ marginRight: '4px',background:'#BED8DA' }} />
          )}
      </ul>
        ), },
        { field: 'allowedSubscriptions', headerName: 'Subscripciones', width: 300, renderCell: (params) => (
          <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
            {params.value.slice(0, 2).map((plan, index) => (
              <Chip key={index} label={`${plan.substring(0, 7)}...`} style={{ marginRight: '4px',background:'#F9AE00' }} />
              ))}
              {params.value.length > 2 && (
                  <Chip key="more" label={`+${params.value.length - 2} mas`} style={{ marginRight: '4px',background:'#F9AE00' }} />
              )}
          </ul>
        ), },
        { field: 'actions', headerName: 'Acciones', width: 300, renderCell: (params) => (
          <div className="flex gap-2">
            <IconButton aria-label="info" size="small" onClick={() =>updateVideo(params.row)}>
              <Info />
            </IconButton>
            <IconButton aria-label="remove" size="small" onClick={() =>removeVideo(params.row)}>
              <Delete />
            </IconButton>
          </div>
        ), }
    ];
  

    const handleOpen = async (option) => {
      try {
        setOpen(true);
        setActiveOption(option);
      } catch (error) {
        console.error('Error while opening:', error);
      }
    };

    const handleClose = () => {
      setOpen(false)
    };
  
    const getAllVideosList =  async () => {
        try{     
            setIsLoading(true);
            const response = await getAllVideos();
            const data = response.data;
            setVideosList(data)
        }catch(error){
            console.log(error);
            setError(error)
        }finally{
            setIsLoading(false);
        }
      };

    const getVideoInfo=(video) =>{
      try {
       setCurrentVideo(video);
       
      } catch (error) {
        console.log(error) 
      }
    }

    const removeVideo=(video) =>{
      setCurrentVideo(video);
      handleOpen('remove');
    }

    const updateVideo=(video) =>{
      setCurrentVideo(video);
      handleOpen('update');
    }

    const refreshData=()=>{
      handleClose();
      getAllVideosList();
    }
    
    const handleVideoOption =(action) =>{
      switch (action) {
        case 'add':
          return <VideoUploadStepper/>;
        case 'update':
          return <UpdateVideoForm video={currentVideo} handleClose={handleClose} refreshData={refreshData}/>;
        case 'remove':
          return <RemoveForm video={currentVideo} handleClose={handleClose} refreshData={refreshData}/>;
        default:
          return <VideoUploadStepper/>;
      }
    }
    
  return (
    <div style={{ height: 'auto', width: '100%' }}>

        
    {isLoading ? (
      <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ): error ? (
        <div>Error: {error.message}</div>
      ) : (
        <ThemeProvider theme={theme}>
        <DataGrid
          rows={videosList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          getRowId={(row) => row.videoId}
          autoHeight
        />
        </ThemeProvider>
      )}

    <div className="flex justify-center p-5">
      <button className='button-29 w-auto'  onClick={() => handleOpen('add')}>Agregar Video</button>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >        
       {handleVideoOption(activeOption)}
    </Modal>
    </div>

     </div>
  )
}

export default VideosTable