import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {addSubscriptionPlan, findUserByEmail } from "../../../services/user";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { restartUser } from "../../../redux/userRedux";
import {
  activateOrder,
  checkToken,
  findOrderById,
} from "../../../services/order";
import { convertUnixTimeToLocalDate } from "../../../utils/timeConvertor";

interface OrderModel {
  orderId: string;
  invoiceId: string;
  planId: string; // Product Id
  productId: string;
  paymentId: string;
  chargeId: string;
  customerId:string | null;
  customerEmail: string;
  customerName: string;
  periodStart: number; // Assuming it represents a Unix timestamp
  periodEnd: number; // Assuming it represents a Unix timestamp
  subscriptionId: string;
  receiptUrl: string;
  token: string;
  tokenActive: boolean;
  created: Date;
}

interface SubscriptionModel{
  id: string,
  customerId: string | null,
  planId: string,
  startDate: Date,
  endDate: Date,
  isActive: boolean
}

type ChildProps = {
  handleRegister: (value: boolean) => void;
};

const RegisterFormActiveUser:React.FC<ChildProps> = (props)=> {

  const [order, setOrder] =useState <OrderModel>({
      orderId: "",
      invoiceId: "",
      planId: "",
      productId: "",
      paymentId: "",
      chargeId: "",
      customerId:null,
      customerEmail: "",
      customerName: "",
      periodStart: 0, // You can set an initial value here
      periodEnd: 0, // You can set an initial value here
      subscriptionId: "",
      receiptUrl: "",
      token: "",
      tokenActive: false,
      created: new Date(),
    });
  const [email, setEmail] = useState("");
  const [validEmail,setValidEmail] = useState(true)
  const [addPlanSuccess, setPlanSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [orderToken, setOrderToken] = useState('');
  const [valid, setValid] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);
  const { isFetching, error } = useSelector((state: any) => state.user);
  const match:any = useMatch("/:firstRoute/:secondRoute/:thirdRoute");
  const { firstRoute, secondRoute,thirdRoute } = match.params;
  const navigate = useNavigate();


  const dispatch = useDispatch();
  useEffect(() =>{
      getOrderDetailsById();
  },[thirdRoute])

  useEffect(() =>{
    const isErrorMessagePresent = errorMessage === 'Link Error';
    const isFormIncomplete = orderToken === '' ||  !valid;
    setIsDisabled(isErrorMessagePresent || isFormIncomplete);

  },[orderToken,valid])

  const getOrderDetailsById = async () => {
    try {
      dispatch(restartUser);
      const response: any = await findOrderById(dispatch, thirdRoute);
      if (response && response.status === 200) {
        setOrder(response.data);
        setOrderToken(response.data.token);
      } else {
        if (response && response.response && response.response.status === 404) {
          setErrorMessage("Link Error");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const checkUserEmail= async(email:string) =>{
    setErrorMessage("");
      setValidEmail(false);
      console.log(email);
    try{
      const response: any = await findUserByEmail(dispatch, email);
      console.log(email);
      if (response && response.status === 200) {
        setValidEmail(true);
      } else {
        if (response && response.response && response.response.status === 404) {
          setValidEmail(false);
          setErrorMessage("No existe ese correo");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

  }

  const checkOrderToken = async (order: OrderModel) => {
    try {
      const response: any = await checkToken(order.orderId, orderToken);
      console.log(response);
      if (response && response.status === 202) {
        setTokenValid(true);
        setErrorMessage("");
      } else if (response && response.response && response.response.data) {
        setErrorMessage(response.response.data);
      } else {
        // Handle other error cases or set a default error message
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      // Handle any exceptions that might occur during the request
      setErrorMessage("An error occurred during the request.");
    }
  };

  const orderTokenActive = async (order: OrderModel) => {
    try {
      const response: any = await activateOrder(order.orderId, orderToken);
      console.log(response);
      if (response && response.status === 202) {
        setTokenValid(true);
        setErrorMessage("");
      } else if (response && response.response && response.response.data) {
        setErrorMessage(response.response.data);
      } else {
        // Handle other error cases or set a default error message
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      // Handle any exceptions that might occur during the request
      console.error("Error:", error);
      setErrorMessage("An error occurred during the request.");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the input value matches the email pattern
    const isValid = emailPattern.test(inputValue);
    setEmail(inputValue);
    setValid(isValid);

  };


  const createSubscription = () =>{
    const subscription:SubscriptionModel={
      id:order.subscriptionId ? order.subscriptionId:order.paymentId,
      customerId:order.customerId,
      planId: order.planId,
      startDate:convertUnixTimeToLocalDate(order.periodStart),
      endDate:convertUnixTimeToLocalDate(order.periodEnd),
      isActive:true,
    }
    return subscription;
  }

  const handleAddSubscription= async (email:string,order:OrderModel) => {

    const subscription = createSubscription();
    console.log(subscription);
    const response:any = await addSubscriptionPlan(email,subscription);
    if(response && response.status === 200){ 
      setPlanSuccess(true);
      await orderTokenActive(order);
      props.handleRegister(true);
    }else{
      if (response && response.response && response.response.status === 404) {
          setValidEmail(false);
          setErrorMessage("No existe ese correo");
        }
    }

  }

  const activeSubscription= () =>{

    var planId:string =order.planId;

    try {
       checkUserEmail(email);
      if (validEmail) {
         checkOrderToken(order);
        if(tokenValid){
           handleAddSubscription(email,order);
        }   
        // Additional logic after successful checkOrderToken
      }
    }  catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred during the request.");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="py-2">
                <img src="../../assets/icons/Logo/Logo-sm.png" width={64} alt="Logo" />
          </div>
        </div>
        {addPlanSuccess ? 
        (<div className='flex flex-col justify-center items-center gap-5'>
            <p className='text-center text-xl uppercase'>Tu registro ha sido exitoso</p>
            <button className='button-30 w-fit p-2' onClick={() => navigate("/login")}>Ir a Inicio</button>
          </div>):(<div className="flex flex-col gap-5 justify-center items-center">
          <TextField
                label="Correo"
                error={!valid}
                helperText={!valid ? 'Correo Invalido' : ''}
                id="outlined-start-adornment"
                sx={{ m: 1, width: '25ch' }}
                value={email}
                onChange={handleEmailChange}
            />
            <TextField
                label="Token"
                id="outlined-start-adornment"
                sx={{ m: 1, width: '25ch' }}
                onChange={(e) => setOrderToken(e.target.value)}
                value={orderToken}
            />
            {errorMessage && (<Alert className="py-5" severity="error">{errorMessage}</Alert>)}
            <Button  onClick={() => activeSubscription()} className="button-29 w-fit p-2" variant="contained" disabled={isDisabled} >{isFetching ? <CircularProgress color="inherit" size={25} />:'Registrarse' }</Button>
        </div>)}
        
      </div>
    </>);
};

export default RegisterFormActiveUser;
