export const Options = [
    {
        title:'Usuarios',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Videos',
        icon:'../../assets/icons/Dashboard/videos.png',
    },
    {
        title:'Clases en Vivo',
        icon:'../../assets/icons/Dashboard/live.png',
    },
    {
        title:'Retos',
        icon:'../../assets/icons/Dashboard/chapter.png',
    },
    {
        title:'Mandalas',
        icon:'../../assets/icons/Dashboard/mandala-icon.png',
    },
    {
        title:'Subscripciones',
        icon:'../../assets/icons/Dashboard/subscription.png',
    }
];

export const UserAdminOptions = [
    {
        title:'Ver Usuarios',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Agregar Usuario',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Eliminar Usuario',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Buscar Usuario',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
]

export const VideosAdminOptions = [
    {
        title:'Ver Video',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Agregar Video',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Eliminar Video',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Buscar Video',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
]

export const LiveAdminOptions = [
    {
        title:'Ver Clases',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Agregar Clase',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Eliminar Clase',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
    {
        title:'Buscar Clase',
        icon:'../../assets/icons/Dashboard/usuarios.png',
    },
]