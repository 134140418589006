import React,{useEffect,useState} from 'react'
import { Menu } from './MenuData'
import { useDispatch, useSelector } from 'react-redux';
import './VerticalNavBar.css'
import { Link } from 'react-router-dom';
import { logOut } from '../../../redux/userRedux';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import UserState from '../../../Data/UserState';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


const SmallAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#F9AE00',
  width: 48,
  height: 48,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function stringAvatar(name: string) {
  if(name.split(' ').length > 1){
    return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
  }
  return {
    children: `${name.split(' ')[0][0]}`,
  };
}

  

const VerticalNavBar = () => {
  const [stickyClass, setStickyClass] = useState('relative');
  const [showVerticalMenu, setShowVerticalMenu] = useState(false);
  const userId = useSelector((state:UserState) => state.user.currentUser);
  const userName:string = userId?.fullName;


  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  function isActive(pathname:string) {
     const currentPath = window.location.pathname;
     const current = currentPath.split('/');
     const path = pathname.split('/');
    // Check if the current path contains the specified path part
    return current[1] === path[1];
  }

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setStickyClass('stickNav') : setStickyClass('');
    }
  };


  const dispatch = useDispatch();

  const handleLogout = (e: React.MouseEvent) => {
    dispatch(logOut());
  };

  

  return <>
    <nav className={`platform-navbar h-[100vh] w-48 min-w-48 bg-sb-600 py-10 flex flex-col justify-between items-center sticky top-0 ${stickyClass}`}>
        <div className='avatar-info cursor-pointer'>
          <Link to={'/user'}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              { userId ? (
                <SmallAvatar {...stringAvatar(userId?.fullName)} />
              ):(
                <SmallAvatar {...stringAvatar(userName)} />
              )}
              
            </StyledBadge>
          </Link>
          
        </div>
        <ul className={`flex flex-col w-full justify-center items-center menu-platform ${showVerticalMenu ? 'active':''}`}>
            <div className='block sm:hidden absolute top-5 left-5' onClick={()=> setShowVerticalMenu(false)}>
              <CloseIcon className="text-sc-c2 text-3xl"/>
            </div>
            {Menu.map((item,index) => (
            <Link to={item.path} key={index} className={`w-full pl-4 menu-item-p ${isActive(item.path) ? 'active' : ''}`}>
              <li className='flex gap-1 justify-start items-start'>
                      <img className="icon-img h-6 w-6 cursor-pointer" src={item.url} alt="" key={index}/>
                      <p className={` hover:text-sb-900 font-[DIN-Condensed] font-bold text-2xl md:text-lg block sm:hidden md:block ${isActive(item.path) ? 'text-sb-900' : 'text-sc-c2'}`}>{item.name}</p>
              </li>
            </Link>
            ))}
             <div className="flex sm:hidden gap-1 justify-start items-start cursor-pointer" onClick={handleLogout}>
                <img className="icon-img h-auto w-6" src="../../assets/icons/NavBar/exit.png" alt="" />
                <p className={` hover:text-sb-900 font-[DIN-Condensed] font-bold text-2xl md:text-lg block sm:hidden text-sc-c2`}>Cerrar Sesion</p>
              </div>
        </ul> 
        <div className=" flex-grow-0 md:flex-grow hidden sm:flex flex-col justify-end cursor-pointer">
          <img className="icon-img h-auto w-6" src="../../assets/icons/NavBar/exit.png" alt="" onClick={handleLogout} />
        </div>
        <div className="flex sm:hidden flex-col justify-end cursor-pointer text-2xl">
            <MenuIcon className='text-sc-c2' onClick={()=> setShowVerticalMenu(true)}/>
        </div>
    </nav>
  </>
}

export default VerticalNavBar