import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import NavBar from '../../components/Navbar/NavBar';
import "./PromoPageStyle.css"
import SocialMediaBox from '../../components/SocialMediaBox/SocialMediaBox';
import Footer from '../../components/Footer/Footer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMatch,useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fetchPromotionsBySeriesId } from '../../services/promotion';
import NotFound from '../Platform/NotFound/NotFound';

const theme = createTheme({
    palette: {
      secondary: {
        main: '#EB6E18', // Set your desired secondary color here
      },
    },
  });


type TPromotionProps={
  id:string;
  title:string;
  subtitle:string;
  thumbnail:string | null;
  backgroundImage:string | null;
  promotionImage:string | null;
  paymentLink:string;
  duration:string;
  price:number;
  productId:string;
  shortDescription:string;
  longDescription:string;
  fullDescription:string;
  listOfProducts:string[];
}

function PromoPageType1() {
    const [value, setValue] = useState('1');
    const [programInfo,setProgramInfo]=useState<TPromotionProps>();
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const match= useMatch("/:firstRoute/:secondRoute/*");
    const { firstRoute, secondRoute } = match?.params ?? {};

    useEffect(() =>{
      console.log(secondRoute);
      handlePromotionInformation();

    },[]);

    const handlePromotionInformation= async()=>{
      try{
        setIsLoading(true);
        console.log(secondRoute);
        const response = await fetchPromotionsBySeriesId(secondRoute)
        console.log(response);
        setProgramInfo(response);
      }catch(error:any){
        console.log(error)
      }finally{
        setIsLoading(false)
      }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    return (
    <>
    <NavBar/>
    {!programInfo && (
      <div className="flex justify-center items-center p-5">
        {isLoading ?  <CircularProgress />:<NotFound/>}
      </div>
    )}
    {programInfo && <>
      <section className="hero-promo !bg-[rgb(234,229,200)] !bg-none  relative flex flex-wrap gap-5 lg:gap-0 justify-center lg:justify-between items-center lg:items-start">
        <div className="flex flex-col gap-4 justify-start pt-5 md:pt-14 xl:pt-20 px-5 md:pl-20 w-full lg:w-[55%] xl:w-1/2">
        <h6 className='font-[DIN-Condensed] font-bold uppercase text-sb-500 italic text-2xl'>{programInfo.duration}</h6>
        <div className="flex font-[DIN-Condensed] font-bold uppercase">
          <h1 className='text-so-300 text-[48px] leading-10'>{programInfo.title} </h1>
        </div>
        <div className="flex justify-center w-full sm:hidden ">
          <div className="h-[300px] w-[300px] mt-0 mr-0 lg:mr-[50px] rounded-md overflow-hidden">
            <img
              className="hero-image promo-img"
              src={programInfo.thumbnail !== null ? programInfo.thumbnail:"../../assets/images/Promo/Promo_2.webp"}
              alt="Slide-1"
            />
          </div>
        </div>
        <div className="flex flex-col">
        <p className="font-[Avenir] italic font-bold text-2xl">${programInfo.price.toFixed(2)}mxn</p>
        </div>
        <div className="text-content hidden sm:block">
            <p className="text-black text-md font-[Avenir] text-justify">
              {programInfo.shortDescription}
            </p>
        </div>
        <div className="flex flex-wrap gap-5 pb-10 sm:pb-0">
            <Link to={programInfo.paymentLink}>
              <button className='button-30 w-fit p-5 rounded-full'>Comprar</button>
            </Link> 
        </div>
        </div>
        <div className="hidden sm:block h-[300px] md:h-[350px] lg:h-[400px] xl:h-[500px] w-[300px] md:w-[350px] lg:w-[400px] xl:w-[500px] mt-0 lg:mt-48 xl:mt-32 mr-0 lg:mr-[50px] rounded-md overflow-hidden">
          <img
            className="hero-image promo-img"
            src={programInfo.thumbnail !== null ? programInfo.thumbnail:"../../assets/images/Promo/Promo_2.webp"}
            alt="Slide-1"
          />
        </div>
    </section>
    <section className='pt-20 px-10 flex flex-col gap-4'>
        <div className="flex justify-left font-[DIN-Condensed] font-bold uppercase">
            <h2 className='text-[48px] leading-10'>{programInfo.subtitle}</h2>
        </div>
        <p className='font-[Avenir] text-justify text-md sm:text-xl'>{programInfo.longDescription}</p>
    </section>
    <section className='flex justify-left py-10 pl-2 sm:pl-10'>
        <ThemeProvider theme={theme}>
        <Box sx={{ width: isMobile ? '100%%' : '90%', typography: 'body1'}}>
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary"> 
                <Tab label="Descripción" value="1" sx={{ '&.Mui-selected': { color: '#EB6E18'}}} />
                <Tab label="Qué Incluye" value="2" sx={{ '&.Mui-selected': { color: '#EB6E18',borderBottom: '2px solid #EB6E18', } }} />
                </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: isMobile ? '0px' : '24px' }}>
                <div className="flex flex-wrap justify-around items-center gap-5">
                <div className="flex justify-start w-full md:w-1/2 text-justify px-5">
                  <p>{programInfo.fullDescription}</p>
                </div>
                <div className="h-[300px] w-[300px] mt-0 mr-0 lg:mr-[50px] overflow-hidden rounded-md">
                <img
                  className="hero-image promo-img"
                  src={programInfo.promotionImage !== null ? programInfo.promotionImage:"../../assets/images/Promo/Promo_2.webp"}
                  alt="Slide-1"
                />
          </div>
                </div>
                </TabPanel>
            <TabPanel value="2">
            <ul className="list-disc pl-4">
              {programInfo.listOfProducts.map((item,index)=>{
                return <li>{item}</li>
              })}
            </ul>
            </TabPanel>
            </TabContext>
            <div className="flex justify-center gap-5 py-10 sm:pb-0">
            <Link to={programInfo.paymentLink}>
              <button className='button-30 w-fit p-5 rounded-full'>Comprar</button>
            </Link>  
        </div>
        </Box>
        </ThemeProvider>
    </section>
    </>}
   
    <SocialMediaBox/>
    <Footer/>
    <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>AGOTADO</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lo lamenentamos el grupo se a completado, te invitamos estar al pendiente de nuestras redes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
    );
}

export default PromoPageType1