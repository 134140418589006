import React,{useEffect,useState,useRef} from "react";
import ChatBox from "../CardsBox/ChatBox";
import ChatInput from "../CardsBox/ChatInput";
import Loader from "../Loaders/Loader";
import { createMessage,getLastMessagesByTypeAndReference,getNextMessagesByTypeAndReference,removeMessage } from "../../services/messages";
import { useSelector } from "react-redux";

const ChatComponent = ({messageType,referenceId}) => {
    const currentUser = useSelector((state) => state.user.currentUser);
    const userName = currentUser?.fullName;
    const [messageList, setMessageList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPage,setTotalPage]=useState(99);
    const [showChat,setShowChat] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const chatContainerRef = useRef(null);

    useEffect(() => {
        getLastetMessages();
    }, [showChat]);
  
    useEffect(() => {
        const isAtBottom = chatContainerRef.current.scrollTop + chatContainerRef.current.clientHeight >= chatContainerRef.current.scrollHeight;
        if (isAtBottom) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messageList,page]);
  
    const handleScroll = () => {
        const isAtTop = chatContainerRef.current.scrollTop === 0;
        if (isAtTop && !isLoadingMore) {
            loadMoreMessages();
        }
    };
  
    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
    
            return () => {
                chatContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isLoadingMore]);
  
    const getLastetMessages = async () => {
        try {
            const res = await getLastMessagesByTypeAndReference(messageType, referenceId);
            setMessageList(res.data.sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate)));
        } catch (error) {
            console.error('Error fetching latest messages:', error);
            setMessageList([]);
        }
    };
  
    const loadMoreMessages = async () => {
        setIsLoadingMore(true);
        try {
            if(page >= totalPage-1){
                console.log("final")
                return;
            }
            const res = await getNextMessagesByTypeAndReference(messageType, referenceId, page + 1);
            setPage(page + 1);
            setTotalPage(res.data.totalPages);
            console.log(res);
            if(res && res.data.content.length !== 0){
                setMessageList(prevMessages => [...res.data.content.reverse(), ...prevMessages]);
            }
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; // Scroll to bottom after adding messages
        } catch (error) {
            console.error('Error loading more messages:', error);
        } finally {
            setIsLoadingMore(false);
        }
    };
  
    const sendMessage = async (textMessage) => {
        const messageModel = {
            messageType: messageType,
            referenceId: referenceId,
            content: textMessage,
            user: {
                id: currentUser.userId,
                name: userName
            }
        };
       
        try {
            const res = await createMessage(messageModel);
            console.log(res);
            getLastetMessages();
        } catch (error) {
            console.error('Error sending message:', error);
        }
    
    };

    const deleteMessage = async (messageId) => {       
        try {
            const res = await removeMessage(messageId);
            console.log(res);
            getLastetMessages();
        } catch (error) {
            console.error('Error sending message:', error);
        }
    
    };
      


  return (
    <div className="flex flex-col gap-5 w-full">
    <div className="w-full flex items-center gap-5">
        <button className={`${showChat ? 'button-29':'button-30'}`} onClick={() => setShowChat(!showChat)}> {showChat ? "Cerrar Comentari":"Ver Comentarios"}os</button>
        <div className="image-container w-12 hover:w-14 transition-transform duration-300 ease-in-out cursor-pointer" onClick={() => setShowChat(!showChat)}>
        <img className="w-10/12" src="../../assets/icons/NavBar/charla.png" alt="chatIcon" />
        </div>
    </div>
        <div className={`chat-section ${showChat ? 'flex':'hidden'} flex-col justify-center md:justify-end items-center md:items-end w-full h-[450px] mb-10  overflow-x-hidden`}>
            <div className={`chat_container flex flex-col gap-5 p-5 md:p-10 w-11/12 md:w-full h-10/12`} ref={chatContainerRef}>
            {isLoadingMore && <Loader/>} 
                {messageList && messageList.map((message, index) => (
                    currentUser.userId === message.user.id ? (
                        <div key={index} className="flex justify-end">
                            <ChatBox user={true} message={message} removeMessage={deleteMessage} />
                        </div>
                    ) : (
                        <div key={index} className="flex justify-start">
                            <ChatBox user={false} message={message} removeMessage={deleteMessage}/>
                        </div>
                    )
                ))}
            </div>
            <div className="flex justify-start my-5 w-full">
                <ChatInput onSendMessage={sendMessage} topValue={1}/>
            </div>
        </div>
    </div>
  )
}

export default ChatComponent