import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./SeriesChallengeBox.css";
import {
  getVideosInteractionByUser,
  getVideoBySerieId,
} from "../../services/videos";
import "./SeriesChallengeSlider.css";

const SeriesProgress = ({serie}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [videosList, setVideosList] = useState([]);
  const [userVideosComplete, setUserVideosComplete] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentChallenge, setCurrentChallenge] = useState(serie);
  const userId = useSelector((state) => state.user.currentUser.username);
  const [style, setStyle] = React.useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${progress}%`,
    };

    setStyle(newStyle);
  }, 200);

  useEffect(() => {
    getUserVideoInteraction(userId).then(() => {
      getVideosListBySeries(serie);
    });
  }, [userId]);

  useEffect(() => {
    setLineProgress();
  }, [videosList, userVideosComplete]);

  const getVideosListBySeries = async (serieId) => {
    try {
      setIsLoading(true);
      const response = await getVideoBySerieId(serieId);
      const data = response.data;
      setVideosList(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserVideoInteraction = async (username) => {
    try {
      const response = await getVideosInteractionByUser(username);
      const data = response;
      setUserVideosComplete(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setLineProgress = () => {
    var videosComplete = 0;
    const commonVideoIds = videosList.filter((video1) =>
      userVideosComplete.some((video2) => video2.videoId === video1.videoId)
    );
    videosComplete = commonVideoIds.length;
    setProgress((videosComplete / videosList.length) * 100);
  };

  return (
    <div>
      <div className="activeChallenge-slider flex flex-row justify-center items-center w-full pb-2">
        <h6 className="font-[DIN-Condensed] uppercase text-center">{currentChallenge}</h6>
      </div>
      <div className="flex flex-col items-center">
        <div className="image-box w-1/2 md:w-7/12">
          <img src="../../assets/icons/Mandala/mandala-8.png" alt="" />
        </div>
        <div className="progress-info p-3 text-justify w-full">
          {isLoading ? (
            <div className="progress">
              <div className="progress-done" style={style}>
                {0}%
              </div>
            </div>
          ) : (
            <div className="progress">
              <div className="progress-done" style={style}>
                {progress.toFixed(0)}%
              </div>
            </div>
          )}

          <p>“La vida es como el yoga, una práctica de todos los días"</p>
        </div>
      </div>
    </div>
  );
};

export default SeriesProgress;
