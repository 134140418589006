import React,{useState} from 'react'
import './RegisterPage.css'
import Card from '@mui/material/Card';
import RegisterFormTrial from './RegisterFormTrial';
import RegisterFormActiveUser from './RegisterFormActiveUser';
import { useNavigate } from "react-router-dom";

const RegisterPageTrial = () => {

  const [activeAccount,setActiveAccount] =useState(false);
  const [registerComplete,setRegisterComplete]=useState(false);
  const navigate = useNavigate();

  const handleRegister = (value:boolean) => {
    setRegisterComplete(value);
  };
  
  return <>
  <section className='flex justify-center items-center h-full register-section-1'>
     <Card sx={{ minWidth: 275, padding:'2rem' }} className="card-register">
       {activeAccount ? (<RegisterFormActiveUser handleRegister={handleRegister}/>):(<RegisterFormTrial handleRegister={handleRegister}/>)}
       {registerComplete ? '':( <div className="flex justify-center py-2">
          <h6 className='text-lg uppercase font-[DIN-Condensed] text-sb-500 cursor-pointer  hover:text-so-500 hover:scale-110' onClick={()=>setActiveAccount(!activeAccount)}>
            {activeAccount ? 'Registrar Nuevo Usuario':'Ya tengo Usuario'}
          </h6>
       </div>)}
       <div className="" onClick={() => navigate("/login")}>
                  <p className="text-sb-900 font-[DIN-Condensed] text-lg text-center hover:font-bold cursor-pointer">
                    Inicar Sesión
                  </p>
            </div>
    </Card>
    <div className="flex justify-center bg-sb-600">
    </div>
        
  </section>
  
  </>
}

export default RegisterPageTrial

