import React, { useEffect,useState } from 'react';
import {Alert,Autocomplete,Box,Button,FormControl,InputLabel,TextField,Typography, Chip, Avatar, Grid, Paper, Select, MenuItem } from '@mui/material';
import { UserData } from './UserData';
import { Subscription } from '../Video/VideoData';
import { getAllActiveSubscriptions } from '../../../../services/series';
import { modalStyle } from '../GeneralData';
import { updateUserById } from '../../../../services/user';
import { styled } from "@mui/material/styles";

interface Props {
  refreshData: () => void;
  handleClose:() => void;
  user:UserData;
}

const BigAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "#54989C",
  fontSize:36,
  width: 120,
  height: 120,
  border: `2px solid ${theme.palette.background.paper}`,
  position: "relative",
  margin:'1rem'
}));

const UpdateUserForm:React.FC<Props> = ({user,refreshData,handleClose}) => {

  const [editing, setEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(user);
  const [subscriptionList, setSubscriptionList] = useState<Subscription[]>([]);
  const [filterSubscriptionList, setFilterSubscriptionList] = useState<Subscription[]>([]);
  const [message,setMessage]= useState();
  const [userList, setUserList] = useState<Subscription[]>([]);
  const [isLoadingModal, setIsLoadingModal] = useState(true);
  const [success,setSuccess]=useState(false);
  const [error,setError] = useState<String|null>();


  useEffect(() => {
    filterSubscrition();
  }, []);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof UserData) => {
    setEditedUser((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleEditClick = () => {
    getSubscription();
    setEditing(true);
    filterSubscrition();
  };

  const handleSaveClick = () => {
    handleUpdate();
    setEditing(false);
  };

  const handleUpdate= async() =>{
    try {
        const res = await updateUserById(editedUser.userId,editedUser);
        setMessage(res);
        setSuccess(true);
        
    } catch (error:any) {
        setError(error);
    }
}

const filterSubscrition = async () => {
  try {
    getSubscription()
    .then(subscriptions => {
      const filteredSubscriptions = subscriptions.filter(subscription =>
        editedUser.subscriptionPlanId.includes(subscription.planId)
      );
      setFilterSubscriptionList(filteredSubscriptions);
    })
    .catch(error => {
      console.error('Error fetching and filtering subscriptions:', error);
      // Handle error appropriately, e.g., display an error message to the user
    });
  } catch (error) {
    console.error('Error filtering subscriptions:', error);
    return []; // Return an empty array in case of error
  }
};
const getSubscription = async (): Promise<Subscription[]> => {
  try {
    setIsLoadingModal(true);
    const response:any = await getAllActiveSubscriptions(); // Assuming getAllActiveSubscriptions returns a Promise<ApiResponse<Subscription[]>>
    const data: Subscription[] = response.data; // Assuming the response contains the data property of type Subscription[]
    setSubscriptionList(data);
    return data;
  } catch (error:any) {
    console.error('Error fetching active subscriptions:', error);
    setError(error); // Consider providing more specific error handling based on different error scenarios
    throw error; // Re-throw the error to propagate it further if necessary
  } finally {
    setIsLoadingModal(false);
  }
};

  const handleSubscriptionsChange = (e: any, values: { planId: string }[]) => {
    const selectedPlanIds = values.map((value) => value.planId);
    setEditedUser((prevData:any) => ({
      ...prevData,
      subscriptionPlanId: selectedPlanIds,
    }));
  };

  const closeSuccessFully=()=>{
    handleClose();
    refreshData();
}

const stringAvatar = (name:string) => {
  if (name.split(" ").length > 1) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return {
    children: `${name.split(" ")[0][0]}`,
  };
}


  return (
    <Box sx={modalStyle}>
    {!success ? (
        <Paper sx={{ padding: 2, maxHeight: 500, overflowY: 'scroll' }}>
                {editing ? (
                    <>
                        <b>Username:</b>
                        <Typography variant="body2" gutterBottom>
                        <TextField
                            fullWidth
                            value={editedUser.username}
                            onChange={(e) => handleChange(e, 'username')}
                        />
                      <Grid container justifyContent="center">
                        <Grid item>
                        <BigAvatar {...stringAvatar(editedUser?.fullName)} />
                        </Grid>
                      </Grid>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        <b>Email:</b>
                        <TextField
                            fullWidth
                            value={editedUser.email}
                            onChange={(e) => handleChange(e, 'email')}
                        />
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <b>Nombre:</b>
                          <TextField
                              fullWidth
                              value={editedUser.fullName}
                              onChange={(e) => handleChange(e, 'fullName')}
                          />
                        </Typography>
                        <FormControl className="p-5" style={{ width: '100%' }}>
                          <Autocomplete
                            style={{ width: '100%' }}
                            multiple
                            id="tags-standard"
                            options={subscriptionList}
                            defaultValue={filterSubscriptionList}
                            getOptionLabel={(option) => option.planId}
                            onChange={handleSubscriptionsChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Subscripciones"
                                placeholder="Subscripciones"
                              />
                            )}
                          />
                        </FormControl>
                        {/* Add more fields for other user properties */}
                    </>
                ) : (
                    <>
                        <Typography variant="body1"><b>User ID:</b> {editedUser.userId}</Typography>
                        <Grid container justifyContent="center">
                          <Grid item>
                          <BigAvatar {...stringAvatar(editedUser?.fullName)} />
                          </Grid>
                        </Grid>
                        <Typography variant="body1"><b>Username:</b> {editedUser.username}</Typography>
                        <Typography variant="body1"><b>Email:</b> {editedUser.email}</Typography>
                        <Typography variant="body1"><b>Nombre:</b> {editedUser.fullName}</Typography>
                        <Typography variant="body2" gutterBottom>
                          <b>Subscripciones: </b>
                          {editedUser.subscriptionPlanId.map((subscription, index) => (
                              <Chip key={index} label={subscription} style={{ marginRight: '4px', background: "#FFCD59" }} />
                          ))}      
                        </Typography>
                    </>
                )}
            {/* Add more sections for other user properties */}
            {editing ? (
                <div className='flex flex-wrap justify-around py-2 px-5'>
                    <Button onClick={handleSaveClick} variant="contained" color="primary">
                        Update
                    </Button>
                    <Button onClick={() => setEditing(false)} variant="contained" color="error">
                        Cancel
                    </Button>
                </div>
            ) : (
                <Button onClick={handleEditClick} variant="outlined">
                    Edit
                </Button>
            )}
        </Paper>
    ) : (
        <div className="flex flex-col gap-5 justify-center items-center">
            <Alert severity="success">{message}</Alert>
            <Button variant="contained" color="success" onClick={closeSuccessFully}>Continue</Button>
        </div>
    )}
</Box>
    
  )
}

export default UpdateUserForm