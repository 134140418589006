import {
  publicRequest,
  userRequestByParams
} from "../requestMethods/requestMethods";

export const getClientSecret = async (payment) => {
  try {
    const res = await publicRequest.post(`/stripe/create-payment-intent`,payment);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getSubscriptionById = async (subscriptionId) => {
  try {
  const params ={
      subscriptionId: subscriptionId,
  }
  const res = await userRequestByParams(params).get("/stripe/subscriptionById");
  return res.data;
  } catch (err) {
    return {
      id: null,
      customerId: null,
      priceId: null,
      subscriptionType: null,
      created: null,
      currentPeriodStart: null,
      currentPeriodEnd: null,
      interval: null,
      amount: null,
      currency: null,
      status: false
    };
  }
};
