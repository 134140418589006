import React, { useEffect,useState } from 'react'
import {CardElement, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { getClientSecret } from '../../services/stripe';

const STRIPE_AUTH = process.env.REACT_APP_STRIPE_KEY;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_AUTH,{
  locale: 'es',
});

export default function MindPayment() {
  const[clientSecret,setClientSecret] = useState();
  const [isLoading,setIsLoading]=useState(false);
  const[paymentIntentStatus,setPaymentIntentStatus]=useState(false);


  useEffect(() =>{
     const payment = {
       priceId: "examplePriceId",
       amountInCents: 50000,
       currency: "mxn",
       email: "example@email.com",
       subscriptionType: "MIND",
     };
    handleClientSecret(payment);

  },[])
  const options = {
    // passing the client secret obtained from the server
    clientSecret:clientSecret
  };

  const handleClientSecret = async (payment) => {
    setIsLoading(true);
    const res = await getClientSecret(payment);
    console.log(res);
    setClientSecret(res);
    setIsLoading(false);
    return res;
  };

    const handlePaymentStatus = (childData) => {
      setPaymentIntentStatus(childData);
    };

  return (
    <>
      <h5>MIND PAYMENT</h5>
      {clientSecret && !isLoading && !paymentIntentStatus && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm sendPaymentStatus={handlePaymentStatus} />
        </Elements>
      )}

      {paymentIntentStatus ? "exitoso":""}
    </>
  );
};