import React,{useState,useEffect} from 'react'
import './ResetPasswordPage.css'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { useMatch } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { resetPassword } from '../../services/user';
import { restartError } from '../../redux/userRedux';
import CircularProgress from "@mui/material/CircularProgress";

interface ResetPassswordModel{
  token:string;
  newPassword:string;
}

const ResetPasswordPage = () => {
  const[resetPasswordSuccess,setResetPasswordSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [matchPassword,setMatchPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const { isFetching, error } = useSelector((state:any) => state.user);
  const match:any  = useMatch("/:firstRoute/:secondRoute/*");
  const { firstRoute,secondRoute } = match.params;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

   const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  


  const dispatch = useDispatch();


  useEffect(() =>{
      dispatch(restartError());
  },[])

  const handleResetPassword = async () =>{
    try{
      if(checkMatchPassword()){
        const resetObject:ResetPassswordModel=({
          token:secondRoute,
          newPassword:password
        })
         console.log(resetObject);
        const response = await resetPassword(dispatch,resetObject);
        if(response === 'Password reset successfully'){
          setResetPasswordSuccess(true);
          alert("La contraseña se actualizado de forma exitosa");
          console.log(response);
        }
        
      }
    
    } catch (error) {
      console.log(error);
     
    }
  };

    const checkMatchPassword= () =>{
    if(password === confirmPassword){
        setMatchPassword(true);
        console.log("PasswordMatch" + matchPassword);
        return true;
    }else{
        setMatchPassword(false);
        console.log("PasswordMatch" + matchPassword);
        setPassword('')
        setConfirmPassword('');
        alert("Las contraseñas no coinciden");
        return false;
    }
  }

  return (
    <>
      <section className="reset-section">
        <div className="form w-full md:w-1/2 flex justify-center">
          {resetPasswordSuccess ? (
          <div className="w-11/12 md:w-full form-box flex flex-col justify-center items-center gap-5">
            <div className="">
            <img src="../../assets/icons/Logo/Logo-sm.png" width={64} alt="Logo" />
          </div>
            <p>El cambio de contraseña ha sido existoso</p>
            <button className='button-29 w-fit p-2' onClick={() => navigate("/index")}>Ir a Inicio</button>
          </div>

          ):(
            <div className="w-11/12 md:w-full form-box flex flex-col justify-center items-center gap-5">
              <p className="text-center text-xl">Ingresa tu nueva contraseña</p>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirmar Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="ConfirmPassword"
          />
        </FormControl>
            <div className="flex flex-col gap-5">
              <button
                type="submit"
                className="w-full button-29"
                onClick={handleResetPassword}
                disabled={isFetching}
              >
                {isFetching ? (
                  <div
                    role="status"
                    className="flex items-center justify-center"
                  >
                   <CircularProgress color="inherit" size={25} />
                  </div>
                ) : (
                  "Cambiar Constraseña"
                )}
              </button>
              {error && (
                <Alert severity="error">El link a caducado</Alert>
              )}
            </div>
          </div>

          )}
          
        </div>
        <div className="text-box flex-col gap-6 justify-start items-start pr-20 w-full md:w-1/2 font-[MADE-Bon-Voyage]">
          <h1 className="text-sc-c2">SAR ASANAS</h1>
          <div className="text-content">
            <h6 className="text-sc-c2">
              “La vida es como el yoga, una práctica de todos los días”.
            </h6>
          </div>
        </div>
        
      </section>
    </>
  );
}

export default ResetPasswordPage