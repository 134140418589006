import axios from "axios";

const BASE_URL = process.env.REACT_APP_SPRING_URL;
const BASE_URL_AUTH = process.env.REACT_APP_BASE_URL;

const accesstoken =
  "eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJzZWxmIiwic3ViIjoibHVpcyIsImV4cCI6MTY3NDA5OTYxMSwiaWF0IjoxNjc0MDk2MDExLCJzY29wZSI6IlJPTEVfQURNSU4ifQ.k2XO6uH9gWFX0XBnxBvYjVC7Dt8H0Oe86tkIZ98SqyJk7Sb99qMhe69IXoRFUGZVGcYMu7-LXvJkXjaoALa2rsvG3Y5OJHL5UJKH6ZBl-rYnHyXzKFRA3TuKfwrpc2V6kytH5Z95Sg3t91ffF0ySU4eo2WRwnEPl-vCvDah8HcpRCj5XyAhE2i72IQgO9dXlyD0cJvs4FRL-ToIA4kBdcSHrrhkDPDsY_NyDuDGthL2Uny9QwvaOXNomR_ql29UxAV5iAn7FxS2dMMEUGsPKd6tpsBVOcZwZDgeifTUG1NS9yrMAkb1_5EJpG5miJr7tha4Dgvu74KKHE4PTxkYVuw";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const publicHeaderRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  auth: {
    username: 'admin',
    password: 'admin',
  }
});

export const userRequestByTag = axios.create({
  baseURL: BASE_URL,
  params: {
    tag: 'value',
  },
});

export const userRequestBy2Params = axios.create({
  baseURL: BASE_URL,
  params: {
    minDuration: "value",
    maxDuration: "value1",
  },
});

export const userRequestByParams = (params = {}) => axios.create({
  baseURL: BASE_URL,
  params: params,
});

export const userRequestBy3Params = (params) =>
  axios.create({
    baseURL: BASE_URL,
    params: {
      params,
    },
  });

export const createAxiosInstance = (params) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000, // Set your desired timeout
    params: params, // Use the dynamic params here
  });

  return instance;
}


export const createInstance = (params) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    params: params,
    headers: {
      'Content-Type': 'application/json', // Set your desired content type
    },
  });

  // You can also add request interceptors, response interceptors, etc.

  return instance;
};