import React,{useState} from 'react'
import { Alert,Box,Button,Typography, Chip, Avatar, Grid, Paper } from '@mui/material';
import { VideoData,VideoFormData } from './VideoData'
import { removeVideoById } from '../../../../services/videos';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)',
    zIndex:10,
  };


  interface Props {
    refreshData: () => void;
    handleClose:() => void;
    video:VideoData;
  }


const RemoveForm:React.FC<Props> = ({video,refreshData,handleClose}) => {
    const [message,setMessage]= useState();
    const [success,setSuccess]=useState(false);

    const handleRemoveVideo= async(videoId:string)=>{
        try {
            const res = await removeVideoById(videoId);
            setMessage(res);
            setSuccess(true);
        } catch (error:any) {
            setMessage(error)
        }
    }

    const closeModal=()=>{
        handleClose();
    }

    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    }

  return (
    <Box sx={modalStyle}>
        {!success ? <div>
            <div className="title text-center p-5">
            <h5 className='text-2xl'>¿Desesas Eliminar {video.title}?</h5>
        </div>
        <div className="video-info">
            <Paper sx={{ padding: 2}}>
            <Typography variant="h4" gutterBottom textAlign="center">
                {video.title}
            </Typography>
            <Grid container justifyContent="center" padding={3}>
                <Grid item>
                <Avatar alt="Thumbnail" src={video.thumbnail} sx={{ width: 150, height: 150 }}/>
                </Grid>
            </Grid>
            <Typography variant="body1" gutterBottom>
                {video.description}
            </Typography>
            <Typography variant="body2" gutterBottom>
                Tags:
                {video.tags.map((tag, index) => (
                <Chip key={index} label={tag} style={{ marginRight: '4px' }} />
                ))}
            </Typography>
            <Typography variant="body2" gutterBottom>
                Duration: {video.duration} minutes
            </Typography>
            <Typography variant="body2" gutterBottom>
                Intensity Level: {video.intensityLevel}
            </Typography>
            <Typography variant="body2" gutterBottom>
                Series ID: {video.seriesId}
            </Typography>
            <Typography variant="body2" gutterBottom>
                Series Episode ID: {video.seriesEpisodeId || 'N/A'}
            </Typography>
            <Typography variant="body2" gutterBottom>
                Subscription Required: {video.subscriptionRequired ? 'Yes' : 'No'}
            </Typography>
            <Typography variant="body2" gutterBottom>
                Allowed Users: {video.allowedUsers.join(', ')}
            </Typography>
            <Typography variant="body2" gutterBottom>
                Allowed Subscriptions: {video.allowedSubscriptions.join(', ')}
            </Typography>

            </Paper>
        </div>
        <div className="actionContainer flex flex-wrap gap-5 justify-center items-center p-5">
            <Button variant='contained' color='success' onClick={()=>handleRemoveVideo(video.id)}>Continuar</Button>
            <Button variant='contained' color='error' onClick={closeModal}>Cancelar</Button>
        </div>

        </div>:<div className="flex flex-col gap-5 justify-center items-center">
        <Alert severity="success">{message}</Alert>
          <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
        </div>}

    </Box>
  )
}

export default RemoveForm