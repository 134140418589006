import { useEffect, useState } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { createMessage } from '../../services/messages';
import { Button } from '@mui/material';


const ChatInput = ({onSendMessage,topValue}) => {
    const [showPicker, setShowPicker] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(()=>{
        console.log(showPicker);
    },[showPicker]);

    const getPicker=()=>{
        if(showPicker){
        return <div className="flex flex-col gap-5 left-0 mb-2.5">
            <button className='button-30 w-fit p-5' onClick={() =>setShowPicker(false)}>Cerrar </button>
            <Picker  data={data} onEmojiSelect={handleEmojiSelect} />
        </div>

        }
       
    }

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleEmojiSelect = (emoji) => {
        setMessage(message + emoji.native);
    };

    const handleSendMessage = ()=>{
        try {
            onSendMessage(message);
            
        } catch (error) {
            console.log(error);
        }finally{
            setMessage('');
        }

    }


    return (
        <div className="max-w-md mx-0 md:mx-auto w-11/12 md:w-full">   
            <div className="relative flex gap-2 items-end justify-center">
                <input 
                    type="search" 
                    id="default-search" 
                    className="block w-full pl-10 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Enviar Mensaje" 
                    value={message} 
                    onChange={handleInputChange} 
                    required 
                />
                
                <button className="absolute top-[30%] start-2 hidden sm:block" onClick={() => setShowPicker(true)}>
                    😁
                </button>
                <button onClick={() => handleSendMessage()} className="!hidden sm:!inline-flex end-2.5 bottom-2.5 font-medium rounded-lg text-sm px-4 py-2 mx-2 button-29 " disabled={message === ''}>Enviar</button>
            </div>
            <div className={`${topValue === 0 ? '':'absolute top-[30%]'}`}>
                {getPicker()}
            </div>
            <div className="block sm:hidden flex justify-center py-5">
                <button onClick={() => handleSendMessage()} className="block sm:hidden font-medium rounded-lg text-sm px-4 py-2 mx-2 button-29" disabled={message === ''}>Enviar</button>
            </div>

        </div>
        
    );
};

export default ChatInput;