import React, { useState } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Modal, Typography, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


interface ProductListProps {
    handleListOfProducts: (productList: string[]) => void;
    productList:string[];
}

const UpdateProductForm:React.FC<ProductListProps> = ({handleListOfProducts,productList}) => {
    const [description, setDescription] = useState('');
    const [descriptions, setDescriptions] = useState<string[]>(productList);
    const [removeIndex, setRemoveIndex] = useState<number | null>(null);
    const [openModal, setOpenModal] = useState(false);

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleAddDescription = () => {
        if (description.trim() !== '') {
            setDescriptions([...descriptions, description]);
            setDescription('');
        }

        handleListOfProducts([...descriptions, description]);
    };

    const handleRemoveDescription = () => {
        if (removeIndex !== null) {
            const updatedDescriptions = [...descriptions];
            updatedDescriptions.splice(removeIndex, 1);
            setDescriptions(updatedDescriptions);
            setRemoveIndex(null);
            setOpenModal(false);
            handleListOfProducts(updatedDescriptions);
        }
    };

    const handleOpenModal = (index: number) => {
        setRemoveIndex(index);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setRemoveIndex(null);
        setOpenModal(false);
    };

    return (
        <div>
            <TextField
                label="Description"
                variant="outlined"
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                margin="normal"
            />
            <div className="flex justify-center p-3">
                <Button variant="contained" color="primary" onClick={handleAddDescription}>Agregar Producto</Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Producto</TableCell>
                            <TableCell>Acción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {descriptions.map((desc, index) => (
                            <TableRow key={index}>
                                <TableCell>{desc}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleOpenModal(index)} aria-label="delete">
                                        <ClearIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography variant="h6" gutterBottom>Confirmar</Typography>
                    <Typography variant="body1">Segura que quieres Eliminarlo?</Typography>
                    <div className="flex flex-wrap gap-5 justify-center">
                        <Button onClick={handleRemoveDescription} color="primary" variant="contained">Eliminar</Button>
                        <Button onClick={handleCloseModal} color="error" variant="contained">Cancelar</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default UpdateProductForm;
