import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import { Link, useNavigate, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from 'react-player'
import Footer from '../../../components/Footer/Footer'
import VerticalNavBar from '../../../components/Navbar/PlatformNavBar/VerticalNavBar'
import './VideoPlayer.css'
import { addVideoInteraction, getVideoById, getVideoBySerieId } from "../../../services/videos";
import Rating from "@mui/material/Rating";
import ChatInput from "../../../components/CardsBox/ChatInput";
import ChatBox from "../../../components/CardsBox/ChatBox";
import {createMessage,getLastMessagesByTypeAndReference,getNextMessagesByTypeAndReference } from "../../../services/messages";
import Loader from "../../../components/Loaders/Loader";
import ChatComponent from "../../../components/ChatComponent/ChatComponent";




const VideoPlayer = () => {

    const [video,setVideo] = useState({});
    const match= useMatch("/:firstRoute/:secondRoute/*");
    const { firstRoute,secondRoute } = match.params;
    const [videosList, setVideosList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [videoEnded,setVideoEnded] = useState(false);
    const [reviewSend, setReviewSend] = useState(false);
    const [radomNumber,setRandomNumber]=useState(0);
    const [showChat,setShowChat] = useState(false);
    const [videoInteractionModel, setVideoInteractionModel] = useState(
      {
        videoId: '',
        seriesId: '',
        status: '',
        interactionDate: null,
      }
    );
    const [username, setUsername] = useState();
    const [activeSub, setActiveSub] = useState(false);
    const [value, setValue] = useState(0);
    const [isReady, setIsReady] = useState(true);
    const playerRef = useRef();
    const currentUser = useSelector((state) => state.user.currentUser);
    const checkSubscription = () => {

      if (video.allowedSubscriptions.includes("FREE")) {
        setActiveSub(true);
      } else if (currentUser.subscriptionPlanId.length === 0) {
        setActiveSub(false);
      } else {
        currentUser.subscriptionPlanId.forEach(
          (subscriptionItem) => {
            if (
              video.allowedSubscriptions.includes(subscriptionItem)
            ){
              setActiveSub(true);
            }                            
          }
        );
      }
    };

    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };



     const onReady = React.useCallback(() => {
      
       if (!isReady) {
         const timeToStart = 0 * 60 + 1;
         playerRef.current.seekTo(timeToStart, "seconds");
         setIsReady(true);
       }
     }, [isReady]);


    

      const history = useNavigate();

      const handleGoBack = () => {
        history(-1);
      };

    useLayoutEffect(() => {
      setVideoEnded(false);
      getVideoByID()
      .then(() => checkSubscription())
      .catch(error => {
        console.error("Error fetching video details:", error);
        // Handle the error as needed
      });
    }, [secondRoute]);

    useEffect(()=>{
      getVideosListBySeries(video.seriesId);
      
    },[video]);

    useEffect(() => {
      createVideoInteraction();
    }, [video]);

    const getVideoByID =  async () => {
        try{     
            const response = await getVideoById(secondRoute);
            const data = response.data;
            setVideo(data);
        }catch(error){
            console.log(error);
        }
    };


    const getVideosListBySeries = async (seriesId) => {
      try {
        setIsLoading(true);
        const response = await getVideoBySerieId(seriesId);
        const data = response.data;
        setVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleReview = () =>{
      setReviewSend(true);
      

    }

    const handleRestart = () =>{
      onReady();
      setVideoEnded(false);
    }

    const handleVideoEnded = () =>{
      setVideoEnded(true);
      setIsReady(false);
      sendVideoInteraction(username, videoInteractionModel);
    };

    const createVideoInteraction=()=>{
      const { videoId, tags } = video;
      const interactionDate = new Date();
      const seriesId = video.seriesId;

      setVideoInteractionModel({
        videoId,
        seriesId,
        status: "Complete",
        interactionDate,
      });
  
      setUsername(currentUser.username);
    }

    const sendVideoInteraction = async (userId, interaction) => {
      try {
        const response = await addVideoInteraction(userId, interaction);
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <>
      <div className="index-platform  flex gap-5">
        <VerticalNavBar></VerticalNavBar>
        <div>
          <div className="logo-box flex flex-col justify-center items-center p-10">
            <div className="image-container w-20">
              <img src="../../assets/icons/Logo/Logo-sm.png" alt="" />
            </div>
            <p className="font-[MADE-Bon-Voyage]">
              "It's not about being good at something. It's about being good to
              yourself.
            </p>
          </div>
          <div className="arrow-back-player flex justify-start items-start">
            <button className="button-30" onClick={handleGoBack}>
              Regresar
            </button>
          </div>
          <div className="video-box flex justify-center items-center gap-5 relative">
            <div className="w-11/12 lg:w-8/12 relative">
              <div
                className={`video-rating-box flex flex-col gap-5 justify-center items-center w-full h-full absolute z-10 ${
                  videoEnded ? "block" : "hidden"
                }`}
              >
                {reviewSend ? (
                  <>
                    <h6 className="font-[DIN-Condensed] text-sb-100 uppercase">
                      Muchas Gracias por tu review!!
                    </h6>
                  </>
                ) : (
                  <>
                    <h5 className="font-[DIN-Condensed] text-sb-100 uppercase">
                      ¿Qué tanto te gusto la clase?
                    </h5>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      size="large"
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </>
                )}

                <div className="flex flex-wrap gap-5">
                  <button
                    className={`button-29 w-fit ${
                      reviewSend ? "!hidden" : "block"
                    }`}
                    onClick={handleReview}
                  >
                    Enviar
                  </button>
                  <button className="button-30 w-fit" onClick={handleRestart}>
                    Repetir Video{" "}
                  </button>
                </div>
              </div>

              <ReactPlayer
                ref={playerRef}
                url={video.filePath}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload", // Remove download button
                    },
                  },
                }}
                width="100%"
                height="100%"
                type="video/mp4"
                onEnded={() => handleVideoEnded()}
              />
            </div>
            <div className="video-bookmarks flex flex-wrap lg:flex-col  w-11/12 md:w-auto justify-center items-center  gap-2 md:gap-5">
              {isLoading ? (
                <>
                  {[...Array(4)].map((e, i) => (
                    <div className="image-container w-36 max-h-24 is-loading"></div>
                  ))}
                </>
              ) : (

                videosList.slice(0, 4).map((item) => {
                    return (
                      <Link to={`/video/${item.videoId}`}>
                        <div className="image-container w-36 max-h-24">
                          <img src={item.thumbnail} alt={item.title} />
                        </div>
                      </Link>
                    );                  
                })
              )}
            </div>
          </div>
          <div className="spec flex flex-wrap justify-center items-center gap-3 px-10 md:px-0 py-10">
            <div className="video-spec-box flex flex-col gap-3 w-full md:w-3/4">
              <h6 className="font-[MADE-Bon-Voyage]">{video.title}</h6>
              <p className="font-[DIN-Condensed]">{video.description}</p>
            </div>

            <div className="image-container w-1/2 md:w-32">
              <img src="../../assets/icons/Mandala/mandala-7.png" alt="Mandala" />
            </div>
            <div className="relative flex justify-center items-center w-full mx-1 md:mx-20">
              <ChatComponent messageType={"VIDEO"} referenceId={secondRoute} />
            </div>
          </div>
        </div> 

      </div>

      <Footer></Footer>
    </>
  );
}

export default VideoPlayer