interface MenuItem{
    name:string;
    path:string;
    url:string;
}

export const Menu: MenuItem[] =[
    {
        name:'Inicio',
        path:'/index',
        url:'../../assets/icons/NavBar/Home-icon.svg'
    },
    {
        name:'Clases de Yoga',
        path:'/yogaClasses',
        url:'../../assets/icons/NavBar/yoga-mat.png'
    },
    {
        name:'Biblioteca',
        path:'/library/searchBy?all',
        url:'../../assets/icons/NavBar/video-playlist-90.png'
    },
    {
        name:'Retos',
        path:'/challenges',
        url:'../../assets/icons/NavBar/target.png'
    },
    {
        name:'En Vivo',
        path:'/liveClasses',
        url:'../../assets/icons/NavBar/live.png'
    },
    {
        name:'Chat',
        path:'/chat',
        url:'../../assets/icons/NavBar/chat-icon.png'
    }
    ];