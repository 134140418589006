import React, {useState} from 'react';
import {Alert,Box} from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import VideoUpload from './VideoUpload';
import StepInformationForm from './StepInformationForm';

const steps = ['Agregar Media', 'Agregar Información'];

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)',
    zIndex:10,
  };

export default function VideoUploadStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [videoUrl,setVideoUrl] = useState<string>('');
  const [imgUrl,setImgUrl] = useState('');


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleMediaUrl = (videoUrl:string,imgUrl:string) =>{
    setImgUrl(imgUrl);
    setVideoUrl(videoUrl);
  }


  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <VideoUpload handleNext={handleNext} handleMediaUrl={handleMediaUrl} />;
      case 1:
        return <StepInformationForm handleNext={handleNext} videoUrl={videoUrl} imgUrl={imgUrl}/>;
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={modalStyle}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
        <div className="flex justify-center items-center p-5">
          <Alert severity="success">Todos los pasos se han completado de forma exitosa !!!</Alert>
        </div>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Inciar</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
            
        <div className='flex flex-col p-5'>
            {getStepContent(activeStep)}
         </div>
          
        </React.Fragment>
      )}
    </Box>
  );
}