import React,{useState,useEffect} from 'react'
import CardBoxPogram from '../CardsBox/CardBoxProgram';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination,Mousewheel } from "swiper/modules";
import { getAllSeries } from '../../services/series';
import { fetchPromotions } from '../../services/promotion';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./SliderCardsChallenge.css"


const SliderCardsPrograms = () => {
    const [swiper, setSwiper] = useState(null);
    const [seriesList, setSeriesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error,setError]=useState();

    useEffect(()=>{
      getAllSeriesList();
    },[])

    const getAllSeriesList =  async () => { 
      try{     
          setIsLoading(true);
          const response = await fetchPromotions();
          const data = response;
          console.log(response);
          setSeriesList(data)
      }catch(error){
          console.log(error);
          setError(error)
      }finally{
          setIsLoading(false);
      }
    };

  return (
    <>
      <Swiper
        modules={[Pagination, Mousewheel]}
        slidesPerView={"auto"}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        initialSlide={1}
        breakpoints={{
          // when window width is >= 640px
          300: {
            width: 300,
            slidesPerView: 1,
            initialSlide: 0,
          },
          640: {
            width: 640,
            slidesPerView: 1,
            initialSlide: 0,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2,
          },
          980: {
            width: 980,
            slidesPerView: 3,
          },
          1280: {
            width: 1280,
            slidesPerView: 4,
          },
        }}
        className="mySwiper"
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <CardBoxPogram
            title=""
            desc=""
            imageUrl="../../assets/images/Promo/Reto-Enc-Activa/Reto-Enc-Activa-1.jpg"
            url="/promotion"
            active={true}
          />
        </SwiperSlide>
        {seriesList && seriesList.map((item, index) => {

          if(item.serieId === 'RETOMA-ENCIENDE-ACTIVA' || item.serieId === 'Reto-Estres-y-Ansiedad'){
            return;
          }
          console.log(item);
          return(
            <SwiperSlide key={index} className="flex justify-center items-center">
            <CardBoxPogram
              title=""
              desc=''
              imageUrl={item.thumbnail}
              url={`/promotion/${item.serieId}`}
              active={item.active}
            />
          </SwiperSlide>)
        })}
        <SwiperSlide>
          <CardBoxPogram
            title=""
            desc=""
            imageUrl="../../assets/images/FrontPage/reto01.jpg"
            url="https://buy.stripe.com/8wMcNr8vZ1LxeYw3cg"
            active={true}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBoxPogram
            title="POWER YOGA"
            desc="Dinámica y energizante donde se logra tonificar el cuerpo"
            imageUrl="../../assets/images/Cards/Video-P-8.jpg"
            url="/subscripciones"
            active={false}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBoxPogram
            title="AFRICAN MIX"
            desc="Vinyasa, danza tribal, funcional y cardio."
            imageUrl="../../assets/images/sliders/sara-2.webp"
            url="/subscripciones"
            active={false}
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SliderCardsPrograms;