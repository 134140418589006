import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {addSubscription, findUserByEmail } from "../../../services/user";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Loader from "../../../components/Loaders/Loader";
import { restartUser } from "../../../redux/userRedux";
import {
  activateOrder,
  checkToken,
  findOrderById,
} from "../../../services/order";
import Email from "@mui/icons-material/Email";

interface OrderModel {
  orderId: string;
  invoiceId: string;
  planId: string; // Product Id
  productId: string;
  paymentId: string;
  chargeId: string;
  customerEmail: string;
  customerName: string;
  periodStart: number; // Assuming it represents a Unix timestamp
  periodEnd: number; // Assuming it represents a Unix timestamp
  subscriptionId: string;
  receiptUrl: string;
  token: string;
  tokenActive: boolean;
  created: Date;
}

type ChildProps = {
  handleRegister: (value: boolean) => void;
};

const RegisterFormActiveUserTrail:React.FC<ChildProps> = (props)=> {

  const [order, setOrder] =useState <OrderModel>({
      orderId: "",
      invoiceId: "",
      planId: "",
      productId: "",
      paymentId: "",
      chargeId: "",
      customerEmail: "",
      customerName: "",
      periodStart: 0, // You can set an initial value here
      periodEnd: 0, // You can set an initial value here
      subscriptionId: "",
      receiptUrl: "",
      token: "",
      tokenActive: false,
      created: new Date(),
    });
  const [email, setEmail] = useState("");
  const [validEmail,setValidEmail] = useState(true)
  const [userId,setUserId] =useState("");
  const [addPlanSuccess, setPlanSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [valid, setValid] = useState(true);
  const { isFetching, error } = useSelector((state: any) => state.user);
  const match: any = useMatch("/:firstRoute/:secondRoute/*");
  const { firstRoute, secondRoute } = match.params;
  const navigate = useNavigate();


  const dispatch = useDispatch();

  useEffect(() =>{
    const isErrorMessagePresent = errorMessage === 'Link Error';
    setIsDisabled(isErrorMessagePresent);

  },[valid])

  const checkUserEmail= async(email:string) =>{
    setErrorMessage("");
      setValidEmail(false);
      console.log(email);
    try{
      const response: any = await findUserByEmail(dispatch, email);
      console.log(email);
      if (response && response.status === 200) {
        setValidEmail(true);
        setUserId(response.data.userId)
      } else {
        if (response && response.response && response.response.status === 404) {
          setValidEmail(false);
          setErrorMessage("No existe ese correo");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the input value matches the email pattern
    const isValid = emailPattern.test(inputValue);
    setEmail(inputValue);
    setValid(isValid);

  };

  const handleAddSubscription= async (userId:string,planId:string) => {
    const response:any = await addSubscription(userId,planId);
    console.log(response);
    if(response && response.status === 200){ 
      setPlanSuccess(true);
      props.handleRegister(true);
    }else{
      if (response && response.response && response.response.status === 404) {
          setValidEmail(false);
          setErrorMessage("No existe ese correo");
        }
    }

  }

  const activeSubscription= () =>{

    var planId:string ='';
    const match = secondRoute.match(/registro-(.+)/);
    if (match) {
      // Extract the subscription plan ID from the route
      const subscriptionPlanId = match[1].toUpperCase();
      planId = subscriptionPlanId;
    }

    try {
       checkUserEmail(email);
      if (validEmail && userId) {
           handleAddSubscription(userId,planId); 
      }
    }  catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred during the request.");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="py-2">
                <img src="../../assets/icons/Logo/Logo-sm.png" width={64} alt="Logo" />
          </div>
        </div>
        {addPlanSuccess ? 
        (<div className='flex flex-col justify-center items-center gap-5'>
            <p className='text-center text-xl uppercase'>Tu registro ha sido exitoso</p>
            <button className='button-30 w-fit p-2' onClick={() => navigate("/login")}>Ir a Inicio</button>
          </div>):(<div className="flex flex-col gap-5 justify-center items-center">
          <TextField
                label="Correo"
                error={!valid}
                helperText={!valid ? 'Correo Invalido' : ''}
                id="outlined-start-adornment"
                sx={{ m: 1, width: '25ch' }}
                value={email}
                onChange={handleEmailChange}
            />
            {errorMessage && (<Alert className="py-5" severity="error">{errorMessage}</Alert>)}
            <Button  onClick={() => activeSubscription()} className="button-29 w-fit p-2" variant="contained" disabled={isDisabled} >{isFetching ? <CircularProgress color="inherit" size={25} />:'Registrarse' }</Button>
        </div>)}
        
      </div>
    </>);
};

export default RegisterFormActiveUserTrail;
