import React, {useState} from 'react'
import VerticalNavBar from '../../../components/Navbar/PlatformNavBar/VerticalNavBar'
import './IndexPlatform.css'
import { Link } from 'react-router-dom';
import SeriesChallengeBox from '../../../components/SeriesChallengeBox/SeriesChallengeBox';
import SeriesChallengeSlider from '../../../components/SeriesChallengeBox/SeriesChallengeSlider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SliderMandalas from '../../../components/Sliders/SliderMandalas';
import SliderSereiesIndex from '../../../components/Sliders/SliderSereiesIndex';

interface Mantra{
    name:string;
    serieId:string;
    path:string;
    month:string;
    active:boolean;
}

const Mantras:Mantra[] =[
    {
        name:'Abundancia',
        serieId:'Abundancia',
        path:'../../assets/images/Mantras/AGOSTO.jpg',
        month:'Agosto',
        active:true
    },
    {
        name:'Ser tú',
        serieId:'Ser Tu',
        path:'../../assets/images/Mantras/SEPTIEMBRE.jpg',
        month:'Septiembre',
        active:true
    },
     {
        name:'Oneness',
        serieId:'Oneness',
        path:'../../assets/images/Mantras/OCTUBRE.jpg',
        month:'Octubre',
        active:true
    },
    {
        name:'Naturaleza',
        serieId:'all',
        path:'../../assets/images/Mantras/NOVIEMBRE.jpg',
        month:'Noviembre',
        active:true
    },
    {
        name:'Divinidad',
        serieId:'Divinidad',
        path:'../../assets/images/Mantras/DICIEMBRE.jpg',
        month:'Diciembre',
        active:true
    },
]

const IndexPlatform = () => {

    const [primarySectionActive,setPrimarySectionActive] = useState(false);
    const [currentChallenge,setCurrentChallenge]= useState('Abundancia');
    const [switchButton,setSwitchButton]= useState(false);
    const [secondSectionOpen,setSecondSectionOpen] = useState(false);

  return (
    <><section className='index-platform flex gap-3 h-[100vh] overflow-hidden'>
        <VerticalNavBar/>
        <div className="flip-card-section flex flex-wrap w-full">
            <div className="principal-section bg-sc-c2 flex flex-wrap gap-4 lg:gap-8 justify-center items-start pt-10">
                <div className="reflexion-box w-full md:w-1/3 lg:w-[30%]">
                    
                    <div className="image-container w-[180px] pb-5">
                        <img src="../../assets/icons/Position-Orange/yoga-pose-5.png" alt=""/>
                    </div>
                    <div className="phrase font-[MADE-Bon-Voyage] flex flex-col gap-5 text-center text-sb-900">
                        <h5>Sara Asanas</h5>
                        <p>"It's not about being good at something. It's about being good to yourself."</p>
                    </div>
                </div>
                {primarySectionActive ? (
                <div className="spotify-box w-1/3 p-5 flex justify-center items-center">
                    <iframe
                        src="https://open.spotify.com/embed/show/5ixh2ge9moyf2nfEw0EANH?utm_source=generator"
                        width="100%"
                        height="152"
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                    ></iframe>
                </div>
                ):null}
                  
                <div className="classes-box">
                    <SeriesChallengeBox/>
                </div>
                <div className="flex flex-col justify-center items-center gap-2 w-3/4 md:w-1/4 relative bottom-0 lg:bottom-8">
                    <div className="switch-button">
                        <input className="switch-button-checkbox" type="checkbox" onChange={() => setSwitchButton(!switchButton)}></input>
                        <label className="switch-button-label"><span className="switch-button-label-span flex justify-center items-center">Progreso</span></label>
                    </div>
                    {switchButton ? (<div className="spotify-box w-full flex justify-center items-center">
                        <iframe 
                            src="https://open.spotify.com/embed/show/5ixh2ge9moyf2nfEw0EANH?utm_source=generator" 
                            width="100%" height="352"
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                            loading="lazy">
                            
                        </iframe>
                </div>):(<div className="progress-box w-full bg-sb-200 h-fit md:h-[350px] min-w-[300px] col-span-4 rounded-lg p-5 mx-5">
                   <SeriesChallengeSlider/>
                </div>)}
                </div>
                
                 
                
            </div>
            <div className={`secondary-section bg-sb-200 p-7 ${secondSectionOpen ? 'expand':''}`}>
                <div className="flex justify-between">
                    <h5 className='uppercase font-[MADE-Bon-Voyage] pb-1 text-left'>MANDALAS</h5>
                    {secondSectionOpen ? (<button className="text-xl hidden lg:block" onClick={() => setSecondSectionOpen(false)}><KeyboardArrowDownIcon/></button>):(<button className="text-xl hidden lg:block" onClick={() => setSecondSectionOpen(true)}><KeyboardArrowUpIcon/></button>)}
                </div>
                <SliderMandalas/>
                <div className="flex flex-col gap-5 py-5">
                    <h5 className='uppercase font-[MADE-Bon-Voyage] pb-1 text-left'>Retos</h5>
                       <SliderSereiesIndex/>
                </div>
            </div>
        </div>

    </section>
    
    </>
  )
}

export default IndexPlatform