import React, {useEffect} from 'react'
import {Link,useNavigate} from 'react-router-dom';
import NavBar from '../../components/Navbar/NavBar';
import './AboutPage.css'
import ButtonS1 from '../../components/Buttons/ButtonS1';
import SocialMediaBox from '../../components/SocialMediaBox/SocialMediaBox';
import ImageBoxType1 from '../../components/ImageBox/ImageBoxType1';
import GridGallery from '../../components/Gallery/GridGallery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../components/Footer/Footer';


const AboutPage = () => {
  const navigate = useNavigate();

  const navigateToSubscription = () => {
    // 👇️ navigate to /contacts
    navigate('/subscripciones');
  };
  
  useEffect(() =>{
    AOS.init();
  })

  return (
    <>
      <NavBar/>
      <section className="hero-section">
        <div className="slider h-full w-full">
          <img
            className="hero-image hero-image-about"
            src={"../../assets/images/sliders/Sara-P-5.jpg"}
            alt="Slide-1"
          />
          <div className="hero-title-about flex-col gap-4 justify-start" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000" data-aos-delay="500">
            <h1 className="text-sc-c2">SARASANAS</h1>
            <div className="text-content-">
              <p className="text-sc-c2 text-lg font-[DIN-Condensed]">
                Soy <b className='text-2xl font-[MADE-Bon-Voyage]'>psicóloga</b> de profesión y <b className='text-2xl font-[MADE-Bon-Voyage]'>maestra de yoga</b> por pasión.<br></br> Mi misión de vida es fomentar nuestro bienestar integral y desarrollo de consciencia
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mi-bio-section">
        <div className="box-image-left flex flex-wrap">
          <div className="image flex justify-center w-full md:w-1/2 p-2 md:p-10" data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500">
            <ImageBoxType1 name="" source='../../assets/images/sliders/Sara-P-2.jpg'/>
          </div>
          <div className='bg-img-right absolute opacity-5 -z-10 mt-32'>
              <img src={require("../../assets/icons/Mandala/mandala-8.png")} alt=""/>
            </div>
          <div className="text-content w-full md:w-1/2 p-2 md:p-10 flex flex-col justify-center gap-10">
        
            <div data-aos="fade-left"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500">              
              <h2 className='text-sb-800 font-[MADE-Bon-Voyage] uppercase'>Mi Camino</h2>
            </div>
              <div className="box-image-text flex justify-center gap-10 items-end pl-1 md:pl-8">
                <div className="border-vertical bg-sb-900 h-[400px] w-1"></div>
                <p className='text-sb-800 font-[MADE-Bon-Voyage] text-2xl text-left md:text-justify pb-5'>
                 Cuando tenía 17 años, viví un cuadro de depresión muy grave en el que pensé en quitarme la vida; mi mente se había apoderado de mí y yo no entendía qué sucedía.<br></br><br></br>
Fue en ese momento que comprendí la importancia de cuidar nuestra salud de manera integral y que el cuerpo y la mente no estan separados, forman parte de nuestro SER. 
              </p>
              </div>
          </div>          
        </div>
        <div className="box-image-right flex flex-wrap-reverse">
          <div className="text-content w-full md:w-1/2 py-10 pl-8 flex flex-col justify-center gap-10">
            <div className='bg-img absolute left-[-250px] opacity-5 -z-10 mt-32'>
              <img src={require("../../assets/icons/Mandala/mandala-8.png")} alt=""/>
            </div>
            <div data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500">              
              {/*<h2 className='text-sb-800 font-[MADE-Bon-Voyage] uppercase'>Mi Ser</h2>*/}
            </div>
            <div className="box-image-text flex justify-center gap-10 items-end pl-24">
                <p className='text-sb-800 font-[MADE-Bon-Voyage] text-2xl text-left md:text-justify pb-5'>
                  Unos años después, descubrí que nuestro <b>SER</b> también está conformado por un alma.<br></br><br></br>
                  El yoga abrió un camino de consciencia y autoconocimiento mucho más profundo.
                  Hoy no solo es mi práctica de todos los días, es mi filosofía de vida. 
                </p>
                <div className="border-vertical bg-sb-900 h-[400px] w-1"></div>
            </div>
          </div>     
          <div className="image flex justify-center w-full md:w-1/2 p-2 md:p-10" data-aos="fade-left"
     data-aos-easing="ease-in-sine" data-aos-duration="500">
            <ImageBoxType1 name="" source='../../assets/images/sliders/Sara-P-8.jpg'/>
          </div>     
        </div>
      </section>
      <section className="phrase-section bg-sc-c2 flex flex-col justify-center items-center py-16 px-5 md:px-32 gap-10 text-center">
        <h2 className='uppercase text-sb-800 font-[MADE-Bon-Voyage]'>Sara + Asanas</h2>
        <h5 className='text-sb-800 font-[MADE-Bon-Voyage] '>SARASANAS</h5>
        <p className='text-sb-800 font-[MADE-Bon-Voyage] text-xl'>
            Es la fusión de mi nombre Sara, con la palabra asanas (postura en sánscrito) y la intención
             de crear <br></br>espacios de salud, sanación armonía, paz y bienestar para compartirlos con ustedes,
            <br></br><br></br><h6>MI TRIBU.</h6><br></br>
            Clases, programas, experiencias, retiros y más!
        </p>
        <button className='button-30 w-fit px-2' onClick={navigateToSubscription}>Ser de la Tribu</button>
      </section>
      <section className="benefits-section py-5">
          <div className="title w-full">
            <h2 className='text-sb-700 font-[MADE-Bon-Voyage] text-center'>Estilos de Yoga</h2>
          </div>
          <div className="steps-content flex flex-wrap justify-around items-center pb-12">
            <ButtonS1 title="1" class="" imageSrc={8} desc="Vinyasa"/>
            <ButtonS1 title="2" class="" imageSrc={4} desc="Mandala Vinyasa"/>
            <ButtonS1 title="3" class="" imageSrc={6} desc="Meditación"/>
            <ButtonS1 title="4" class="" imageSrc={2} desc="African Mix"/>
            <ButtonS1 title="5" class="" imageSrc={9} desc="Power Yoga"/>
          </div>

        </section>
      <section className="gallery">
        <GridGallery/>
      </section>
      <SocialMediaBox/>
      <Footer/>
    </>
  );
}

export default AboutPage