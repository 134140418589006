import React,{useEffect,useState} from 'react'
import { Alert,Autocomplete,TextField, Button, Grid, Paper, Typography, Box,FormControl,InputAdornment} from '@mui/material';
import { modalStyle } from '../GeneralData';
import { addLiveClass } from '../../../../services/liveClasses';
import { getAllActiveSubscriptions } from '../../../../services/series';

interface Props {
    refreshData: () => void;
    handleClose:() => void;
  }

const AddLiveClassForm:React.FC<Props> = ({refreshData,handleClose}) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        date: getCurrentDateInMexicoCity(),
        startTime: '',
        duration: '',
        subscriptionRequired: false,
        zoomUrl:'',
    });

    const [subscriptionList, setSubscriptionList] = useState([]);
    const [isLoadingModal, setIsLoadingModal] = useState(true);
    const [error,setError] = useState<String|null>();
    const [success,setSuccess]=useState(false);

    useEffect(()=>{
        getSubscription();
    },[])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }))
    };

    const getSubscription = async() =>{
        try{     
          setIsLoadingModal(true);
          const response:any = await getAllActiveSubscriptions();
          const data:any = response.data;
          setSubscriptionList(data);
        }catch(error:any){
            console.log(error);
            setError(error)
        }finally{
          setIsLoadingModal(false);
        }
  
      }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const res = await addLiveClass(formData);
            console.log(res);
            //setMessage(res);
            setSuccess(true);
            
        } catch (error:any) {
            setError(error);
        }
    };

    const handleAllowedSubscriptionsChange = (e: any, values: { planId: string }[]) => {
        const selectedPlanIds = values.map((value) => value.planId);
        setFormData((prevData:any) => ({
          ...prevData,
          allowedSubscriptions: selectedPlanIds,
        }));
      };

      function getCurrentDateInMexicoCity() {
        const now = new Date();
        const mexicoCityOffset = -6 * 60; // Mexico City timezone offset in minutes (UTC-6)
        const localOffset = now.getTimezoneOffset(); // User's local timezone offset in minutes
        const utcTimestamp = now.getTime() + (localOffset * 60 * 1000); // Convert to UTC timestamp
        const mexicoTimestamp = utcTimestamp + (mexicoCityOffset * 60 * 1000); // Add Mexico City timezone offset
        const mexicoDate = new Date(mexicoTimestamp);
        return mexicoDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    }

      const closeSuccessFully=()=>{
        refreshData();
    }
    return (
        <Box sx={modalStyle}>
            {!success ? <Paper sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>Agregar Live Class</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Title"
                                variant="outlined"
                                fullWidth
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="zoomLink"
                                variant="outlined"
                                fullWidth
                                name="zoomUrl"
                                value={formData.zoomUrl}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                                label="Fecha"
                                variant="outlined"
                                fullWidth
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                                label="Start Time"
                                variant="outlined"
                                fullWidth
                                type="time"
                                name="startTime"
                                value={formData.startTime}
                                onChange={handleChange}
                                inputProps={{ step: 1800 }} // step is set to 5 minutes (300 seconds)
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">AM/PM</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Duration (minutes)"
                                variant="outlined"
                                fullWidth
                                type="number"
                                name="duration"
                                value={formData.duration}
                                onChange={handleChange}
                            />
                        </Grid>
                        <FormControl className="p-5" style={{ width: '100%' }}>
                            <Autocomplete
                            style={{ width: '100%' }}
                            multiple
                            id="subscriptions-standard"
                            options={subscriptionList}
                            getOptionLabel={(option:any) => option.planId}
                            onChange={handleAllowedSubscriptionsChange}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                variant="standard"
                                label="Subscripciones"
                                placeholder="Subscripciones"
                                />
                            )}
                            />
                        </FormControl>
                        
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">Agregar</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper> :(<div className="flex flex-col gap-5 justify-center items-center">
        <Alert severity="success">Se Agrego Una Clase de Forma Exitosa</Alert>
          <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
        </div>)}
          
        </Box>
    );
};

export default AddLiveClassForm;
