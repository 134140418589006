import React, { useState, useEffect } from 'react';
import { Menu } from './MenuData'
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './NavBar.css'


const NavBar = () => {
   const [stickyClass, setStickyClass] = useState('relative');
   const [menuOpen,setMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500 ? setStickyClass('fixed top-0 left-0 z-50 navbar-box') : setStickyClass('relative');
    }
  };


  return (
    <nav className={`flex justify-between p-3 items-center w-full bg-slate-500 top-0 ${stickyClass}`}>
      <MenuIcon onClick={()=>setMenuOpen(true)} className={`z-20 burger-menu hidden`}/>
      <img src="../../assets/icons/Logo/Logo-sm.png" width={50} alt="Logo" className='hidden sm:block' />
      <ul className={`menu-items-box gap-5 flex-grow justify-center items-center pl-[5.5rem] ${menuOpen ? 'menu-active':''}`}>
        <CloseIcon className='close-icon absolute' onClick={()=>setMenuOpen(false)}/>
        {Menu.map((menuItem, index) => (
            <Link to={menuItem.path} key={menuItem.name || index}>
                <li className='menu-item'>{menuItem.name}</li>
            </Link>
        ))}
    </ul>
      <ul id="social-icons">
        <a href="https://instagram.com/sar.asanas?igshid=MzRlODBiNWFlZA==" className='icon-img'><img src="../../assets/icons/Socials/instagram_icn.svg" alt="instagram-icon" /></a>
        <a href="https://www.facebook.com/profile.php?id=100038357469052&mibextid=LQQJ4d" className='icon-img'><img src="../../assets/icons/Socials/facebook_icn.svg" alt="facebook-icon" /></a>
        <a href="https://youtube.com/@sar.asanas3495?si=iLg2xdpmsI2XwglC" className='icon-img'><img src="../../assets/icons/Socials/play.png" alt="youtube-icon" /></a>
        <a href="https://open.spotify.com/show/5ixh2ge9moyf2nfEw0EANH?si=83d3c37b6e904ac4" className='icon-img'><img src="../../assets/icons/Socials/Spotify.svg" alt="spotify-icon" /></a>
        {/*<AccountCircleIcon/>*/}
      </ul>
      <img src="../../assets/icons/Logo/Logo-sm.png" width={50} alt="Logo" className='block sm:hidden'/>
    </nav>
  )
}

export default NavBar