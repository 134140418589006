import React, { useEffect, useState } from 'react'
import VerticalNavBar from "../../../components/Navbar/PlatformNavBar/VerticalNavBar";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { Options } from './AdminData';
import DashboardCard from '../../../components/Admin/Cards/DashboardCard';
import { Button } from '@mui/material';
import UserTable from '../../../components/Admin/Tables/UserTable';
import VideosTable from '../../../components/Admin/Tables/VideosTable';
import LiveClasses from '../../../components/Admin/Tables/LiveClasses';
import SeriesTable from '../../../components/Admin/Tables/SeriesTable';
import SubscriptionTable from '../../../components/Admin/Tables/SubscriptionTable';
import VideoUploadStepper from '../../../components/Admin/Stepper/Video/StepperVideoUpload';
import MandalasTable from '../../../components/Admin/Tables/MandalasTable';

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const BigAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "#54989C",
  fontSize: 36,
  width: 120,
  height: 120,
  border: `2px solid ${theme.palette.background.paper}`,
  position: "relative",
  top: "60px",
}));

function stringAvatar(name) {
  if (name.split(" ").length > 1) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return {
    children: `${name.split(" ")[0][0]}`,
  };
}

const AdminPage = () => {
    const [selectOption,setSelectOption] = useState('Usuarios');
    const userId = useSelector((state) => state.user.currentUser);
    const userName = userId?.fullName;
    
    const handleOption =(option) =>{
      setSelectOption(option);
    }

    const getAdminTable =(option)=>{
      switch(option){
        case "Usuarios":
          return <UserTable></UserTable>
        case "Videos":
          return <VideosTable/>
        case "Clases en Vivo":
          return <LiveClasses/>
        case "Retos":
          return <SeriesTable/>
        case "Mandalas":
          return <MandalasTable/>
        case "Subscripciones":
          return <SubscriptionTable/>
        default:
          return <UserTable></UserTable>
      }
    }

  return (<>
      <section className="index-platform flex">
        <VerticalNavBar />
        <div className='w-full'>
          <div className="header bg-so-300 w-full h-48 flex justify-center items-end relative">
            <StyledBadge overlap="circular">
              {userId ? (
                <BigAvatar {...stringAvatar(userId?.fullName)} />
              ) : (
                <BigAvatar {...stringAvatar(userName)} />
              )}
            </StyledBadge>
          </div>
          <div className='mt-20 ml-10'>
            <h3 className='text-[DIN-Condensed] font-bold text-3xl'>Menu de Opciones</h3>
          </div>
          <div id="top-function-dashboard" className='flex flex-wrap justify-center gap-10 py-10 ml-10'>
            {Options.map((option,index)=>{
              return <Button key={index} onClick={() => handleOption(option.title)} className='text-black'><DashboardCard 
              title={option.title} 
              value={0}
              icon={option.icon}
              />
              </Button>
            })}
          </div>
          <div className="flex justify-center w-full my-10">
            <div className='flex flex-col justify-center items-center gap-5 shadow-2xl w-10/12 rounded-xl py-10'>
              <h6>{selectOption}</h6>
              {getAdminTable(selectOption)}
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default AdminPage