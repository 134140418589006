import {
    publicRequest,
  } from "../requestMethods/requestMethods";

export const getMandalaByName = async(name) =>{
    try {
        const res = await publicRequest.get(`/mandalas/findByName/${name}`);
        return res;
    } catch (error) {
        return error;    
    }
}

export const getAllMandalas = async() =>{
    try {
        const res = await publicRequest.get('/mandalas/findAll');
        return res;
    } catch (error) {
        return error;    
    }
}

export const addMandala = async(mandala) =>{
    try {
        const res = await publicRequest.post(`/mandalas/add`,mandala);
        return res;
    } catch (error) {
        return error;    
    }
}

export const updateMandalaById= async(id,mandala) =>{
    try {
        const res = await publicRequest.put(`/mandalas/update/${id}`,mandala);
        return res;
    } catch (error) {
        return error;    
    }
}

export const updateStatusMandala= async(seriesId,status) =>{
    try {
        const res = await publicRequest.put(`/mandalas/updateMandalaStatus/${seriesId}/status/${status}`);
        return res;
    } catch (error) {
        return error;    
    }
}


export const deleteMandala = async(mandalaId) =>{
    try {
        const res = await publicRequest.delete(`/mandalas/remove/${mandalaId}`);
        return res;
    } catch (error) {
        return error;    
    }
}


