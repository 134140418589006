import React from 'react';
import './ButtonsS1.css';

interface ButtonSpec{
    title:string;
    imageSrc:number;
    class:string;
    desc:string;
}

interface ImageSource{
    name:string;
    src:string;
    class:string;
}

const Icons:ImageSource[]=[
    {
        name:'Yoga-1',
        src:'../../assets/icons/Position-Orange/yoga-pose-3.png',
        class:'icon-1'
    },
    {
        name:'Yoga-2',
        src:'../../assets/icons/Position-Orange/yoga-pose-4.png',
        class:'icon-1'
    },
    {
        name:'Yoga-3',
        src:'../../assets/icons/Position-Orange/yoga-pose-5.png',
        class:'icon-2'
    },
    {
        name:'Yoga-4',
        src:'../../assets/icons/Position-Orange/yoga-pose-6.png',
        class:'icon-3'
    },
    {
        name:'Yoga-5',
        src:'../../assets/icons/Position-Orange/yoga-pose-7.png',
        class:'icon-4'
    },
    {
        name:'Yoga-6',
        src:'../../assets/icons/Position-Orange/yoga-pose-8.png',
        class:'icon-1'
    },
    {
        name:'Yoga-7',
        src:'../../assets/icons/Position-Orange/yoga-pose-2.png',
        class:'icon-4'
    },
    {
        name:'Yoga-8',
        src:'../../assets/icons/Position-Orange/yoga-pose.png',
        class:'icon-1'
    },
    {
        name:'Yoga-9',
        src:'../../assets/icons/Position-Orange/yoga-pose-9.png',
        class:'icon-5'
    },
    {
        name:'Yoga-10',
        src:'../../assets/icons/Position-Orange/yoga-pose-10.png',
        class:'icon-4'
    }
    
]



const ButtonS1 = (props:ButtonSpec) => {
  return <>
    <div className='button-box-3 flex flex-col justify-center items-center'>    
    <div className='button-3 lex flex-col justify-center items-center'>
        <button>{props.title}
            <div className={Icons[props.imageSrc].class} >
            <img src={Icons[props.imageSrc].src} alt={Icons[props.imageSrc].name} loading='lazy'/>
            </div>
        </button>
        
    </div>
    <h6 className='text-sb-700 font-[DIN-Condensed] font-bold text-center pt-5 max-w-[100px] uppercase'>{props.desc}</h6>
    </div>

    </>
}

export default ButtonS1