import {
  loginFailure,
  loginStart,
  loginSuccess,
  logOut,
  addUserStart,
  addUserSuccess,
  addAccessUserStart,
  addAccessUserSuccess,
  addUserFailure,
  restartPasswordRequestStart,
  restartPasswordRequestSuccess,
  restartPasswordRequestFailure
} from "../redux/userRedux";

import {
  publicRequest,
  userRequest,
  userRequestByParams,
  createAxiosInstance,
} from "../requestMethods/requestMethods";

export const login = async (dispatch, loginRequest) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/access/login", loginRequest);
    dispatch(loginSuccess(res.data));
    return res.data;
  } catch (err) {
    dispatch(loginFailure());
    return err;
  }
};

export const addUser = async (dispatch, user) => {
  console.log(user);
  dispatch(addUserStart());
  try {
    const res = await publicRequest.post("/user/create", user);
    console.log(res.data);
    dispatch(addUserSuccess(res.data));
    return res.data;
  } catch (err) {
    dispatch(addUserFailure());
    return err;
  }
};

export const addAccessUser = async (dispatch, user) => {
  console.log(user);
  dispatch(addAccessUserStart());
  try {
    const res = await publicRequest.post("/access/create", user);
      dispatch(addAccessUserSuccess(res.data));
      return res;
  } catch (err) {
    console.log(err.response.data);
    alert(err.response.data);
    dispatch(addUserFailure());
    return err;
  }
};

export const getAllUsers = async() =>{
  try
  {
    const res = await publicRequest.get(`/user/findAllUser`);
    return res;
  }catch(err){
    return err;
  }
}

export const findUserByEmail = async(dispatch,userEmail) =>{
  dispatch(addAccessUserStart());
  try
  {
    const res = await publicRequest.get(`/user/by-email/${userEmail}`);
    dispatch(addAccessUserSuccess(res.data));
    return res;

  }catch(err){
    dispatch(addUserFailure());
    return err;
  }

}

export const getUserInfoByEmail = async(dispatch,userEmail) =>{
  try
  {
    const res = await publicRequest.get(`/user/by-email/${userEmail}`);
    return res;
  }catch(err){
    return err;
  }

}

export const addSubscriptionPlan = async(userEmail,subscription) =>{
  try {
    const res = await publicRequest.post(`/user/add-subscription/${userEmail}`,subscription);
    return res;
  } catch (err) {
    return err;
  }

}

export const addSubscription= async(userId,planId) => {
  const params ={
      userId: userId,
      planId:planId,
  }
  return await userRequestByParams(params).post("/user/addSubscription");
}


export async function updateUserById(userId,user){
  try {
    const res = await publicRequest.put(
      `/user/update/${userId}`,user
    );
    return res.data;
  } catch (err) {
    return err;
  }

}

export const logout = async (dispatch) => {
  dispatch(logOut());
};

export const requestResetPassword = async (dispatch, emailSet) => {
  try {
    console.log(emailSet);
    dispatch(restartPasswordRequestStart());
    const res = await userRequest.post(`/access/request?email=${emailSet}`);
    console.log(res);
    console.log(res.data);
     dispatch(restartPasswordRequestSuccess());
    return res.data;
  } catch (err) {

    dispatch(restartPasswordRequestFailure());
    return err;
  }
};

export const resetPassword = async (dispatch, resetPassword) => {
  try {
    dispatch(restartPasswordRequestStart());
    const res = await userRequest.post('/access/reset',resetPassword);
    console.log(res.data);
    dispatch(restartPasswordRequestSuccess());
    return res.data;
  } catch (err) {
    dispatch(restartPasswordRequestFailure());
    return err;
  }
};

export const changeUserPassword = async (dispatch, email, oldPassword, newPassword) => {
  try {
    dispatch(restartPasswordRequestStart());

    const axiosInstance = createAxiosInstance(  );

    const res = await axiosInstance.post("/access/change-password");
    console.log(res);
    dispatch(restartPasswordRequestSuccess());
    return res.data;
  } catch (error) {
    dispatch(restartPasswordRequestFailure());
     if (error.response) {
       if (error.response.status === 404) {
         console.log("User not found");
       } else if (error.response.status === 403) {
         console.log("Password does not match");
       } else {
         console.log("Unexpected error:", error.response.status);
       }
     } else {
       console.error("Network error:", error.message);
     }
    return error;
  }
};

export const checkHealth = async () => {
  try {
    const res = await userRequest.get("/access/health");
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function removeUserById(userId){
  try {
    const res = await publicRequest.delete(
      `/user/remove/${userId}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export async function removeAccessUserByEmail(email){
  try {
    const res = await publicRequest.delete(
      `/access/remove/${email}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

