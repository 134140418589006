import React,{useEffect,useState} from 'react'
import { DataGrid, GridColDef, GridValueGetterParams, esES } from '@mui/x-data-grid';
import {Modal,Chip, Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllUsers,getUserInfoByEmail } from '../../../services/user';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RemoveUserForm from '../Stepper/User/RemoveUserForm';
import UpdateUserForm from '../Stepper/User/UpdateUserForm';
import AddUserForm from '../Stepper/User/AddUserForm';


const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES, // x-data-grid translations
  );


  
  
const UserTable = () => {
    const [userList, setUserList] = useState([]);
    const [activeOption,setActiveOption] =useState();
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser,setCurrentUser] = useState();
    const [error,setError] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() =>{
        getAllUsersList();
      },[])
      
      const columns = [
        { field: 'username', headerName: 'ID', width: 70, height:'fit-content' },
        { field: 'fullName', headerName: 'First name', width: 130 },
        { field: 'email', headerName: 'Last name', width: 250 },
        { field: 'subscriptionPlanId', headerName: 'Subscription Plan ID', width: 350, renderCell: (params) => (
          <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
            {params.value.slice(0, 2).map((plan, index) => (
              <Chip key={index} label={`${plan.substring(0, 10)}...`} style={{ marginRight: '4px',background:'#F9AE00' }} />
              ))}
              {params.value.length > 2 && (
                  <Chip key="more" label={`+${params.value.length - 2} mas`} style={{ marginRight: '4px',background:'#F9AE00' }} />
              )}
          </ul>
          ), },
        { field: 'actions', headerName: 'Acciones', width: 300, renderCell: (params) => (
          <div className="flex gap-2">
            <IconButton aria-label="info" size="small" onClick={() =>updateUser(params.row)}>
              <Info />
            </IconButton>
            <IconButton aria-label="remove" size="small" onClick={() =>removeUser(params.row)}>
              <Delete />
            </IconButton>
          </div>
        ), }
      ];

    const getAllUsersList =  async () => {
        try{     
            setIsLoading(true);
            const response = await getAllUsers();
            const data = response.data;
            setUserList(data)
        }catch(error){
            console.log(error);
        }finally{
            setIsLoading(false);
        }
    };

    const getUserInfo = (user) =>{
      try {
       setCurrentUser(user);;
      } catch (error) {
        console.log(error) 
      }

    }

    const handleOpen = async (option) => {
      try {
        setOpen(true);
        setActiveOption(option);
      } catch (error) {
        console.error('Error while opening:', error);
      }
    };

    const handleClose = () => {
      setOpen(false)
    };

    const removeUser=(user) =>{
      setCurrentUser(user);
      console.log(user);
      handleOpen('remove');
    }

    const updateUser=(user) =>{
      setCurrentUser(user);
      handleOpen('update');
    }

    const refreshData=()=>{
      handleClose();
      getAllUsersList();
    }
    

    const handleUserOption =(action) =>{

      switch (action) {
        case 'add':
          return <AddUserForm handleClose={handleClose} refreshData={refreshData}/>;
        case 'update':
          return <UpdateUserForm user={currentUser} handleClose={handleClose} refreshData={refreshData}/>;
        case 'remove':
          return <RemoveUserForm user={currentUser} handleClose={handleClose} refreshData={refreshData}/>;
        default:
          return <UpdateUserForm user={currentUser} handleClose={handleClose} refreshData={refreshData}/>;
      }
    }

  return (
  <div style={{ height: 'auto', width: '100%' }}>
    {isLoading ? (
  <div className="flex justify-center items-center">
    <CircularProgress />
  </div>
) : error ? (
  <div>Error: {error.message}</div>
) : (
  <ThemeProvider theme={theme}>
    <DataGrid
      rows={userList}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10]}
      getRowId={(row) => row.userId}
      autoHeight
    />
  </ThemeProvider>
)}
  {error && (<p>{error} error</p>)}
  <div className="flex justify-center p-5">
    <Button className="button-29 w-auto" onClick={() => handleOpen('add')} variant='contained'>
      Agregar Usuario
    </Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {handleUserOption(activeOption)}
    </Modal>
  </div>

 </div>
  )
}

export default UserTable