import React, { useEffect, useState } from 'react'
import ButtonS2 from '../Buttons/ButtonS2';
import './CardBox2.css'
import { Link } from 'react-router-dom';
import { yogaTypes,YogaButton } from '../../Data/YogaTypes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSelector } from 'react-redux';


const CardBox2 = (Info) => {
    const [yoga,setYoga]=useState();
    const [isLoading,setIsLoading]=useState(Info.isLoading);
    const [activeSub, setActiveSub] = useState(false);
    const currentUser = useSelector(state => state.user.currentUser);

    useEffect(()=>{
        getYogaType();
    },[yoga])

    useEffect(() => {
      checkSubscription();
    }, [currentUser]);

    const getYogaType = ()=>{
        const type= yogaTypes.find((item) =>{
            return item.title === Info.yogaType
        })
        setYoga(type);
    }

    const checkSubscription = () =>{
      const subscription = currentUser.subscriptionPlanId;
      
      if(subscription.includes("BASICA") || subscription.includes("PREMIUM") || subscription.includes("RETOMA-ENCIENDE-ACTIVA") || subscription.includes("RETOMA-ENCIENDE-ACTIVA-AP")){
        setActiveSub(true);
      }else{
        setActiveSub(false);
      }
    }


  return (
    <>
      <div className="card-box flex flex-col gap-1">
        <div className="flex gap-3 items-center justify-around">
          <div>
            <ButtonS2
              iconPath={yoga ? yoga.icon : null}
              isLoading={isLoading}
            />
          </div>
            <p
              className={`font-[DIN-Condensed] text-sc-c2 text-lg ${
                isLoading ? "is-loading" : ""
              }`}
            >
              {Info.title}
            </p> 
            {activeSub ? (
              <Link to={`/video/${Info.path}`}>
              <button
                className={`button-2 font-[MADE-Bon-Voyage] ${
                  Info.enable ? "active" : "desactive"
                }`}
                disabled={isLoading}
              >
                {Info.enable ? (
                  "Completado"
                ) : (
                  "En Curso"
                )}
                  </button>
              </Link>
            ) : (
              <button
                className={`button-2 font-[MADE-Bon-Voyage] ${
                  Info.enable ? "active" : "desactive"
                }`}
                disabled={isLoading}
              >
                <div className="flex justify-center">
                  <img
                    id="c-box-img"
                    src="../../assets/icons/Forms/lock-outline.png"
                    alt="Lock-icon"
                    width={25}
                  />
                </div>
              </button>
            )}
        </div>
        <div
          className={`video-spec flex justify-center gap-1 h-fit ${
            isLoading ? "is-loading" : ""
          }`}
        >
          <div
            className={`icon-box flex gap-1 justify-center items-center w-1/3`}
          >
            <AccessTimeIcon sx={{ color: "rgba(250, 250, 250)" }} />
            <p
              className={`font-[DIN-Condensed] font-bold uppercase text-sc-c2`}
            >
              {Info.duration + " Min"}
            </p>
          </div>
          <div className="vertical-border"></div>
          <div className="icon-box flex flex-grow gap-1 justify-center items-center">
            <p className="font-[DIN-Condensed] font-bold uppercase text-sc-c2">
              {Info.level}
            </p>
          </div>
          <div className="vertical-border"></div>
          <div className="icon-box flex justify-center items-center gap-1 w-1/5">
            <img src="../../assets/icons/Forms/Level-4.svg" alt="clock-icon" />
          </div>
        </div>
      </div>
    </>
  );
}

export default CardBox2