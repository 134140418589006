import React, { useState, useEffect, useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword } from "../../../services/user";
import { restartError } from "../../../redux/userRedux";
import VerticalNavBar from "../../../components/Navbar/PlatformNavBar/VerticalNavBar";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SellIcon from "@mui/icons-material/Sell";
import './UserDashboard.css';
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserInfoByEmail } from "../../../services/user";
import { getSubscriptionById } from "../../../services/stripe";
import { convertUnixTimeMexicoToLocalDate } from "../../../utils/timeConvertor";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const BigAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "#54989C",
  fontSize:36,
  width: 120,
  height: 120,
  border: `2px solid ${theme.palette.background.paper}`,
  position: "relative",
  top: "60px",
}));

function stringAvatar(name) {
  if (name.split(" ").length > 1) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return {
    children: `${name.split(" ")[0][0]}`,
  };
}



const UserDashboard = () => {

  const [userId,setUserId] = useState(useSelector((state) => state.user.currentUser));
  const userName = userId?.fullName;
  const [errorMessage, setErrorMessage] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { isFetching, error } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setNewShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);

  const dispatch = useDispatch();


  useEffect(()=>{
    const fetchData = async () => {
      await checkSubscription();
      checkSubscriptionStatus();
    };
  
    fetchData();

  },[])

  const checkSubscription= async()=>{
    try {
      setIsLoading(true);
      const res = await getUserInfoByEmail(dispatch,userId.email);
      setUserId(res.data);
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false);
    }
  }

  const checkSubscriptionStatus = async () => {
    setSubscriptionDetails([]);
    console.log(userId.subscriptionPlanId);
    try {
      const hasBasicOrPremiumSubscription = userId.subscriptionPlanId.some((subscriptionItem) =>
      ['BASICA', 'PREMIUM', 'MEDITACION'].includes(subscriptionItem.trim().toUpperCase())
      );

      if (hasBasicOrPremiumSubscription) {
        for (const subscription of userId.subscriptions) {
          if (['BASICA', 'PREMIUM', 'MEDITACION'].includes(subscription.planId.trim().toUpperCase())) {
            try {
              const fetchedSubscription = await getSubscriptionById(subscription.id);
              setSubscriptionDetails(subscriptionDetails => [...subscriptionDetails, fetchedSubscription]);
            } catch (error) {
              console.error('Error fetching subscription details:', error);
              setSubscriptionDetails(subscriptionDetails => [...subscriptionDetails, { error: true }]);
            }
          }
        }
      }

    } catch (error) {
      console.error('Error fetching subscription details:', error);
      // Handle error gracefully, e.g., display an error message
    }
  };

  const renderSubscriptionDetails = (subscription) => {
    if (subscriptionDetails.length === 0) {
      // Render loading indicator or placeholder
      return <CircularProgress />; // or any other placeholder component
    }

  

     return subscriptionDetails.map((item,index)=>{
      if (!item.currentPeriodEnd) {
        return null; // Skip rendering if expiration date is undefined
      }

      return  <>
      <TextField
             id="input-with-icon-textfield"
             sx={{width:'fit-content',padding:'5px'}}
             label="Subcripción"
             value={subscription}
             InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <CardMembershipIcon />
                 </InputAdornment>
               ),
             }}
             variant="standard"
           />
           <TextField
             id="input-with-icon-textfield"
             label="Tipo de Subscripción"
             value={subscription === "BASICA" ||  subscription === "PREMIUM" || subscription === "MEDITACION" ? "Pago Mensual":"Pago Unico"}
             InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <SellIcon />
                 </InputAdornment>
               ),
             }}
             variant="standard"
           />
       <TextField
         id="input-with-icon-textfield"
         label="Expiración"
         value={item.currentPeriodEnd ? convertUnixTimeMexicoToLocalDate(item.currentPeriodEnd):'Indefinido'}
         InputProps={{
           startAdornment: (
             <InputAdornment position="start">
               <AccessTimeIcon />
             </InputAdornment>
           ),
         }}
         variant="standard"
       />
       <div className="flex gap-2">
         <h6 className="text-2xl font-[DIN-Condensed] uppercase">Status:</h6>
         {item.status !== 'active' ? (
           <Chip label="Desactivada" color="error" />
         ) : (
           <Chip label="Activa" color="success" />
         )}
       </div>
      <div className="flex flex-wrap justify-center gap-5 w-full py-5">
        <p
          className="text-sb-500 hover:text-sb-800 text-xl font-[DIN-Condensed] cursor-pointer"
          onClick={openNewTab}
        >
          Revisar Subscripción
        </p>
        <p
          className="hidden text-[#dc2626] hover:text-[#7f1d1d] text-xl font-[DIN-Condensed] cursor-pointer"
          onClick={() => setChangePassword(!changePassword)}
        >
          Cancelar Subcripción
        </p>
      </div>
   
     </>
    })

  };



  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const openNewTab = () => {
    window.open(
      "https://billing.stripe.com/p/login/9AQ8xu0Nf69SeUU8ww",
      "_blank"
    );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleResetPassword = async () => {
    try {
      if (checkMatchPassword()) {
        const response = await changeUserPassword(
          dispatch,
          userId.email,
          password,
          newPassword
        );
        if (response === "Password changed successfully.") {
          setResetPasswordSuccess(true);
          alert("La contraseña se actualizado de forma exitosa");
        } else {
          if (response.request.status === 404) {
            alert("User not found");
            setErrorMessage("Infromación incorrecta");
          } else if (response.request.status === 403) {
            console.log("Password does not match");
            setErrorMessage("Contraseña Incorrecta")
          } else {
            alert("Unexpected error:", response.request.status);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkMatchPassword = () => {
    if (newPassword === confirmPassword) {
      return true;
    } else {
      console.log("PasswordMatch" + newPassword + 'confirmn' + confirmPassword);
      setNewPassword("");
      setConfirmPassword("");
      alert("Las contraseñas no coinciden");
      return false;
    }
  };

  return (
    <section className="index-platform flex">
      <VerticalNavBar />
      <div className="w-full">
        <div className="header bg-so-300 w-full h-48 flex justify-center items-end relative">
          <StyledBadge overlap="circular">
            {userId ? (
              <BigAvatar {...stringAvatar(userId?.fullName)} />
            ) : (
              <BigAvatar {...stringAvatar(userName)} />
            )}
          </StyledBadge>
        </div>
        <div className="user-info mt-20  mb-5 w-full flex flex-col gap-2">
          <h3 className="text-2xl font-[MADE-Bon-Voyage] px-8 py-5">
            Información de Contacto
          </h3>
          <div className="info flex flex-wrap gap-5 justify-center">
            <FormControl fyulvariant="standard">
              <InputLabel htmlFor="input-with-icon-adornment">
                Nombre
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={userId.fullName}
                startAdornment={
                  <InputAdornment position="start">
                    <BadgeIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel htmlFor="input-with-icon-adornment">
                Usuario
              </InputLabel>
              <Input
                className="w-[250px]"
                id="input-with-icon-adornment"
                value={userId.username}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel htmlFor="input-with-icon-adornment">
                Correo Electronico
              </InputLabel>
              <Input
                className="w-[250px]"
                id="input-with-icon-adornment"
                value={userId.email}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="w-full pl-10">
              <p
                className="text-sb-500 hover:text-sb-800 text-xl font-[DIN-Condensed] cursor-pointer"
                onClick={() => setChangePassword(!changePassword)}
              >
                Cambiar contraseña ?
              </p>
            </div>
            <div
              className={`change-password-container ${
                changePassword ? "flex" : "hidden"
              } flex-wrap justify-center items-center w-full `}
            >
              <Card sx={{ minWidth: 275 }}>
                <CardContent className="flex flex-wrap justify-center items-center">
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Contraseña Actual
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Nueva Contraseña
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showNewPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setNewShowPassword(!showNewPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirmar Contraseña
                    </InputLabel>
                    <OutlinedInput
                      id="filled-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="ConfirmPassword"
                    />
                  </FormControl>
                  <button
                    onClick={() => handleResetPassword()}
                    className="button-30 w-fit px-2"
                  >
                    {isFetching ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      "Continuar"
                    )}
                  </button>
                  {error ? (
                    <Alert severity="error" sx={{ m: 1, width: "25ch" }}>
                      {errorMessage}
                    </Alert>
                  ) : null}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <div className="subscription-info  w-full mb-10">
          <h3 className="text-2xl font-[MADE-Bon-Voyage] px-8 py-5">
            Detalles de Subscripción
          </h3>
          {userId.subscriptionPlanId
            .filter(subscription => !['BASICA', 'PREMIUM', 'MEDITACION'].includes(subscription))
            .map((subscription, index) => {
              return (
                <div key={index} className="info flex flex-wrap items-center gap-5 justify-center my-5">
                  <TextField
                    id="input-with-icon-textfield"
                    sx={{ width: 'fit-content', padding: '5px' }}
                    label="Subcripción"
                    value={subscription}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CardMembershipIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    id="input-with-icon-textfield"
                    label="Tipo de Subscripción"
                    value="Pago Unico"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SellIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    id="input-with-icon-textfield"
                    label="Expiración"
                    value="Indefinida"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <div className="flex gap-2">
                    <h6 className="text-2xl font-[DIN-Condensed] uppercase">Status:</h6>
                    <Chip label="Activa" color="success" />
                  </div>
                </div>
              );
          })}

          
        {userId.subscriptionPlanId
            .filter(subscription => ['BASICA', 'PREMIUM', 'MEDITACION'].includes(subscription))
            .map((subscription, index) => {                 
            return <div key={index} className="info flex flex-wrap items-center gap-5 justify-center my-5">
            {
                subscriptionDetails ? (
                  // Render subscription details if available
                  renderSubscriptionDetails(subscription)
                ) : (
                  // Render loading indicator or placeholder
                  <CircularProgress /> // or any other placeholder component
                )
      
            }
          
            {/*<div className="">
              <p
                className="text-sb-500 hover:text-sb-800 text-xl font-[DIN-Condensed] cursor-pointer"
                onClick={handleOpenReto}
              >
                Activar Token
              </p>
            </div>*/}
          </div>
            

          })}
          
        </div>
      </div>
      {/*<Modal
        open={openReto}
        onClose={handleCloseReto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ActiveToken />
        </Box>
      </Modal>
      */}
    </section>
  );
};

export default UserDashboard;
