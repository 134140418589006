import { useSelector } from "react-redux";
import {Route, Routes } from "react-router-dom";
import "./index.css";
import FrontPage from "./pages/FrontPage/FrontPage";
import AboutPage from "./pages/About/AboutPage";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import ContactPage from "./pages/ContactPage/ContactPage"; 
import LoginPage from "./pages/Platform/LoginPage/LoginPage";
import RegisterPage from "./pages/Platform/Register/RegisterPage";
import IndexPlatform from "./pages/Platform/IndexPlatform/IndexPlatform";
import VideoLibray from "./pages/Platform/VideoLibrary/VideoLibray";
import VideoPlayer from "./pages/Platform/VideoPlayer/VideoPlayer";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import VideoLibraryPro from "./pages/Platform/VideoLibrary/VideoLibraryPro";
import SeriesDetailsPage from "./pages/Platform/SeriesDetailsPage/SeriesDetailsPage";
import LiveClassesPage from "./pages/Platform/LiveClasses/LiveClassesPage";
import UserDashboard from "./pages/Platform/UserPage/UserDashboard";
import RegisterPageTrial from "./pages/Platform/Register/RegisterPageTrial";
import PaymentSucessPage from "./pages/Platform/Register/PaymentSuccessPage";
import ChallengePage from "./pages/Platform/ChallengePage/ChallengePage";
import AdminPage from "./pages/Platform/Admin/AdminPage";
import RegisterPageFree from "./pages/Platform/Register/RegisterPageFree";
import PromoPage from "./pages/PromotionPage/PromoPage";
import PromoPageType1 from "./pages/PromotionPage/PromoPageType1";
import ChatPage from "./pages/Platform/ChatPage/ChatPage";


function AppIndex() {
  
  const user = useSelector((state) => state.user.currentUser);
  const isAdmin = user && (user.username === 'sarasanas' || user.username === 'Luis Munoz');
  const adminElement = isAdmin ? <AdminPage /> : <LoginPage />;
  const indexPlatformElement = user && !isAdmin ? <IndexPlatform /> : adminElement;

  return (
    <>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/Un-Poco-de-Mi" element={<AboutPage />} />
        <Route path="/subscripciones" element={<SubscriptionPage />} />
        <Route path="/contacto" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/payment-success" element={<PaymentSucessPage />} />
        <Route path="/promotion" element={<PromoPage/>} />
        <Route path="/promotion/:token" element={<PromoPageType1/>} />
        <Route path="/registro" element={<RegisterPage />} />
        <Route path="/registro/:token" element={<RegisterPage />} />
        <Route path="/registro-mind/:token" element={<RegisterPageTrial />} />
        <Route path="/registro-body/:token" element={<RegisterPageTrial />} />
        <Route path="/registro-retoma-enciende-activa/:token" element={<RegisterPageTrial />} />
        <Route path="/registro-retoma-enciende-activa-ap/:token" element={<RegisterPageTrial />} />
        <Route path="/registro/:subcription/:token" element={<RegisterPage/>} />
        <Route
          path="/registro-reto-estres-ansiedad/:token"
          element={<RegisterPageTrial />}
        />
        <Route
          path="/registro-usuario"
          element={<RegisterPageFree />}
        />
        <Route
          path="/registro-trail/:token"
          element={<RegisterPageFree />}
        />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route
          path="/index"
          element={user ? <IndexPlatform /> : <LoginPage />}
        />
        <Route
          path="/user"
          element={user ? <UserDashboard /> : <LoginPage />}
        />
        <Route
          path="/yogaClasses"
          element={user ? <VideoLibraryPro /> : <LoginPage />}
        />
        <Route
          path="/library/:serieid"
          element={user ? <VideoLibray /> : <LoginPage />}
        />
        <Route
          path="/seriesDetails/:serieid"
          element={user ? <SeriesDetailsPage /> : <LoginPage />}
        />
        <Route
          path="/video/:videoid"
          element={user ? <VideoPlayer /> : <LoginPage />}
        />
        <Route
          path="/liveClasses"
          element={user ? <LiveClassesPage /> : <LoginPage />}
        />
        <Route
          path="/challenges"
          element={user ? <ChallengePage /> : <LoginPage />}
        />
        <Route
          path="/chat"
          element={user ? <ChatPage /> : <LoginPage />}
        />
        <Route 
            path="/admin" 
            element={indexPlatformElement} 
          />
        
      </Routes>
    </>
  );
}

export default AppIndex;
