import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import './ChatPageStyle.css';
import VerticalNavBar from '../../../components/Navbar/PlatformNavBar/VerticalNavBar';
import ChatBox from '../../../components/CardsBox/ChatBox';
import ChatInput from '../../../components/CardsBox/ChatInput';
import { createMessage, getLastMessagesByTypeAndReference, getNextMessagesByTypeAndReference, removeMessage } from "../../../services/messages";
import Loader from "../../../components/Loaders/Loader";

const ChatPage = () => {
    const [userId, setUserId] = useState(useSelector((state) => state.user.currentUser));
    const userName = userId?.fullName;
    const [messageList, setMessageList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPage,setTotalPage]=useState(99);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const messageType = "FORUM";
    const referenceId = "PR-1";
    
    const chatContainerRef = useRef(null);

    useEffect(() => {
        getLastetMessages();
    }, []);

    useEffect(() => {
        const isAtBottom = chatContainerRef.current.scrollTop + chatContainerRef.current.clientHeight >= chatContainerRef.current.scrollHeight;
        if (isAtBottom) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messageList,page]);

    const handleScroll = () => {
        const isAtTop = chatContainerRef.current.scrollTop === 0;
        if (isAtTop && !isLoadingMore) {
            loadMoreMessages();
        }
    };

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
    
            return () => {
                chatContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isLoadingMore]);

    const getLastetMessages = async () => {
        try {
            const res = await getLastMessagesByTypeAndReference(messageType, referenceId);
            setMessageList(res.data.sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate)));
        } catch (error) {
            console.error('Error fetching latest messages:', error);
        }
    };

    const loadMoreMessages = async () => {
        setIsLoadingMore(true);
        try {
            if(page >= totalPage-1){
                console.log("final")
                return;
            }
            const res = await getNextMessagesByTypeAndReference(messageType, referenceId, page + 1);
            setPage(page + 1);
            setTotalPage(res.data.totalPages);
            console.log(res);
            if(res && res.data.content.length !== 0){
                setMessageList(prevMessages => [...res.data.content.reverse(), ...prevMessages]);
            }
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; // Scroll to bottom after adding messages
        } catch (error) {
            console.error('Error loading more messages:', error);
        } finally {
            setIsLoadingMore(false);
        }
    };

    const sendMessage = async (textMessage) => {
        const messageModel = {
            messageType: "FORUM",
            referenceId: "PR-1",
            content: textMessage,
            user: {
                id: userId.userId,
                name: userName
            }
        };
       
        try {
            const res = await createMessage(messageModel);
            console.log(res);
            getLastetMessages();
        } catch (error) {
            console.error('Error sending message:', error);
        }
    
    };

    const deleteMessage = async (messageId) => {       
        try {
            const res = await removeMessage(messageId);
            console.log(res);
            getLastetMessages();
        } catch (error) {
            console.error('Error sending message:', error);
        }
    
    };

    return (
        <section className='index-platform flex gap-3 '>
            <VerticalNavBar />
            <div className='h-[100vh] md:h-auto flex flex-col justify-start items-center pb-5 sm:px-8 md:px-12 w-full'>
                <div className=" flex flex-col justify-center items-center pb-10">
                    <div className="image-container w-12 h-20">
                        <img src="../../assets/icons/Logo/Logo-sm.png" alt="Logo-SA" />
                    </div>
                    <div className="text text-center">
                        <p className="font-[Generica]">
                            "It's not about being good at something. It's about being good
                            to yourself."
                        </p>
                    </div>
                </div>
                <div className='chat-section flex flex-col justify-center md:justify-end items-center md:items-end w-full mb-10'>
                    <div className="chat_container flex flex-col gap-5 p-10 w-11/12 md:w-full h-10/12" ref={chatContainerRef}>
                       {isLoadingMore && <Loader/>} 
                        {messageList && messageList.map((message, index) => (
                            userId.userId === message.user.id ? (
                                <div key={index} className="flex justify-end">
                                    <ChatBox user={true} message={message} removeMessage={deleteMessage} />
                                </div>
                            ) : (
                                <div key={index} className="flex justify-start">
                                    <ChatBox user={false} message={message} removeMessage={deleteMessage}/>
                                </div>
                            )
                        ))}
                    </div>
                    <div className="flex justify-start my-5 w-full">
                    <div className="flex justify-center items-center h-full">
                    </div>
                        <ChatInput onSendMessage={sendMessage} topValue={0}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChatPage;
