import React, { useState, useEffect,useR } from "react";
import VerticalNavBar from "../../../components/Navbar/PlatformNavBar/VerticalNavBar";
import CalendarSlider from "../../../components/Calendar/CalendarSlider";
import {useSelector } from "react-redux";
import CardBoxVideoLive from "../../../components/CardsBox/CardBoxVideoLive";
import CardBoxVideo from "../../../components/CardsBox/CardBoxVideo";
import { getLiveVideosFromCurrentDate } from "../../../services/liveClasses";
import moment from "moment";
import { Link } from 'react-router-dom';

const LiveClassesPage = () => {
  const [videosList, setVideosList] = useState([]);
  const [filteredListLiveClasses,setFilteredListLiveClasses] = useState([]);
  const [currentDay, setCurrentDate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [mindNBodySub,setMindNBodySub]=useState(false);
  const [activeSub, setActiveSub] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(()=> {
    getAllVideosList();
  },[])

  useEffect(() => {
    checkSubscription();
  }, [currentUser]);

   useEffect(() => {
     filterLiveClassesByDay();
   }, [currentDay]);


  const getAllVideosList = async () => {
    try {
      setIsLoading(true);
      const response = await getLiveVideosFromCurrentDate();
      const data = response.data;
      setVideosList(data);
      setFilteredListLiveClasses(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkSubscription = () => {
    const subscription = currentUser.subscriptionPlanId;
    if (subscription.includes("MIND") || subscription.includes("BODY+MIND") || subscription.includes("MIND+BODY")) {
      setActiveSub(true);
    } else {
      setActiveSub(false);
    }
    if (subscription.includes("BODY+MIND")|| subscription.includes("MIND+BODY") || (subscription.includes("MIND") && subscription.includes("BODY"))) {
      setMindNBodySub(true);
    }
  };

  const filterLiveClassesByDay = () => {
    const listLiveClasses = videosList.filter(
      (liveClass) => moment(liveClass.date).format("YYYY-MM-DD") === currentDay
    );
    setFilteredListLiveClasses(listLiveClasses);
  };

  const handleDateChange = (date) => {
    setCurrentDate(date);
    filterLiveClassesByDay();
  };

  return (
    <>
      <section
        id="series-details"
        className="index-platform flex overflow-x-hidden"
      >
        <VerticalNavBar />
        <div className="h-[100vh] md:h-auto flex flex-col justify-start items-center pb-5 sm:px-8 md:px-12 w-full">
          <div className=" flex flex-col justify-center items-center pb-10">
            <div className="image-container w-12 h-20">
              <img src="../../assets/icons/Logo/Logo-sm.png" alt="Logo-SA" />
            </div>
            <div className="text text-center">
              <p className="font-[Generica]">
                "It's not about being good at something. It's about being good
                to yourself."
              </p>
            </div>
          </div>
          <div className="calendar-section py-5">
            {<CalendarSlider onDateChange={handleDateChange} />}
          </div>
          <div className="p-5">
            <button
              className="button-30 w-fit p-2"
              onClick={() => setFilteredListLiveClasses(videosList)}
            >
              Ver todas las clases
            </button>
          </div>
          <div className="flex flex-wrap gap-5 justify-center items-start pb-10 md:pb-5">
            {isLoading ? (
              <>
                <div className="flex flex-wrap gap-5 justify-center">
                  {[...Array(3)].map((e, i) => (
                    <CardBoxVideo
                      enable={true}
                      title={""}
                      duration={20}
                      level=""
                      intensity={4}
                      path={""}
                      id={""}
                      imgUrl={"Intermedio"}
                      serie={""}
                      loading={true}
                      key={i}
                    />
                  ))}
                </div>
              </>
            ) : (
              filteredListLiveClasses.map((item, index) => {
                console.log(item.allowedSubscriptions);
                console.log(currentUser.subscriptionPlanId);
                const allowUser =
                  item.allowedSubscriptions.includes("FREE") ||
                  currentUser.subscriptionPlanId.some(subscriptionItem =>
                      item.allowedSubscriptions.some(allowedSubscription =>
                          subscriptionItem.trim().toUpperCase() === allowedSubscription.trim().toUpperCase()
                      )
                  );
            
                console.log(allowUser);
                const formattedDate = moment(item.date).locale("es").format("DD-MMM-YYYY");
            
                return (
                    <CardBoxVideoLive
                        key={index}
                        title="LIVE"
                        loading={true}
                        enable={allowUser}
                        date={formattedDate}
                        time={item.startTime}
                        zoomLink={item.zoomUrl}
                    />
                );
            })
            )}
            {filteredListLiveClasses &&
              !isLoading &&
              filteredListLiveClasses.length === 0 && (
                <div className="flex flex-col justify-center items-center gap-5">
                  <div className="image-container flex flex-col justify center w-24">
                    <img src="../assets/icons/Forms/upcoming.gif" alt="" />
                  </div>
                  <h4 className="text-xl text-sb-800 opacity-50 uppercase font-[DIN-Condensed]">
                    No hay clases para este día
                  </h4>
                </div>
              )}
          </div>
          <div className="filter-by-mandala-box flex flex-col gap-2 w-full justify-start px-4 sm:px-0">
             <h5 className="font-[Generica] uppercase">Eventos Especiales</h5>
             <div className="flex flex-wrap gap-5 justify-center md:justify-start">
                <div className={`card-box-3 w-[200px] h-[200px] rounded-xl flex flex-col justify-center items-center gap-5 relative`}>
                  <img src={"../../assets/images/Portadas/Video-P-7.jpg"} className='absolute  w-[200px] h-[200px]  -z-10 rounded-xl' alt="" />
                  <div className={`flex flex-col items-center gap-2 text-justify z-10 p-5 h-full w-full justify-end`}>    

                  </div>
                </div>                   
             </div>
           </div>
        </div>
      </section>
    </>
  );
};

export default LiveClassesPage;
