import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loginAccess: true,
    currentUser: null,
    newUser: null,
    newAccessUser: null,
    activeSubscription: true,
    isFetching: false,
    error: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.currentUser = action.payload;
      state.loginAccess = true;
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logOut: (state) => {
      state.loginAccess = false;
      state.currentUser = null;
    },
    setCurrentUser: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.currentUser = action.payload;
    },
    checkUserSubscription: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.activeSubscription = action.payload;
    },
    addUserStart: (state) => {
      state.isFetching = true;
    },
    addAccessUserStart: (state) => {
      state.isFetching = true;
    },
    addUserSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.newAccessUser = action.payload;
    },
    addAccessUserSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.newUser = action.payload;
    },
    addUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    restartPasswordRequestStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    restartPasswordRequestSuccess: (state) => {
      state.isFetching = false;
    },
    restartPasswordRequestFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    restartError: (state) => {
      state.error = false;
    },
    restartUser: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
  addUserStart,
  addUserSuccess,
  addUserFailure,
  addAccessUserStart,
  addAccessUserSuccess,
  checkUserSubscription,
  loginStart,
  loginSuccess,
  loginFailure,
  logOut,
  setCurrentUser,
  restartUser,
  restartError,
  restartPasswordRequestStart,
  restartPasswordRequestSuccess,
  restartPasswordRequestFailure,
} = userSlice.actions;

export default userSlice.reducer;
