import {
  publicRequest,
  userRequest,
  userRequestByTag,
  userRequestBy2Params,
  userRequestByParams,
} from "../requestMethods/requestMethods";
;


async function getAllVideos() {
  return await userRequest.get(`/videos/getVideos`);
}

async function getAllVideosByPage(page,size) {
  console.log(page);
  console.log(size);
  const params={
    page:page,
    pageSize:size
  }

  return await userRequestByParams(params).get(`/videos/getVideosByPage`);
}

async function getVideoById(videoId) {
  return await publicRequest.get(`/videos/${videoId}`);
}

async function getVideoBySerieId(serieId) {
  return await publicRequest.get(`/videos/by-series/${serieId}`);
}

async function getVideosByDuration(duration) {
  return await publicRequest.get(`/videos/by-duration/${duration}`);
}

async function getVideosByBetweenDuration(min,max) {
  return await userRequestBy2Params.get("/videos/by-duration-range", {
    params: {
      minDuration: min-5,
      maxDuration: max+5,
    },
  });
}

async function getVideosByTag(value){
  return await userRequestByTag.get('/videos/by-tag',{
    params:{
      tag:value
    }
  })
}

async function getVideosByAllowSubscription(subscription){
  return await publicRequest.get(`/videos/allowedSubscription/${subscription}`);
}

async function getVideosByLevel(value) {
  return await userRequestByTag.get("/videos/by-intensity-level", {
    params: {
      intensityLevel: value,
    },
  });
}


async function addVideo(video) {
  return await userRequest.post("/videos/addVideo",video);
}


async function addVideoInteraction(username,interaction) {
   try {
     const res = await publicRequest.post(`/user/${username}/video-interactions`,interaction);
     return res;
   } catch (err) {
     return err;
   }

}

async function getVideosInteractionByUser(username){
  try {
    const res = await publicRequest.get(
      `/user/${username}/video-interactions`
    );
    return res.data;
  } catch (err) {
    return err;
  }

}

async function updateVideoById(videoId,video){
  try {
    const res = await publicRequest.put(
      `/videos/update/${videoId}`,video
    );
    return res.data;
  } catch (err) {
    return err;
  }

}

async function removeVideoById(videoId){
  try {
    const res = await publicRequest.delete(
      `/videos/remove/${videoId}`
    );
    return res.data;
  } catch (err) {
    return err;
  }

}



export {
  addVideo,
  getAllVideos,
  getAllVideosByPage,
  getVideosByAllowSubscription,
  getVideoById,
  getVideoBySerieId,
  getVideosByTag,
  getVideosByDuration,
  getVideosByLevel,
  getVideosByBetweenDuration,
  getVideosInteractionByUser,
  addVideoInteraction,
  updateVideoById,
  removeVideoById,
};