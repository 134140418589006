import React from 'react'
import {Button,Card,CardContent,CardActions,CardMedia} from '@mui/material';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';



interface Info{
    title:string,
    subtitle:string,
    desc:string[],
    price:number,
    stripe:string,
    color:string,
    thumbnail:string,
}

const SubscriptionBox2 = (subscriptionInfo:Info) => {

    const openInNewTab = (url:string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

  return (
    <Card sx={{ maxWidth: 310 }}>
        <CardActionArea >
            <CardMedia
                sx={{height:260}}
                component="img"
                image={subscriptionInfo.thumbnail}
                alt={subscriptionInfo.title}
            />
            <CardContent>
                <h4 className='font-bold text-center font-[MADE-Bon-Voyage] text-sb-600 text-4xl'>
                    {subscriptionInfo.title.toUpperCase()}
                </h4>
                <Typography variant="subtitle1" color="text.primary">
                    <ul className='subscription-item font-[DIN-Condensed] list-disc p-5'>
                    {subscriptionInfo.desc.map((item,index)=>{
                        return <li key={index}>{item}</li>
                            })}
                    </ul>
                </Typography>
                <h5 className='font-[DIN-Condesded] text-sb-700 text-center text-5xl'>${subscriptionInfo.price}</h5>
            </CardContent>
        </CardActionArea>
        <CardActions sx={{display:'flex', justifyContent:'center'}}>
        <Button className="button-30 w-fit p-5 text-white" size="small" variant="contained" onClick={() => openInNewTab(subscriptionInfo.stripe)}>
            Inscribirme
        </Button>
        </CardActions>
        <CardContent>
            <Typography variant="body2" color="text.secondary">
            *El pago es mensual y se reactivará de manera automática cada mes - puedes cancelar cuando lo desees.
            </Typography>
        </CardContent>
    </Card>
  )
}

export default SubscriptionBox2