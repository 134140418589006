export const sessionsOne = [
    {
      type: 'SESIÓN',
      sessionNumber: 1,
      format: 'INDIVIDUAL',
      title: 'OBJETIVOS Y ACCIÓN ALINEADA',
      content: [
        'Atendiendo lo invisible: el inconsciente',
        'Escaneo cuántico con herramienta healy',
        'Objetivos y acción alineada',
      ],
    },
    {
      type: 'SESIÓN',
      sessionNumber: 2,
      format: 'INDIVIDUAL',
      title: 'MIS LÍMITES Y VALORES',
      content: [
        'Límites claros',
        'Definiendo tus valores',
        'Nueva narrativa interna',
      ],
    },
    {
      type: 'SESIÓN',
      sessionNumber: 3,
      format: 'INDIVIDUAL',
      title: 'ANÁLISIS Y ESPACIO',
      content: [
        'Entiende cómo funciona tu mente',
        'Liberación de puntos de vista limitantes',
        'Analizando tus creencias',
      ],
    },
];

export const sessionsGroup = [
    {
      type: 'LLAMADA',
      sessionNumber: 1,
      format: 'GRUPAL',
      title: 'CLARIDAD Y ESTRATEGIA',
      content: [
        'Retomando tu poder',
        'Escaneo interno de satisfacción',
        'Ley de la intención',
        'Objetivos 2024 + moodboard',
      ],
    },
    {
      type: 'LLAMADA',
      sessionNumber: 2,
      format: 'GRUPAL',
      title: 'MI RELACIÓN INTERNA Y HÁBITOS',
      content: [
        'Mis 6 relaciones: mente, cuerpo, relaciones personales, dinero, tiempo y espiritualidad',
        'El círculo de la vida',
        'Hábitos que construyen vs destruyen',
      ],
    },
    {
      type: 'LLAMADA',
      sessionNumber: 3,
      format: 'GRUPAL',
      title: 'VIVIR EN MODO EMPODERADA',
      content: [
        'Base sólida y sostenible',
        'Una vida rentable y expansiva',
        'Tu valor infinito',
      ],
    }
  ];

  