import React,{useState,useEffect} from 'react'
import CardBox3 from '../CardsBox/CardBox3';
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./SliderCardsChallenge.css"
import { getAllSeries } from '../../services/series';
import CircularProgress from '@mui/material/CircularProgress';


const SliderCardsChallenge = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const subscription = currentUser.subscriptionPlanId;
  const [seriesList,setSeriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSeriesList = async () =>{
      try{    
          setIsLoading(true);
          const response = await getAllSeries();
          const data = response.data;
          setSeriesList(data)
      }catch(error){
          console.log(error);
      }finally{
          setIsLoading(false);
      }
  }

  useEffect(() =>{
      getSeriesList();
  },[]);

  return (
    <>
    {isLoading ? <CircularProgress />:(
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        pagination
        initialSlide={0}
        breakpoints={{
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
            centeredSlides: false,
          },
        }}
        className="mySwiper w-full max-w-[90vw] h-[280px] flex justify-center items-center md:justify-start"
        onSwiper={(swiper) => console.log(swiper)}
      >
        {seriesList && seriesList.map((item, index) => (
          <SwiperSlide key={index} className="flex justify-center items-center">
          <CardBox3
            title=""
            desc=""
            imageUrl={item.thumbnail}
            url={`/seriesDetails/searchBy?series=${item.seriesId}`}
            enable={currentUser.email==='sara.asanas@gmail.com' || subscription.includes(item.seriesId.toUpperCase()) || subscription.includes(`${item.seriesId.toUpperCase()}-TRAIL`)}
            active={item.active}
            payementLink={item.payementLink}
          />
        </SwiperSlide>
        ))}
        
        <SwiperSlide className="flex justify-center items-center">
          <CardBox3
            title="VINYASA FLOW"
            desc="Sincronización de asanas y transiciones con la respiración"
            imageUrl="../../assets/images/sliders/sara-2.webp"
            url="/seriesDetails/searchBy?series=Abundancia"
            enable={false}
            active={false}
          />
        </SwiperSlide>
        <SwiperSlide className="flex justify-center items-center">
          <CardBox3
            title="VINYASA FLOW"
            desc="Sincronización de asanas y transiciones con la respiración"
            imageUrl="../../assets/images/Cards/Video-P-8.jpg"
            url="/seriesDetails/searchBy?series=Abundancia"
            enable={false}
            active={false}
          />
        </SwiperSlide>
      </Swiper>)}
    </>
  );
}

export default SliderCardsChallenge;