import React, { useEffect,useState } from 'react';
import {Alert,Autocomplete,Box,Button,FormControl,InputLabel,TextField,Typography, Chip, Avatar, Grid, Paper, Select, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { UserData,AccessUserData} from './UserData';
import { Subscription } from '../Video/VideoData';
import { SubscriptionData } from './UserData';
import { getAllActiveSubscriptions } from '../../../../services/series';
import { modalStyle } from '../GeneralData';
import { updateUserById } from '../../../../services/user';
import { addUser,addAccessUser,addSubscription } from '../../../../services/user';
import { useDispatch } from 'react-redux';
import { convertUnixTimeToLocalDate } from '../../../../utils/timeConvertor';

interface Props {
    refreshData: () => void;
    handleClose:() => void;
}

const AddUserForm:React.FC<Props> = ({refreshData,handleClose}) => {

  const [confirmPassword, setConfirmPassword] = useState('');
  const [matchPassword,setMatchPassword] = useState(true);
  const [accessUser, setAccessUser] = useState<AccessUserData>({
    fullName: '',
    username: '',
    password: '',
    email: '',
  });
  const [editedUser, setEditedUser] = useState<UserData>( {
    userId: null,
    username: '',
    email: '',
    fullName: '',
    profilePicture: '',
    subscriptions: [],
    subscriptionPlanId: [],
    subscribedSeries: [],
    likedVideos: [],
    watchHistory: [],
    savedVideos: [],
    videoInteractions: [],
    paymentProviders: null,
  }
  );
  const [subscriptionList, setSubscriptionList] = useState<Subscription[]>([]);
  const [filterSubscriptionList, setFilterSubscriptionList] = useState<Subscription[]>([]);
  const [message,setMessage]= useState<String|null>('');
  const [errorMessage,setErrorMessage] = useState('');;
  const [isLoadingModal, setIsLoadingModal] = useState(true);
  const [success,setSuccess]=useState(false);
  const [userCreated,setUserCreated] = useState(false);
  const [error,setError] = useState<String|null>();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();




  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof UserData) => {
    setEditedUser((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleChangeAccessUser = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof AccessUserData) => {
    setAccessUser((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  useEffect(() => {
    getSubscription();
    filterSubscrition();
  }, []);

  const filterSubscrition = async () => {
    try {
      getSubscription()
      .then(subscriptions => {
        const filteredSubscriptions = subscriptions.filter(subscription =>
          editedUser.subscriptionPlanId.includes(subscription.planId)
        );
        setFilterSubscriptionList(filteredSubscriptions);
      })
      .catch(error => {
        console.error('Error fetching and filtering subscriptions:', error);
        // Handle error appropriately, e.g., display an error message to the user
      });
    } catch (error) {
      console.error('Error filtering subscriptions:', error);
      return []; // Return an empty array in case of error
    }
  };

  const handleSaveClick = () => {
    handleCreateUser();
  };

  const handleCreateUser= async() =>{
        try {
            await createAccessUser(accessUser);
            setMessage('Usario de Acceso Creado');            
        } catch (error:any) {
            setError(error);
        }
   }

    const handleSubscriptionsChange = (e: any, values: { planId: string }[]) => {
        const selectedPlanIds = values.map((value) => value.planId);
        setEditedUser((prevData:any) => ({
        ...prevData,
        subscriptionPlanId: selectedPlanIds,
        }));
    };


  const checkMatchPassword= () =>{
    if(accessUser.password === confirmPassword){
        setMatchPassword(true);
        console.log("PasswordMatch" + matchPassword);
        return true;
    }else{
        setMatchPassword(false);
        console.log("PasswordMatch" + matchPassword);
        setAccessUser({...accessUser, password:''})
        setConfirmPassword('');
        alert("Las contraseñas no coinciden");
        return false;
    }

  };

  const createAccessUser= async (userModel:AccessUserData) =>{
    setErrorMessage('');
     try {
       if(checkMatchPassword()){
         const response:any = await addAccessUser(dispatch,userModel);
        if(response.status === 201){
            const user = await createUser();
            console.log(user);
            await Promise.all(user.subscriptionPlanId.map(async (item: any) => {
                console.log(user.userId);
                await createSubscription(item, user.userId);
            }));
            setSuccess(true);      
        }else{
            setErrorMessage(response.response.data);
        }
       }  
     } catch (error:any) {
         console.error("An error occurred:", error.response.data);
        
     }
   }

  const createUser = async () => {
    try {
        const response = await addUser(dispatch,editedUser);
        console.log(response);
        setUserCreated(true);
        return response;
    } catch (error) {
        console.error("An error occurred:", error);
    }
  }

  const createSubscription = async(plandId:string,userid:string) =>{
    try {
        await addSubscription(userid,plandId);
    } catch (error) {
        console.error(error)
    }
  }

    const getSubscription = async (): Promise<Subscription[]> => {
        try {
            setIsLoadingModal(true);
            const response:any = await getAllActiveSubscriptions(); // Assuming getAllActiveSubscriptions returns a Promise<ApiResponse<Subscription[]>>
            const data: Subscription[] = response.data; // Assuming the response contains the data property of type Subscription[]
            setSubscriptionList(data);
            return data;
        } catch (error:any) {
            console.error('Error fetching active subscriptions:', error);
            setError(error); // Consider providing more specific error handling based on different error scenarios
            throw error; // Re-throw the error to propagate it further if necessary
        } finally {
            setIsLoadingModal(false);
        }
    };


    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    }

  return (
    <Box sx={modalStyle}>
    {!success ? (
        <Paper sx={{ padding: 2, maxHeight: 500, overflowY: 'scroll' }}>
            <>
                <b>Username:</b>
                <Typography variant="body2" gutterBottom>
                <TextField
                    fullWidth
                    value={editedUser.username}
                    onChange={(e) =>{ 
                        handleChange(e, 'username');
                        handleChangeAccessUser(e, 'username');
                    }}
                />
                </Typography>
                <Typography variant="body2" gutterBottom>
                <b>Email:</b>
                <TextField
                    fullWidth
                    value={editedUser.email}
                    onChange={(e) => {
                        handleChange(e, 'email');
                        handleChangeAccessUser(e, 'email');
                    }}
                />
                </Typography>
                <Typography variant="body2" gutterBottom>
                <b>Nombre:</b>
                <TextField
                    fullWidth
                    value={editedUser.fullName}
                    onChange={(e) => {
                        handleChange(e, 'fullName');
                        handleChangeAccessUser(e, 'fullName');
                        
                    
                    }}
                />
                </Typography>
                <FormControl className="p-5" style={{ width: '100%' }}>
                <div className="flex flex-wrap gap-3 justify-between py-2">
                    <FormControl sx={{width: 240 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={accessUser.password}
                        onChange={(e) => setAccessUser({...accessUser, password:e.target.value})}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                    />
                    </FormControl>
                    <FormControl sx={{width: 240}} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirmar Contraseña</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="ConfirmPassword"
                    />
                    </FormControl>
                </div>
                <Autocomplete
                    style={{ width: '100%' }}
                    multiple
                    id="tags-standard"
                    options={subscriptionList}
                    defaultValue={filterSubscriptionList}
                    getOptionLabel={(option) => option.planId}
                    onChange={handleSubscriptionsChange}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Subscripciones"
                        placeholder="Subscripciones"
                    />
                    )}
                />
                </FormControl>
    
            </>
                
            {/* Add more sections for other user properties */}
            
            <div className='flex flex-wrap justify-around py-5 px-5'>
                <Button onClick={handleSaveClick} variant="contained" color="primary">
                    Crear Usuario
                </Button>
                <Button onClick={closeSuccessFully} variant="contained" color="error">
                    Cancelar
                </Button>
            </div>
            
        </Paper>
    ) : (
        <div className="flex flex-col gap-5 justify-center items-center">
            <Alert severity="success">{message}</Alert>
            <Button variant="contained" color="success" onClick={closeSuccessFully}>Continue</Button>
        </div>
    )}
</Box>
  )
}

export default AddUserForm