import React,{useEffect,useState} from 'react'
import {Box,Modal,CircularProgress,Switch} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, esES } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';
import { getAllActiveSubscriptions,updateActiveSubscription } from '../../../services/series';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchPromotionById } from '../../../services/promotion';
import PromotionForm from '../Stepper/Series/PromotionForm';
import RemoveActiveSubscriptionForm from '../Stepper/Subscriptions/RemoveActiveSubscriptionForm';
import AddActiveSubscriptionForm from '../Stepper/Subscriptions/AddActiveSubscriptionForm';


const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES, // x-data-grid translations
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)'
  };

const SubscriptionTable = () => {

    const [seriesList, setSeriesList] = useState([]);
    const [currentSerie,setCurrentSerie] =useState();
    const [currentPromotion,setCurrentPromotion] = useState();
    const [activeOption,setActiveOption] =useState();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error,setError] = useState();

    useEffect(()=>{
      getAllSeriesList();
    },[])


  const columns = [
    { field: 'planId', headerName: 'Series ID', width: 320 },
    { 
      field: 'isActive', 
      headerName: 'Status', 
      width: 150, 
      renderCell: (params) => {
        return <Switch defaultChecked={params.row.active} color="success" onChange={()=>handleStatus(params.row)}/>
    },
    },
    {field: 'actions', headerName: 'Acciones', width: 300, renderCell: (params) => (
      <div className="flex gap-2">
        <IconButton aria-label="remove" size="small" onClick={() =>removeSerie(params.row)}>
          <Delete />
        </IconButton>
      </div>
    ),}
  ];

  const handleClose = () => {
    setOpen(false)  
  };
  
    const getSerieInfo = async(serie) =>{
      setOpen(true);
      setCurrentSerie(serie);
      const res = await fetchPromotionById(serie.seriesId)
      setCurrentPromotion(res);
    }
    const getAllSeriesList =  async () => {
        try{     
            setIsLoading(true);
            const response = await getAllActiveSubscriptions();
            const data = response.data;
            console.log(data);
            setSeriesList(data)
        }catch(error){
            console.log(error);
            setError(error)
        }finally{
            setIsLoading(false);
        }
      };

    const handleOpen = async (option) => {
      try {
        setOpen(true);
        setActiveOption(option);
      } catch (error) {
        console.error('Error while opening:', error);
      }
    };

    const removeSerie=(serie) =>{
      setCurrentSerie(serie);
      handleOpen('remove');
    }

    const updateSerie=async(serie) =>{
      console.log(serie);
      getSerieInfo(serie);
      setCurrentSerie(serie);
      console.log(currentPromotion);
      await handleOpen('update');
    }

    const handleStatus=async(params) =>{
      const res = await updateActiveSubscription(params.id,params.active);
      alert(res.data)
    }
  
    const refreshData=()=>{
      handleClose();
      getAllSeriesList();
    }
  
  

      
    const handleSeriesOption =(action) =>{
      switch (action) {
        case 'add':
          return <AddActiveSubscriptionForm handleClose={handleClose} refreshData={refreshData}/>;
        case 'remove':
        return <RemoveActiveSubscriptionForm activeSubscription={currentSerie} handleClose={handleClose} refreshData={refreshData}/>;
        default:
          return <AddActiveSubscriptionForm handleClose={handleClose} refreshData={refreshData}/>;
      }

    }

  return (
    <div style={{ height: 'auto', width: '100%' }}>
         {isLoading ? (
      <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ): error ? (
        <div>Error: {error.message}</div>
      ) : (

        <ThemeProvider theme={theme}>
            <DataGrid
            rows={seriesList}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row.planId}
            autoHeight
          />
          </ThemeProvider>

        )}
    {error && (<p>{error} error</p>)}
    <div className="flex justify-center p-5">
      <button className='button-29 w-auto'  onClick={() => handleOpen('add')}>Agregar Serie</button>
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >      
            {handleSeriesOption(activeOption)}
          </Modal>
    </div>
  </div>
  )
}

export default SubscriptionTable