import React from 'react'
import './GridGallery.css'

const GridGallery = () => {
  return <>
    <div className="grid-box grid grid-cols-12 grid-rows-[300px]">
        <div className="box col-span-8">
            <img className='box-image' src="../../assets/images/sliders/Sara-P-10.jpg" alt=""/>
        </div>
        <div className="box col-span-4">
            <img className='box-image' src="../../assets/images/Portadas/Video-P-2.jpg" alt=""/>
        </div>
        <div className="box col-span-4">
            <img className='box-image' src="../../assets/images/sliders/Sara-P-11.jpg" alt=""/>
        </div>
        <div className="box col-span-4">
            <img className='box-image' src="../../assets/images/sliders/Sara-P-3.jpg" alt=""/>
        </div>
        <div className="box col-span-4">
            <img className='box-image' src="../../assets/images/sliders/Sara-P-12.jpg" alt=""/>
        </div>
    </div>
  </>
}

export default GridGallery

