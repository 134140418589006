import React, {useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import './FrontPage.css';
import SocialMediaBox from '../../components/SocialMediaBox/SocialMediaBox';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer/Footer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SliderCardsPrograms from '../../components/Sliders/SliderCardsPrograms';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ModalSource{
    title:string;
    desc:string;
}

const ModalData:ModalSource[] = [
  {
    title:"Salud Física y Mental",
    desc:" Una mente saludable hace que nuestro cuerpo funcione mejor y a su vez un cuerpo sano genera bienestar emocional evitando enfermedades."
  },
  {
    title:"Armonia y Equilibrio",
    desc:"Fomentar la armonía y equilibrio son claves para conseguir un estado de calma, estabilidad y bienestar."
  },
  {
    title:"Paz y Calma",
    desc:"La paz y la calma son semillas que podemos cultivar en nuestro día a día con prácticas conscientes como la meditación y yoga."
  },
  {
    title:"Energía y Vitalidad",
    desc:"Nuestra energía es el recurso más valioso que tenemos, es nuestra capacidad de crear y que al mismo tiempo nos llena de vitalidad."
  },
  {
    title:"Conexión y Unión",
    desc:"Reconocer tu bienestar de manera integral potencializa tu salud y hacerlo en comunidad (tribu) facilita el proceso al sentirnos acompañados y sostenidos."
  }
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const FrontPage = () => {

  const [open, setOpen] = useState(false);
  const [openReto, setOpenReto] = useState(true);
  const[modalTitle,setModalTitle] = useState("");
  const[modalDescription,setModalDescription]=useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenReto = () => setOpen(true);
  const handleCloseReto = () => setOpenReto(false);
  const navigate = useNavigate();
  const navigateToSubscription = () => {
    // 👇️ navigate to /contacts
    navigate('/subscripciones');
  };
  const [isMobile, setIsMobile] = useState(false)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const handleModalOpen=((modalId:number) =>{
    if(!isMobile){
      return
    }

    setModalTitle(ModalData[modalId].title);
    setModalDescription(ModalData[modalId].desc);
    handleOpen();

  })

  useEffect(() =>{
    AOS.init();
  })

   const openInNewTab = (url:string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

  

  return (
    <>  
      <NavBar/>
        <section className="hero-section">
          <div className="slider h-full w-full bg-sb-600">
            <img  loading='lazy' className="hero-image" src={require('../../assets/images/sliders/Sara-P-13.jpg')} alt="Slide-1" data-aos="zoom-out" data-aos-delay="0" data-aos-duration="1500" data-aos-easing="ease-in"/>
            <div className="hero-title gap-5 flex flex-wrap items-center justify-center w-10/12 h-10/12" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1500" data-aos-delay="1500">
              <div className="title-1 flex flex-wrap items-center flex-grow">
                <h1 className='text-so-500'>Cuerpo,</h1>
                <h1 className='text-sb-700'>Mente</h1>
                <h1 className='text-sc-c2'>&</h1>
                <h1 className="text-sc-c2">Alma</h1>
              </div>
              <h4 className='sub-title'>Bienestar Integral</h4>
            </div>
            <div className="button-box">
              <button className="button-30 w-auto" onClick={navigateToSubscription}>Suscribete Aqui</button>
            </div>
          </div>
        </section>
        <section className="about-section">
          <div className="title-content flex justify-center items-center text-center p-10">
            <h2 className="font-[MADE-Bon-Voyage] text-sb-900">Pilares del Bienestar</h2>
          </div>
          <div className="content-box">
            <div className="content">
              <h5 className="title text-sb-900">Paz y Calma</h5>
              <p className='text-sb-900 '>La paz y la calma son semillas que podemos cultivar en nuestro día a día con prácticas conscientes como la meditación y yoga.
              </p>
              <div className="icon-container  w-full flex justify-center md:justify-center" onClick={() =>handleModalOpen(2)} >
                <img  loading='lazy' src="../../assets/icons/Forms/plus.svg" alt=""  className='pulse'/>
              </div>
            </div>
            <div className="content">
              <h5 className="title text-sb-900">Armonia y Equilibrio</h5>
              <p className='text-sb-900 '>Fomentar la armonía y equilibrio son claves para conseguir un estado 
de calma, estabilidad y bienestar.
              </p>
              <div className="icon-container  w-full flex justify-end" onClick={() =>handleModalOpen(1)}>
                <img  loading='lazy'  src="../../assets/icons/Forms/plus.svg" alt=""  className='pulse'/>
              </div>
            </div>
            <div className="content">
              <h5 className="title">Salud Física y Mental</h5>
              <p className=''>Una mente saludable hace que nuestro cuerpo funcione mejor y a su vez un cuerpo sano genera bienestar emocional evitando enfermedades.

              </p>
               <div className="icon-container  w-full flex justify-end" onClick={() =>handleModalOpen(0)} >
                <img  loading='lazy' src="../../assets/icons/Forms/plus.svg" alt=""  className='pulse'/>
              </div>
            </div>
            <div className="image-box" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000" data-aos-delay="500" data-aos-once="true">
              <img  loading='lazy' alt="sara-p1" src={require('../../assets/images/FrontPage/Sara-01.png')}  />
            </div>
            <div className="content flex justify-start items-start">
              <h5 className="title text-sb-900">Energía y Vitalidad</h5>
              <div className="icon-container  w-full flex justify-start" onClick={() =>handleModalOpen(3)}>
              <img  loading='lazy' src="../../assets/icons/Forms/plus.svg" alt=""  className='pulse'/>
              </div>
              <p className='text-sb-900 '>Nuestra energía es el recurso más valioso que tenemos, es nuestra capacidad de crear y que al mismo tiempo nos llena de vitalidad.
              </p>
            </div>
            <div className="content">
              <h5 className="title">Conexión y Unión</h5>
              <div className="icon-container  w-full flex justify-start" onClick={() =>handleModalOpen(4)}>
                <img  loading='lazy' src="../../assets/icons/Forms/plus.svg" alt=""  className='pulse'/>
              </div>
              <p className=''>Reconocer tu bienestar de manera integral potencializa tu salud y hacerlo en comunidad (tribu) facilita el proceso al sentirnos acompañados y sostenidos.
              </p>

            </div>
          </div>
        </section>
        <section className='training-section flex flex-wrap justify-center items-center pb-10'>
          <div className="right-content w-full">
            <SliderCardsPrograms/>
          </div>
        </section>
        <SocialMediaBox/>
        <Footer/>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalDescription}
          </Typography>
        </Box>
      </Modal>
     {/* <Modal
        open={openReto}
        onClose={handleCloseReto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col justify-center items-center gap-5">
          <div className="image-container">
            <img src="../assets/images/FrontPage/reto01.jpg" alt="" />
          </div>
          <button className="button-30 font-bold w-fit px-2" onClick={() => openInNewTab("https://buy.stripe.com/8wMcNr8vZ1LxeYw3cg")}>Registrarme</button>
          </div>
        </Box>
      </Modal>*/}
    </>
  )
}

export default FrontPage;