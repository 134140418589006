interface MenuItem{
    name:string;
    path:string;
}

export const Menu: MenuItem[] =[
    {
        name:'Inicio',
        path:'/'
    },
    {
        name:'Un Poco de MI',
        path:'/Un-Poco-de-Mi'
    },
    {
        name:'Programas',
        path:'/subscripciones'
    },
    {
        name:'Contacto',
        path:'/contacto'
    },
    {
        name:'Mi Usuario',
        path:'/login'
    },
    
    ];