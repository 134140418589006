import React from 'react'
import './ButtonS2.css'


const ButtonS2 = (props) => {
  return (
    <>
      <div
        className={`circle-image-container rounded-full bg-sb-100 w-14 h-14 p-2 ${
          props.isLoading ? "is-loading" : ""
        }`}
      >
        <img
          src={
            props.iconPath
              ? props.iconPath
              : "../../assets/icons/Position-Orange/yoga-pose-2.png"
          }
          alt="Yoga pose"
        />
      </div>
    </>
  );
};


export default ButtonS2