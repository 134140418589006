import {
  userRequest,
  publicRequest,
} from "../requestMethods/requestMethods";


async function addLiveClass(liveClass) {
  return await userRequest.post(`/live-classes`,liveClass);
}

async function updateLiveClass(liveClassId,liveClass) {
  return await userRequest.put(`/live-classes/update/${liveClassId}`,liveClass);
}

async function getAllLiveVideos() {
  return await userRequest.get(`/live-classes`);
}

async function getLiveVideosFromCurrentDate() {
  return await userRequest.get(`/live-classes/starting-from-current-day`);
}

async function removeLiveClassById(liveClassId) {
  try{
    return await publicRequest.delete(`/live-classes/remove/${liveClassId}`);
  } catch (err) {
    return err;
  }
}

export { addLiveClass,getAllLiveVideos, getLiveVideosFromCurrentDate,updateLiveClass,removeLiveClassById };