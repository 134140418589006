import React,{useEffect,useState,useRef} from 'react'
import {Chip,Modal,Switch} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid,esES } from '@mui/x-data-grid';
import { addVideo,getAllVideos } from '../../../services/videos';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { IconButton } from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';
import VideoUploadStepper from '../Stepper/Video/StepperVideoUpload';
import RemoveForm from '../Stepper/Video/RemoveForm';
import UpdateVideoForm from '../Stepper/Video/UpdateVideoForm';
import { getMandalaByName,getAllMandalas,updateStatusMandala } from '../../../services/mandalas';
import UpdateMandalaForm from '../Stepper/Mandalas/UpdateMandalaForm';
import AddMandalaForm from '../Stepper/Mandalas/AddMandalaForm';
import RemoveMandalaForm from '../Stepper/Mandalas/RemoveMandalaForm';


const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES, // x-data-grid translations
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)'
  };

  const useStyles = {
    uploadBox: {
      background: "rgba(255, 255, 255, 0.25)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      borderRadius: "10px",
      border: "1px solid rgba(255, 255, 255, 0.18)",
      transition: "transform 0.3s ease-in-out", // Add a transition for a smooth effect
      '&:hover': {
        transform: 'scale(1.05)', // Example: Scale the box on hover
        // Add additional styles as needed
      },
    },
  };



  const filter = createFilterOptions();
  


const MandalasTable = () => {

  const [mandalasList, setMandalasList] = useState([]);
  const [currentMandala,setCurrentMandala] = useState();
  const [activeOption,setActiveOption] =useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error,setError] = useState();
  const [errorMessage,setErrorMessage] = useState();
  const [open, setOpen] = useState(false);


    useEffect(()=>{
      getAllMandalasList();
    },[])

    const columns = [,
      { field: 'name', headerName: 'Nombre', width: 450 },
      { field: 'serieId', headerName: 'Serie Id', width: 300 }, 
      { field: 'month', headerName: 'Mes', width: 300 },
      { 
        field: 'active', 
        headerName: 'Status', 
        width: 150, 
        renderCell: (params) => {
          return <Switch defaultChecked={params.row.active} color="success" onChange={()=>handleStatus(params.row)}/>
        },
      }, 
      { field: 'actions', headerName: 'Acciones', width: 300, renderCell: (params) => (
        <div className="flex gap-2">
          <IconButton aria-label="info" size="small" onClick={() =>updateVideo(params.row)}>
            <Info />
          </IconButton>
          <IconButton aria-label="remove" size="small" onClick={() =>removeVideo(params.row)}>
            <Delete />
          </IconButton>
        </div>
      ), }
    ];
  

    const handleOpen = async (option) => {
      try {
        setOpen(true);
        setActiveOption(option);
      } catch (error) {
        console.error('Error while opening:', error);
      }
    };

    const handleClose = () => {
      setOpen(false)
    };

    const handleStatus=async(params) =>{
      const res = await updateStatusMandala(params.id,!params.active);
      alert(res.data)
    }

    const getMandalaInfo = async(mandala) =>{
      setCurrentMandala(mandala);
      const res = await getMandalaByName(mandala.name)
      return res;
    }

    const updateMandala =async(mandala) =>{
      setErrorMessage('');
      getMandalaInfo(mandala)
      .then(mandala => {
        if (mandala) {
          handleOpen('update');
        } else {
          setErrorMessage('Error: Mandala info not found');
        }
      })
      .catch(error => {
        setErrorMessage('Error: Mandala info not found');
      });
      setCurrentMandala(mandala);
      
    }
  
    const getAllMandalasList =  async () => {
        try{     
            setIsLoading(true);
            const response = await getAllMandalas();
            const data = response.data;
            setMandalasList(data)
        }catch(error){
            console.log(error);
            setError(error)
        }finally{
            setIsLoading(false);
        }
      };

    const getVideoInfo=(video) =>{
      try {
       setCurrentMandala(video);
       
      } catch (error) {
        console.log(error) 
      }
    }

    const removeVideo=(video) =>{
      setCurrentMandala(video);
      handleOpen('remove');
    }

    const updateVideo=(video) =>{
      setCurrentMandala(video);
      handleOpen('update');
    }

    const refreshData=()=>{
      handleClose();
      getAllMandalasList();
    }
    
    const handleVideoOption =(action) =>{
      switch (action) {
        case 'add':
          return <AddMandalaForm refreshData={refreshData}/>;
        case 'update':
          return <UpdateMandalaForm mandala={currentMandala} handleClose={handleClose} refreshData={refreshData}/>;
        case 'remove':
          return <RemoveMandalaForm mandala={currentMandala} handleClose={handleClose} refreshData={refreshData}/>;
        default:
          return <AddMandalaForm/>;
      }
    }
    
  return (
    <div style={{ height: 'auto', width: '100%' }}>

        
    {isLoading ? (
      <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ): error ? (
        <div>Error: {error.message}</div>
      ) : (
        <ThemeProvider theme={theme}>
        <DataGrid
          rows={mandalasList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          getRowId={(row) => row.name}
          autoHeight
        />
        </ThemeProvider>
      )}

    <div className="flex justify-center p-5">
      <button className='button-29 w-auto'  onClick={() => handleOpen('add')}>Agregar Mandala</button>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >        
       {handleVideoOption(activeOption)}
    </Modal>
    </div>

     </div>
  )
}

export default MandalasTable