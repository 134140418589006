import React,{useState} from 'react'
import { Alert,Box,Button,Typography, Chip, Avatar, Grid, Paper } from '@mui/material';
import { modalStyle } from '../GeneralData';
import { UserData } from './UserData';
import { removeAccessUserByEmail, removeUserById } from '../../../../services/user';
import { Email } from '@mui/icons-material';



interface Props {
    refreshData: () => void;
    handleClose:() => void;
    user:UserData;
  }


const RemoveUserForm:React.FC<Props> = ({user,refreshData,handleClose}) => {
    const [message,setMessage]= useState();
    const [success,setSuccess]=useState(false);

    const handleRemoveUser= async (userId:string) =>{
        try {
            const res = await removeUserById(userId);
            await handleRemoveAccessUser(user.email);
            setMessage(res);
            setSuccess(true);
        } catch (error:any) {
            setMessage(error)
        }
    }

    const handleRemoveAccessUser = async (email:string) => {
        try {
            const res = await removeAccessUserByEmail(email);
        } catch (error:any) {
            setMessage(error)
        }
    }

    const closeModal=()=>{
        handleClose();
    }

    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    }


  return (
    <Box sx={modalStyle}>
        {!success ? <div>
            <div className="title text-center p-5">
            <h5 className='text-2xl'>¿Desesas Eliminar {user.fullName}?</h5>
        </div>
        <div className="user-info">
            <Paper sx={{ padding: 2}}>
            <Typography variant="h4" gutterBottom textAlign="center">
                {user.username}
            </Typography>
            <Grid container justifyContent="center" padding={3}>
                <Grid item>
                <Avatar alt="Thumbnail" src={user.profilePicture} sx={{ width: 150, height: 150 }}/>
                </Grid>
            </Grid>
            <Typography variant="body1" gutterBottom>
                <b>Nombre: </b>
                {user.fullName}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <b>Correo Electronico: </b>
                {user.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
                <b>Subscripciones: </b>
                {user.subscriptionPlanId.map((subscription, index) => (
                <Chip key={index} label={subscription} style={{ marginRight: '4px',background:"#FFCD59" }} />
                ))}
            </Typography>
            </Paper>
        </div>
        <div className="actionContainer flex flex-wrap gap-5 justify-center items-center p-5">
            <Button variant='contained' color='success' onClick={()=>{
                if(user.userId){
                    handleRemoveUser(user.userId)}
                }
                }>Continuar</Button>
            <Button variant='contained' color='error' onClick={closeModal}>Cancelar</Button>
        </div>

        </div>:<div className="flex flex-col gap-5 justify-center items-center">
        <Alert severity="success">{message}</Alert>
          <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
        </div>}

    </Box>
  )
}

export default RemoveUserForm