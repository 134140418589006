import React from "react";
import VerticalNavBar from "../../../components/Navbar/PlatformNavBar/VerticalNavBar";
import SliderCardsChallenge from "../../../components/Sliders/SliderCardsChallenge";
import ChatComponent from "../../../components/ChatComponent/ChatComponent";

const ChallengePage = () => {

  

  return (
    <section className="index-platform relative flex gap-3 overflow-x-hidden">
      <VerticalNavBar />
      <div className="h-[100vh] md:h-auto w-full md:w-[85%] flex flex-col">
        <div className=" flex flex-col justify-center items-center pb-10">
          <div className="image-container w-12 h-20">
            <img src="../../assets/icons/Logo/Logo-sm.png" alt="Logo-SA" />
          </div>
          <div className="text text-center">
            <p className="font-[Generica]">
              "It's not about being good at something. It's about being good to
              yourself."
            </p>
          </div>
        </div>
        <div className="challenge-page-slider flex flex-col gap-2 w-full justify-start px-10 sm:px-0 overflow-hidden">
          <h5 className="font-[Generica] uppercase py-5">Populares</h5>
          <SliderCardsChallenge />
        </div>
      </div>
    </section>
  );
};

export default ChallengePage;
