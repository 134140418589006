import React, { useState, useEffect } from "react";
import VerticalNavBar from "../../../components/Navbar/PlatformNavBar/VerticalNavBar";
import CardBoxVideo from "../../../components/CardsBox/CardBoxVideo";
import { getAllVideos, getVideoBySerieId } from "../../../services/videos";
import { useDispatch, useSelector } from "react-redux";
import "./VideoLibraryPro.css";
import { Link } from "react-router-dom";
import YogaTypesButtons from "../../../components/FilterPageSections/YogaTypesButtons";
import { getAllSeries } from "../../../services/series";
import { getAllMandalas } from "../../../services/mandalas";
import { checkUserMembership,checkAllowUser } from "../../../utils/checkSubscription";



const VideoLibraryPro = () => {
   const [videosList, setVideosList] = useState([]);
   const [seriesList,setSeriesList] = useState([]);
   const [uniqueDurationsArray, setUniqueDurationsArray] = useState([]);
   const [uniqueTagArray, setUniqueTagsArray] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [isLoadingCheck, setIsLoadingCheck] = useState(true);
   const [fetchComplete,setFetchComplete] = useState(false);
   const [allowUserList, setAllowUserList] = useState([]);
   const [subcriptionUserList, setSubscriptionUserList] = useState([]);
   const currentUser = useSelector((state) => state.user.currentUser);
   const [mandalasList,setMandalasList]=useState([]);

   const DurationItems = () =>{
         const result = [];
         for (var i = 0; i < uniqueDurationsArray.length - 1; i++) {
           if (i < uniqueDurationsArray.length-1) {
             result.push(
               <Link
                 to={`/library/searchBy?range=${
                   uniqueDurationsArray[i]
                 }&max=${uniqueDurationsArray[i + 1]}`}
               >
                 <button
                   key={i}
                   className="button-31 bg-so-600 text-sc-c2 rounded-full w-fit px-4 sm:px-6 h-fit"
                 >
                   {uniqueDurationsArray[i]}-{uniqueDurationsArray[i + 1]} min
                 </button>
               </Link>
             );
             i++;
           } else {
             result.push(
               <Link
                 to={`/library/searchBy?range=${uniqueDurationsArray[i]}&max=99`}
               >
                 <button
                   key={i}
                   className="button-31 bg-so-600 text-sc-c2 rounded-full w-fit px-4 sm:px-6 h-fit"
                 >
                   {uniqueDurationsArray[i]}++ min
                 </button>
               </Link>
             );
           }
         }
         return result;

   }

   const getVideosList = async () => {
     try {
       setIsLoading(true);
       const response = await getAllVideos();
       const data = response.data;
       setVideosList(data);
       return data;
     } catch (error) {
       console.log(error);
     } finally {
       setIsLoading(false);
     }
   };

   const getSeriesList = async () =>{
    try{    
        setIsLoading(true);
        const response = await getAllSeries();
        const data = response.data;
        console.log(data);
        setSeriesList(data)
    }catch(error){
        console.log(error);
    }finally{
        setIsLoading(false);
    }
}


   const getVideosListBySerie = async (seriesId) => {
     try {
       setIsLoading(true);
       const response = await getVideoBySerieId(seriesId);
       const data = response.data;
       setVideosList(data);
       console.log(data);
     } catch (error) {
       console.log(error);
     } finally {
       setIsLoading(false);
     }
   };

   const handleMandalasList = async()=>{
    const res = await getAllMandalas();
    setMandalasList(res.data);
  
  }

   const filterVideosByTime = (timeThreshold) => {
     const filteredVideos = videosList.filter(
       (video) => video.duration > timeThreshold
     );
     setVideosList(filteredVideos);
   };

   const createVideoDurationArray=()=>{
     // Extract durations into a new array
     const durationsArray = videosList.map((video) => video.duration);
     // Use a Set to remove duplicates and convert back to an array
     const uniqueArray = [...new Set(durationsArray)];
     // Sort the array
     setUniqueDurationsArray(uniqueArray.sort((a, b) => a - b));
   }

   const createVideoTagsArray = () => {
     // Step 1: Extract all tags into a single array
     const allTagsArray = videosList.flatMap((video) => video.tags);

     // Step 2: Use a Set to ensure uniqueness
     const uniqueTagsSet = [...new Set(allTagsArray)];
     // Sort the array
     setUniqueTagsArray(uniqueTagsSet.sort((a, b) => a - b));
   };

   const fetchData = async () =>{
    try{
        getVideosList();
        getSeriesList();
        handleMandalasList();
    }catch(error){
        console.log(error);
    }finally{
      setFetchComplete(true);
    }
   }

   

   const checkSubscription= async()=>{
    try {
      setIsLoading(true);
      return checkUserMembership(currentUser)
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false);
    }

  }



   useEffect(() => {
     fetchData();
     checkSubscription()
      .then(subscriptionResult => {
        setSubscriptionUserList(subscriptionResult);
      })
      .catch(error => {
        console.error("Error while fetching subscription:", error);
      });
   }, []);

   useEffect(() =>{
      createVideoDurationArray();
      createVideoTagsArray();  
      
   },[videosList])

   useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCheck(true);
  
      try {
        if (!videosList) {
          throw new Error('Videos list is not available');
        }
  
        const updatedAllowUserList = await Promise.all(
          videosList.map(async (item) => {
            return await checkAllowUser(item, currentUser, subcriptionUserList);
          })
        );
  
        setAllowUserList(updatedAllowUserList);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingCheck(false);
      }
    };
  
    fetchData();
  }, [videosList, currentUser,subcriptionUserList]);

   return (
     <>
       <section className="index-platform flex gap-3">
         <VerticalNavBar />
         <div className="flex flex-col justify-start items-center pb-5 md:px-12 w-full gap-5">
           <div className=" flex flex-col justify-center items-center pb-1">
             <div className="image-container w-12 h-16 md:h-20">
               <img src="../../assets/icons/Logo/Logo-sm.png" alt="Logo-SA" />
             </div>
             <div className="text text-center">
               <p className="font-[Generica]">
                 "It's not about being good at something. It's about being good
                 to yourself."
               </p>
             </div>
           </div>
           <div className="filter-by-trends-box flex flex-col gap-2 w-full justify-start px-4 sm:px-0">
             <h5 className="font-[Generica] uppercase">Duración</h5>

             {isLoading ? null : (
               <div className="box-content flex flex-wrap gap-5 items-center sm:items-start">
                 <DurationItems />
               </div>
             )}
           </div>
           <div className="filter-by-popular-box flex flex-col gap-2 w-full justify-start px-4 sm:px-0">
             <h5 className="font-[Generica] uppercase">Populares</h5>
             <div className="library-section">
               {isLoadingCheck ? (
                 <>
                   <div className="flex flex-wrap gap-5 justify-center">
                     {[...Array(6)].map((e, i) => (
                       <CardBoxVideo
                         enable={true}
                         title={""}
                         duration={20}
                         level=""
                         intensity={4}
                         path={""}
                         id={""}
                         imgUrl={"Intermedio"}
                         serie={""}
                         loading={true}
                         key={i}
                       />
                     ))}
                   </div>
                 </>
               ) : (
                // If not loading, check if no videos are available
                !allowUserList.length || !videosList.length ? (
                    // If no videos are available, render the "no videos available" message
                    <div className="flex flex-col justify-center items-center gap-5">
                        <div className="image-container flex flex-col justify center w-24">
                            <img src="../assets/icons/Forms/no-video.gif" alt="not-video" />
                        </div>
                        <h4 className="text-xl text-sb-800 opacity-50 uppercase font-[DIN-Condensed]">
                            No hay video disponibles
                        </h4>
                    </div>
                ) :  (
                  videosList.slice(0, 6).map((item, index) => {
                    return (
                      <CardBoxVideo
                        enable={currentUser.email==='sara.asanas@gmail.com' ? true:allowUserList[index]}
                        title={item.title}
                        duration={item.duration}
                        level="Intermedio"
                        intensity={4}
                        path={item.thumbnail}
                        id={item.videoId}
                        imgUrl={item.thumbnail}
                        serie={item.tags[0]}
                        loading={false}
                        key={item.videoId}
                        allowSubscription={item.allowSubscription}
                      />
                    );
                  })
                  )
               )}
             </div>
           </div>
           <div className="filter-by-Type-box flex flex-col gap-2 w-full justify-start px-4 sm:px-0">
             <h5 className="font-[Generica] uppercase">Tipo de Yoga</h5>

             {isLoading ? null : (
               <div className="box-content flex flex-wrap gap-5 items-center sm:items-start">
                 <YogaTypesButtons />
               </div>
             )}
           </div>
           <div className="filter-by-popular-box flex flex-col gap-2 w-full justify-start px-4 sm:px-0">
             <h5 className="font-[Generica] uppercase">Resistencia</h5>
             <div className="library-section">
               {isLoading ? (
                 <>
                   <div className="flex flex-wrap gap-5 justify-center">
                     {[...Array(3)].map((e, i) => (
                       <CardBoxVideo
                         enable={true}
                         title={""}
                         duration={20}
                         level=""
                         intensity={4}
                         path={""}
                         id={""}
                         imgUrl={"Intermedio"}
                         serie={""}
                         loading={true}
                         key={i}
                       />
                     ))}
                   </div>
                 </>
               ) : (
                 <div className="flex flex-wrap justify-center items-center content-center gap-2 md:gap-5">
                   <Link to={`/library/searchBy?intensity=Principiante`}>
                     <div className="intensity-card-box flex flex-col justify-center items-center">
                       <img
                         className="w-16 md:w-20 h-16 md:h-20"
                         src="../../assets/icons/Mandala/loto-3.png"
                         alt="loto-1"
                       />
                       <p className="font-[DIN-Condensed] text-xl">
                         Principiante
                       </p>
                     </div>
                   </Link>
                   <Link to={`/library/searchBy?intensity=Intermedio`}>
                     <div className="intensity-card-box flex flex-col justify-center items-center">
                       <img
                         className="w-16 md:w-20 h-16 md:h-20"
                         src="../../assets/icons/Mandala/loto-2.png"
                         alt="loto-3"
                       />
                       <p className="font-[DIN-Condensed] text-xl">
                         Intermedio
                       </p>
                     </div>
                   </Link>
                   <Link to={`/library/searchBy?intensity=Avanzado`}>
                     <div className="intensity-card-box flex flex-col justify-center items-center">
                       <img
                         className="w-16 md:w-20 h-16 md:h-20"
                         src="../../assets/icons/Mandala/loto-1.png"
                         alt="loto-2"
                       />
                       <p className="font-[DIN-Condensed] text-xl">Avanzado</p>
                     </div>
                   </Link>
                 </div>
               )}
             </div>
           </div>
           <div className="filter-by-mandala-box flex flex-col gap-2 w-full justify-start px-4 sm:px-0">
             <h5 className="font-[Generica] uppercase">Mandalas</h5>

             {isLoading ? null : (
               <div className="box-content flex flex-wrap gap-5 items-center sm:items-start">
                 <div className="mantras-box flex flex-wrap justify-around gap-5">
                   {mandalasList.map((item, index) => {
                     return (
                       <div
                         className="mantra-box flex flex-col justify-center items-center text-center gap-3"
                         key={index}
                       >
                         {item.active ? (
                           <Link
                             to={`/library/searchBy?series=${item.serieId}`}
                           >
                             <div className="image-container w-[100px] h-[100px]">
                               <img src={item.path} alt={item.name} />
                             </div>
                           </Link>
                         ) : (
                           <div className="image-container next w-[100px] h-[100px] flex justify-center items-center">
                             <img src={item.path} alt={item.name} />
                             <p>Proximamente</p>
                           </div>
                         )}

                         <p className="uppercase font-[Generica] text-base">
                           {item.name}
                         </p>
                       </div>
                     );
                   })}
                 </div>
               </div>
             )}
           </div>
           <div className="filter-by-mandala-box flex flex-col gap-2 w-full justify-start px-4 sm:px-0">
             <h5 className="font-[Generica] uppercase">Retos</h5>
             <div className="box-content flex flex-wrap gap-5 items-center sm:items-start">
             {isLoading ? null : (
              seriesList.map((item,index) =>{
                return(
                <div className="mantras-box flex flex-wrap justify-around gap-5">
                  <div className="mantras-box flex flex-wrap justify-around gap-5">
                    <div className="flex flex-col justify-center items-center gap-5">
                      <Link
                        to={`/library/searchBy?series=${item.seriesId}`}
                      >
                        <div className="image-container flex justify-center items-center w-36 h-36 cursor-pointer">
                          <img
                            src={item.thumbnail}
                            alt="reto"
                          />
                        </div>
                      </Link>
                      <p className="uppercase font-[MADE-Bon-Voyage] text-center text-xl max-w-[300px]">
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>)
              })        
             )}
            </div>
           </div>
           <div className="flex justify-center">
             <Link to={`/library/searchBy?all`}>
               <button className="button-30 w-fit p-4 uppercase">
                 Ver Todo
               </button>
             </Link>
           </div>
         </div>
       </section>
     </>
   );
};

export default VideoLibraryPro;
