import React,{useState} from 'react'
import { Alert,Box,Button,Typography, Chip, Avatar, Grid, Paper } from '@mui/material';
import { modalStyle } from '../GeneralData';
import { LiveClassData } from './LiveClassData';
import { removeLiveClassById } from '../../../../services/liveClasses';

interface Props {
    refreshData: () => void;
    handleClose:() => void;
    liveClass:LiveClassData;
  }

const RemoveLiveClassesForm:React.FC<Props> = ({liveClass,refreshData,handleClose}) =>{
    const [message,setMessage]= useState();
    const [success,setSuccess]=useState(false);
    const date = new Date(liveClass.date);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', // Display the year in full (e.g., "2024")
        month: 'long',   // Display the full name of the month (e.g., "March")
        day: 'numeric'   // Display the day of the month (e.g., "23")
      };
    // Create a formatter with the locale set to Spanish (Mexico)
    const formatter = new Intl.DateTimeFormat('es-MX', options);
    // Format the date using the formatter
    const dateString = formatter.format(date)


    const handleRemoveLiveClass= async (liveClassId:string) =>{
        try {
            const res = await removeLiveClassById(liveClassId);
            console.log(res);
            //setMessage(res);
            setSuccess(true);
        } catch (error:any) {
            setMessage(error)
        }
    }


    const closeModal=()=>{
        handleClose();
    }

    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    }

    console.log(liveClass.date)
  return (
    <Box sx={modalStyle}>
    {!success ? <div>
        <div className="title text-center p-5">
        <h5 className='text-2xl'>¿Desesas Eliminar {liveClass.title}?</h5>
    </div>
    <div className="user-info">
        <Paper sx={{ padding: 2}}>
        <Typography variant="h4" gutterBottom textAlign="center">
            {liveClass.description}
        </Typography>
        <Grid container justifyContent="center" padding={3}>
            <Grid item>
            <Avatar alt="Thumbnail" src={"../../assets/images/Portadas/Video-P-1.jpg"} sx={{ width: 150, height: 150 }}/>
            </Grid>
        </Grid>
        <Typography variant="body1" gutterBottom>
            <b>Fecha: </b>
            {dateString}
        </Typography>
        <Typography variant="body1" gutterBottom>
            <b>Inicio: </b>
            {liveClass.startTime}
        </Typography>
        <Typography variant="body1" gutterBottom>
            <b>Series: </b>
            {liveClass.seriesId}
        </Typography>
        <Typography variant="body1" gutterBottom>
            <b>Duracion: </b>
            {liveClass.duration}
        </Typography>
        <Typography variant="body1" gutterBottom>
            <b>Zoom Link: </b>
            {liveClass.zoomUrl}
        </Typography>
        <Typography variant="body2" gutterBottom>
            <b>Subscripciones: </b>
            {liveClass.allowedSubscriptions.map((subscription, index) => (
            <Chip key={index} label={subscription} style={{ marginRight: '4px',background:"#FFCD59" }} />
            ))}
        </Typography>
        </Paper>
    </div>
    <div className="actionContainer flex flex-wrap gap-5 justify-center items-center p-5">
        <Button variant='contained' color='success' onClick={()=>handleRemoveLiveClass(liveClass.classId)}>Continuar</Button>
        <Button variant='contained' color='error' onClick={closeModal}>Cancelar</Button>
    </div>

    </div>:<div className="flex flex-col gap-5 justify-center items-center">
    <Alert severity="success">{message}</Alert>
      <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
    </div>}

</Box>
  )
}

export default RemoveLiveClassesForm