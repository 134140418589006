import React from 'react'
import './CardBox3.css'
import { Link } from 'react-router-dom';

interface CardInfo{
  title:string;
  desc:string;
  url:string;
  imageUrl:string;
  active:boolean;
  enable:boolean;
  payementLink:string | null;
}

const CardBox3 = (cardInfo:CardInfo) => {
  return <>
    <div className={`card-box-3 w-[200px] h-[200px] rounded-xl flex flex-col justify-center items-center gap-5 ${cardInfo.active ? '':'next'}`}>
        <img src={cardInfo.imageUrl} className='absolute  w-[200px] h-[200px]  -z-10 rounded-xl' alt="" />
        <div className={`flex flex-col items-center gap-2 text-justify z-10 p-5 h-full w-full ${cardInfo.active ? 'justify-end ':'justify-center'}`}>
            {cardInfo.active ? (null):(<p className='text-white'>Próximamente</p>)}
            
            {cardInfo.enable ? 
              (
              <Link to={cardInfo.url} className={`${cardInfo.active ? '':'!hidden'}`}>
              <button className={`button-30 w-auto z-10 ${cardInfo.active ? '':'!hidden'}`} >Ver Reto</button>
               </Link>)
              :(
              <Link to={cardInfo.payementLink ? cardInfo.payementLink:'/'} target="_blank" className={`${cardInfo.active ? '':'!hidden'}`}>
              <button className={`button-29 w-auto z-10 ${cardInfo.active ? '':'!hidden'}`} >Suscribirme</button>
              </Link>)}
          
        </div>
    </div>
  </>
}

export default CardBox3;