import React, { useState } from 'react';
import { Alert,TextField, Button, FormControl, FormControlLabel, Checkbox, Grid, Paper, Typography, Box,Switch } from '@mui/material';
import { ActiveSubscriptionData } from './ActiveSubscriptionData';
import { modalStyle } from '../GeneralData';
import { addActiveSubscription } from '../../../../services/series';

interface Props {
    refreshData: () => void;
    handleClose:() => void;
  }

const AddActiveSubscriptionForm:React.FC<Props> = ({refreshData,handleClose})  => {
    const [formData, setFormData] = useState<ActiveSubscriptionData>({
        id: '',
        planId: '',
        startDate: new Date(),
        isActive: true
    });
    const [isActive, setIsActive] = useState<boolean>(false);
    const [success,setSuccess]=useState(false);
    const [message,setMessage]= useState<string>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit =async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const res = await addActiveSubscription(formData.planId);
        setMessage("Se Agrego de Forma Exitosa");
        setSuccess(true);
    };

    const closeModal=()=>{
        handleClose();
    }

    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    }

    return (
        <Box sx={modalStyle}>
             {!success ? (<Paper sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>Agregar Tipo de Subscripción</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Plan ID"
                                variant="outlined"
                                fullWidth
                                name="planId"
                                value={formData.planId}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="success">Agregar Subscripción</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>):(<div className="flex flex-col gap-5 justify-center items-center">
    <Alert severity="success">Se Agrego de Forma Exitosa</Alert>
      <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
    </div>)}
    </Box>
            
    );
};

export default AddActiveSubscriptionForm;
