import React,{useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {useMatch } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { addUser,addAccessUser } from '../../../services/user';
import Alert from '@mui/material/Alert';
import Loader from '../../../components/Loaders/Loader';
import { restartUser } from '../../../redux/userRedux';
import { activateOrder, checkToken, findOrderById } from '../../../services/order';


interface userAccessModel{
    fullName: string | null;
    username: string | null;
    email: string | null;
    password: string | null;
}

interface UserModel{
    fullName: string | null;
    username: string | null;
    email: string | null;
    subscriptionPlanId:string[];
}

interface OrderModel {
  orderId: string;
  invoiceId: string;
  planId: string; // Product Id
  productId: string;
  paymentId: string;
  chargeId: string;
  customerEmail: string;
  customerName: string;
  periodStart: number; // Assuming it represents a Unix timestamp
  periodEnd: number; // Assuming it represents a Unix timestamp
  subscriptionId: string;
  receiptUrl: string;
  token: string;
  tokenActive: boolean;
  created: Date;
}

type ChildProps = {
  handleRegister: (value: boolean) => void;
};

const RegisterFormFree:React.FC<ChildProps> = (props) => {
  const [user, setUser] = useState<userAccessModel>({
  fullName: '',
  username: '',
  email: '',
  password: '',
});
const [newUser,setNewUser] =  useState<UserModel>({
  fullName: '',
  username: '',
  email: '',
  subscriptionPlanId: [],
});
const [order, setOrder] = useState<OrderModel>({
    orderId: '',
    invoiceId: '',
    planId: '',
    productId: '',
    paymentId: '',
    chargeId: '',
    customerEmail: '',
    customerName: '',
    periodStart: 0, // You can set an initial value here
    periodEnd: 0, // You can set an initial value here
    subscriptionId: '',
    receiptUrl: '',
    token: '',
    tokenActive: false,
    created: new Date(),
  });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registerSuccess,setRegisterSuccess] = useState(false);
  const [errorMessage,setErrorMessage] = useState('');
  const [isDisabled,setIsDisabled] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userCreated,setUserCreated] = useState(false);
  const [matchPassword,setMatchPassword] = useState(true);
  const [valid, setValid] = useState(true);
  const [tokenValid,setTokenValid]=useState(false);
  const navigate = useNavigate();
  const { isFetching, error } = useSelector((state: any) => state.user);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const match:any = useMatch("/:firstRoute/:secondRoute/*");
  const { firstRoute, secondRoute } = match.params;

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  useEffect(()=>{
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setMatchPassword(false);
  },[])

   useEffect(() =>{
    const isErrorMessagePresent = errorMessage === 'Link Error';
    const isFormIncomplete = user.fullName === '' || user.username === '' || user.password === '' || !valid;
    setIsDisabled(isErrorMessagePresent || isFormIncomplete);
  },[user,errorMessage,valid])

  const checkMatchPassword= () =>{
    if(user.password === confirmPassword){
        setMatchPassword(true);
        return true;
    }else{
        setMatchPassword(false);
        setUser({...user, password:''})
        setConfirmPassword('');
        alert("Las contraseñas no coinciden");
        return false;
    }
    
  };

  const createAccessUser= async (userModel:userAccessModel) =>{
   setErrorMessage('');
    try {
      if(checkMatchPassword()){
        dispatch(restartUser);
        const response:any = await addAccessUser(dispatch,userModel);
        if(response.status === 201){
            await createUser(userModel)
            props.handleRegister(true);
        }else{
          setErrorMessage(response.response.data);
        }
      }  
    } catch (error) {
        console.error("An error occurred:", error);
       
    }
  }


  const createUser = async (userModel:userAccessModel) => {
    const user:UserModel= {
        fullName: userModel.fullName,
        username: userModel.username,
        email: userModel.email,
        subscriptionPlanId:[]
    };

    const match = secondRoute.match(/registro-(.+)/);

    if (match) {
      // Extract the subscription plan ID from the route
      const subscriptionPlanId = match[1].toUpperCase();
      user.subscriptionPlanId = [subscriptionPlanId];
    }

    try {
        dispatch(restartUser);
        const response = await addUser(dispatch,user);
        console.log(response);
        setUserCreated(true);
        setRegisterSuccess(true);
    } catch (error) {
        console.error("An error occurred:", error);
    }
  }

  const handleUserCreation = async (userModel:userAccessModel) =>{
    try{
        await createAccessUser(userModel); 
    } catch (error) {
        console.error("An error occurred:", error);
    }
  }

   const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the input value matches the email pattern
    const isValid = emailPattern.test(inputValue);
    setUser({ ...user, email: inputValue });
    setValid(isValid);

  };

  return <>
     <section className="register-section">
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {registerSuccess ? (
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="">
            <img src="../../assets/icons/Logo/Logo-sm.png" width={64} alt="Logo" />
          </div>
          <div className='flex flex-col justify-center items-center gap-5'>
            <p className='text-center text-xl uppercase'>Tu registro ha sido exitoso</p>
            <button className='button-30 w-fit p-2' onClick={() => navigate("/login")}>Ir a Inicio</button>
          </div>
        </div>
      ):(
        <div className='flex flex-col justify-center items-center'>
        <div className="">
            <img src="../../assets/icons/Logo/Logo-sm.png" width={64} alt="Logo" />
        </div>
        <p className='text-center text-xl uppercase'>Iniciar Registro</p>
        <TextField
          label=""
          error={!valid}
          helperText={!valid ? 'Correo Invalido' : ''}
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          value={user.email}
          onChange={handleEmailChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">Correo</InputAdornment>,
          }}
        />
         <TextField
          label=""
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          onChange={(e) => setUser({...user, fullName:e.target.value})}
          InputProps={{
            startAdornment: <InputAdornment position="start">Nombre</InputAdornment>,
          }}
        />
        <TextField
          label=""
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          onChange={(e) => setUser({...user, username:e.target.value})}
          InputProps={{
            startAdornment: <InputAdornment position="start">Usuario</InputAdornment>,
          }}
        />
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={user.password}
            onChange={(e) => setUser({...user, password:e.target.value})}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirmar Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="ConfirmPassword"
          />
        </FormControl>
        <div className="flex flex-col gap-5 justify-center items-center">
             <Button  onClick={() => handleUserCreation(user)} className="button-29 w-fit p-2" variant="contained" disabled={isDisabled} >{isFetching ? <Loader/>:'Registrarse' }</Button>
            {errorMessage && (<Alert severity="error">{errorMessage}</Alert>)}
            <div className="" onClick={() => navigate("/login")}>
                  <p className="text-sb-900 font-[DIN-Condensed] text-lg text-center hover:font-bold cursor-pointer">
                    Inicar Sesión
                  </p>
            </div>
        </div>
        </div>
      )}    
    </Box>
      </section>
  </>
}

export default RegisterFormFree

