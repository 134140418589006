import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Button, Container } from '@mui/material';

const NotFound = () => {
  return (
    <Container sx={{display:'flex',justifyContent:'center',flexDirection:'column'}} className='items-center gap-5 '>
    <div className="flex flex-wrap justify-center items-center gap-5">
    <div className="flex flex-col">
      <Typography variant="h3" gutterBottom>
        404 - Not Found
      </Typography>
      <Typography variant="body1" gutterBottom>
        La pagina que buscas no existe.
      </Typography>
      </div>
       <div className="img-container">
            <img src="../../assets/icons/Forms/error.png" alt="Error-404" height={120} width={120}  />
       </div>
      </div>
      <Button component={RouterLink} to="/" variant="contained" color="primary">
        Ir a Inicio
      </Button>
    </Container>
  );
};

export default NotFound;
