import React from 'react'
import './Footer.css'


const Footer = () => {
  return <>
    <footer className='flex justify-around p-5 bg-black items-center bg-sc-c2'>
        <div className='border w-1/3 h-2'></div>
        <img src="../../assets/icons/Logo/Logo-sm.png" className='w-16' alt="" />
        <div className='border w-1/3 h-[1px] block'></div>
    </footer>
  </>
}

export default Footer