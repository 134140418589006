import React from 'react'
import { Link } from "react-router-dom";

interface YogaButton{
    title:string;
    path:string;
    icon:string;
}

const yogaTypes:YogaButton[]=[
    {
        title:'AFRICAN MIX',
        path:'AFRICAN_MIX',
        icon:'../../assets/icons/Position-Orange/yoga-pose-5.png'
    },
    {
        title:'ECSTATIC DANCE',
        path:'ECSTATIC DANCE',
        icon:'../../assets/icons/Position-Orange/yoga-pose-11.webp'
    },
    {
        title:'MANDALA VINYASA',
        path:'MANDALA VINYASA',
        icon:'../../assets/icons/Position-Orange/yoga-pose-7.png'
    },
    {
        title:'POWER YOGA',
        path:'POWER YOGA',
        icon:'../../assets/icons/Position-Orange/yoga-pose.png'
    },
    {
        title:'VINYASA',
        path:'VINYASA',
        icon:'../../assets/icons/Position-Orange/yoga-pose-9.png'
    },
    {
        title:'YOGA SUAVE',
        path:'YOGA SUAVE',
        icon:'../../assets/icons/Position-Orange/yoga-pose-12.webp'
    },
    {
        title:'YOGA ABS',
        path:'YOGA ABS',
        icon:'../../assets/icons/Position-Orange/yoga-pose-3.png'
    },
    {
        title:'Yoga Pierna',
        path:'Yoga Pierna',
        icon:'../../assets/icons/Position-Orange/yoga-pose-13.webp'
    },
    {
        title:'Meditación',
        path:'Meditacion',
        icon:'../../assets/icons/Position-Orange/yoga-pose-2.png'
    }
]

const YogaTypesButtons = () => {
  return (
    <div className="flex flex-wrap gap-2 sm:gap-10 justify-center sm:justify-start items-baseline">
        {yogaTypes.map((item,index)=>{
            return(
                <Link to={`/library/searchBy?tag=${item.path}`}>
                    <div key={item.title} className='flex flex-col gap-5 w-fit text-center justify-center items-center cursor-pointer'>
                        <div className="rounded-full w-20 bg-sc-c2 p-4">
                            <img src={item.icon} alt={item.title} />
                        </div>
                        <p className='w-20 uppercase font-[Generica]'>{item.title}</p>
                    </div>
                </Link>
            )
        })}
    </div>
    
  )
}

export default YogaTypesButtons