import {
    publicRequest,
    userRequestByParams,
  } from "../requestMethods/requestMethods";


export async function getLastMessages(){
    return await publicRequest.get(`/messages/last`);

}

export async function getNextMessages(page){
    const params={
        page:page,
      }
    
    return await userRequestByParams(params).get(`/messages/next`);
}

export async function getNextMessagesByTypeAndReference(messageType,referenceId,page){
    const params={
        messageType:messageType,
        referenceId:referenceId,
        page:page,
      }
    
    return await userRequestByParams(params).get(`/messages/byMessageTypeAndreference/next`);
}

export async function getLastMessagesByTypeAndReference(messageType,referenceId){
    const params={
        messageType:messageType,
        referenceId:referenceId,
      }

      console.log(params);
    
    return await userRequestByParams(params).get(`/messages/byMessageTypeAndreference/last`);
}

export async function createMessage(message){
    return await publicRequest.post(`/messages`,message);
}

export async function removeMessage(messageId){
  return await publicRequest.delete(`/messages/${messageId}`);
}