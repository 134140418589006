import React,{useState,useEffect} from 'react'
import './RegisterPage.css'
import Card from '@mui/material/Card';
import HorizontalLinearStepper from './Stepper';
import RegisterForm from './RegisterForm';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { resendEmailOrder } from '../../../services/order';


const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '90vw',
  maxWidth:'600px',
  color:'white',
  bgcolor: " rgba( 84, 152, 156, 0.85 )",
  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
  border: "1px solid rgba( 255, 255, 255, 0.18 )",
  p: 2,
  borderRadius: 5,
  backdropFilter:'blur(4px)'
};

const PaymentSucessPage = () => {
  const[errorMessage,setErrorMessage] = useState(false);
  const[requestNewPasswordSuccess,setRequestNewPasswordSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [emailReset, setEmailReset] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  var interval: NodeJS.Timeout;
  
   useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

   const handleStart = () => {
    if (seconds === 0) {
      setSeconds(60);
    }
    setIsActive(!isActive);
  };


  const handleResendEmail = async(email:string)=>{
    try {
      setIsLoading(true);
      const response:any = await resendEmailOrder(email);
      console.log(response);
      if (response && response.data === "Email sent successfully") {
        alert("Se ha enviado el correo de forma exitosa")
        handleStart();
      }else{
        alert("No se encontró el Usuario")
      }
      console.log(response);

    } catch (error) {
      console.log(error);
    }finally{
      setIsLoading(false);
      
    }
    

  };

  return <>
  <section className='flex justify-center items-center h-full register-section-1'>
     <div>
        {requestNewPasswordSuccess ? (
          <Box sx={modalStyle}>
            <div className="w-full flex justify-center">
              <img
                src="../../assets/icons/Logo/Logo-sm.png"
                width={64}
                alt="Logo"
                className="invert brightness-0"
              />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
            >
              Se ha enviado un correo al {emailReset} con el link para
              registrarte
            </Typography>
          </Box>
        ) : (
          <Box sx={modalStyle}>
            <div className="w-full flex justify-center">
              <img
                src="../../assets/icons/Logo/Logo-sm.png"
                width={64}
                alt="Logo"
                className="invert brightness-0"
              />
            </div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Tu Compra ha sido exitosa
            </Typography>
            <Typography id="modal-modal-title">
              Se ha enviado un correo con el link de registro
            </Typography>

            {isActive ? (<div className='flex flex-col gap-3 justify-center items-center text-center'>
              <h4 className='text-xl'>Podrás reenviar el correo en:</h4>
              <h3 className="text-4xl">{seconds}</h3>
              <div className="image-container w-24">
                <img src="../assets/icons/Position-Orange/lotus-pose.gif" alt="yoga-pose" />
              </div>
            </div>):<>
            <Typography id="modal-modal-title" variant="h6" component="h2">
             Reenviar correo
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <input
                type="email"
                className="text w-full p-3 text-sb-900"
                name="Email"
                onChange={(e) => {
                  setEmailReset(e.target.value);
                  setErrorMessage(false);
                }}
                placeholder="Correo Electronico"
              />
            </Typography>
            <div className="flex w-full justify-center p-2">
              <Button
                onClick={() => handleResendEmail(emailReset)}
                className="button-30 w-fit p-2"
                variant="contained"
                disabled={isActive}
              >
                {isLoading ? <CircularProgress /> : "Enviar"}

              </Button>
            </div>
            </>}
            
          </Box>
        )}
      </div>
    <div className="flex justify-center bg-sb-600">
    </div>
        
  </section>
  
  </>
}

export default PaymentSucessPage

