import React, { useState } from 'react'
import './ImageBoxType1.css'

interface ImageSrc{
    name:string,
    source:string
}

const ImageBoxType1 = (ImageSrc:ImageSrc) => {
    const [imgSrc,setImgSrc] = useState(ImageSrc.source); 
  return <>
    <div className="image-container h-[585px] w-[500px]" id="image-box-type-1">
        <img src={imgSrc} alt={ImageSrc.name} onError = {() => setImgSrc("https://picsum.photos/200")} />
    </div>
  </>
}

export default ImageBoxType1;