import React, { useEffect,useState } from 'react';
import {Alert,Autocomplete,Box,Button,FormControl,InputLabel,InputAdornment,TextField,Typography, Chip, Avatar, Grid, Paper, Select, MenuItem } from '@mui/material';
import { Subscription } from '../Video/VideoData';
import { getAllActiveSubscriptions } from '../../../../services/series';
import { modalStyle } from '../GeneralData';
import { styled } from "@mui/material/styles";
import { LiveClassData } from './LiveClassData';
import { updateLiveClass } from '../../../../services/liveClasses';


interface Props {
    refreshData: () => void;
    handleClose:() => void;
    liveClass:LiveClassData;
  }
  const BigAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: "#54989C",
    fontSize:36,
    width: 120,
    height: 120,
    border: `2px solid ${theme.palette.background.paper}`,
    position: "relative",
    margin:'1rem'
  }));
const UpdateLiveClassForm:React.FC<Props> = ({liveClass,refreshData,handleClose}) =>{


  const [editing, setEditing] = useState(false);
  const [editedLiveClass, setEditedLiveClass] = useState(liveClass);
  const [subscriptionList, setSubscriptionList] = useState<Subscription[]>([]);
  const [filterSubscriptionList, setFilterSubscriptionList] = useState<Subscription[]>([]);
  const [message,setMessage]= useState();
  const [userList, setUserList] = useState<Subscription[]>([]);
  const [isLoadingModal, setIsLoadingModal] = useState(true);
  const [success,setSuccess]=useState(false);
  const [error,setError] = useState<String|null>();
  const date = new Date(liveClass.date);
  const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', // Display the year in full (e.g., "2024")
      month: 'long',   // Display the full name of the month (e.g., "March")
      day: 'numeric'   // Display the day of the month (e.g., "23")
    };
  // Create a formatter with the locale set to Spanish (Mexico)
  const formatter = new Intl.DateTimeFormat('es-MX', options);
  // Format the date using the formatter
  const dateString = formatter.format(date)

  useEffect(() => {
    filterSubscription();
  }, []);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof LiveClassData) => {
    setEditedLiveClass((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  }

  const handleSubscriptionsChange = (e: any, values: { planId: string }[]) => {
    const selectedPlanIds = values.map((value) => value.planId);
    setEditedLiveClass((prevData:any) => ({
      ...prevData,
      allowedSubscriptions: selectedPlanIds,
    }));
  };

  const closeSuccessFully=()=>{
    handleClose();
    refreshData();
}

  const handleEditClick = () => {
    getSubscription();
    setEditing(true);
  };

  const handleSaveClick = () => {
    handleUpdate();
    setEditing(false);
  };

  const handleUpdate= async() =>{
    try {
        const res = await updateLiveClass(editedLiveClass.classId,editedLiveClass);
        setSuccess(true);
        
    } catch (error:any) {
        setError(error);
    }
}

const filterSubscription = async () => {
    try {
      const subscriptions:any[] = await getSubscription(); // Assuming getSubscription() returns a Promise<Array<Subscription>>
      const filteredSubscriptions = subscriptions.filter(subscription =>
        editedLiveClass.allowedSubscriptions.includes(subscription.planId)
      );
      setFilterSubscriptionList(filteredSubscriptions);
    } catch (error) {
      console.error('Error fetching and filtering subscriptions:', error);
      // Handle error appropriately, e.g., display an error message to the user
    }
  };

  const getSubscription = async (): Promise<Subscription[]> => {
    try {
      setIsLoadingModal(true);
      const response:any = await getAllActiveSubscriptions(); // Assuming getAllActiveSubscriptions returns a Promise<ApiResponse<Subscription[]>>
      const data: Subscription[] = response.data; // Assuming the response contains the data property of type Subscription[]
      setSubscriptionList(data);
      return data;
    } catch (error:any) {
      console.error('Error fetching active subscriptions:', error);
      setError(error); // Consider providing more specific error handling based on different error scenarios
      throw error; // Re-throw the error to propagate it further if necessary
    } finally {
      setIsLoadingModal(false);
    }
  };



  return (
    <Box sx={modalStyle}>
    {!success ? (
        <Paper sx={{ padding: 2, maxHeight: 500, overflowY: 'scroll' }}>
                {editing ? (
                    <>
                        <b>Titulo:</b>
                        <Typography variant="body2" gutterBottom>
                        <TextField
                            fullWidth
                            value={editedLiveClass.title}
                            onChange={(e) => handleChange(e, 'title')}
                        />
                        <Grid container justifyContent="center" padding={3}>
                            <Grid item>
                            <Avatar alt="Thumbnail" src={"../../assets/images/Portadas/Video-P-1.jpg"} sx={{ width: 150, height: 150 }}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                            <TextField
                                    label="Fecha"
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    name="date"
                                    value={editedLiveClass.date}
                                    defaultValue={editedLiveClass.date}
                                    onChange={(e) => handleChange(e, 'date')}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                    label="Start Time"
                                    variant="outlined"
                                    fullWidth
                                    type="time"
                                    name="startTime"
                                    value={editedLiveClass.startTime}
                                    defaultValue={editedLiveClass.date}
                                    onChange={(e) => handleChange(e, 'startTime')}
                                    inputProps={{ step: 1800 }} // step is set to 5 minutes (300 seconds)
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">AM/PM</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        <b>Descripción:</b>
                        <TextField
                            fullWidth
                            value={editedLiveClass.description}
                            onChange={(e) => handleChange(e, 'description')}
                        />
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <b>ZoomUrl:</b>
                          <TextField
                              fullWidth
                              value={editedLiveClass.zoomUrl}
                              onChange={(e) => handleChange(e, 'zoomUrl')}
                          />
                        </Typography>
                        <FormControl className="p-5" style={{ width: '100%' }}>
                          <Autocomplete
                            style={{ width: '100%' }}
                            multiple
                            id="tags-standard"
                            options={subscriptionList}
                            defaultValue={filterSubscriptionList}
                            getOptionLabel={(option) => option.planId}
                            onChange={handleSubscriptionsChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Subscripciones"
                                placeholder="Subscripciones"
                              />
                            )}
                          />
                        </FormControl>

                        {/* Add more fields for other user properties */}
                    </>
                ) : (
                    <>
                        <Typography variant="h4" gutterBottom textAlign="center">
                            {liveClass.description}
                        </Typography>
                        <Grid container justifyContent="center" padding={3}>
                            <Grid item>
                            <Avatar alt="Thumbnail" src={"../../assets/images/Portadas/Video-P-1.jpg"} sx={{ width: 150, height: 150 }}/>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <b>Fecha: </b>
                            {dateString}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>Inicio: </b>
                            {liveClass.startTime}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>Duracion: </b>
                            {liveClass.duration}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>Zoom Link: </b>
                            {liveClass.zoomUrl}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <b>Subscripciones: </b>
                            {liveClass.allowedSubscriptions.map((subscription, index) => (
                            <Chip key={index} label={subscription} style={{ marginRight: '4px',background:"#FFCD59" }} />
                            ))}
                        </Typography>
                    </>
                )}
            {/* Add more sections for other user properties */}
            {editing ? (
                <div className='flex flex-wrap justify-around py-2 px-5'>
                    <Button onClick={handleSaveClick} variant="contained" color="primary">
                        Update
                    </Button>
                    <Button onClick={() => setEditing(false)} variant="contained" color="error">
                        Cancel
                    </Button>
                </div>
            ) : (
                <Button onClick={handleEditClick} variant="outlined">
                    Edit
                </Button>
            )}
        </Paper>
    ) : (
        <div className="flex flex-col gap-5 justify-center items-center">
            <Alert severity="success">{message}</Alert>
            <Button variant="contained" color="success" onClick={closeSuccessFully}>Continue</Button>
        </div>
    )}
</Box>
  )
}

export default UpdateLiveClassForm