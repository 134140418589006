import React from 'react'
import { Link } from 'react-router-dom';
import './CardBoxVideo.css'

interface Series {
    id: string;
    seriesId: string;
    title: string;
    thumbnail: string;
    description: string;
    listOfVideos: string[] | null;
    intensityLevel: string;
    yogaPositionsCovered: string[];
    active: boolean;
    payementLink: string;
  }

interface CardInfo{
    title:string;
    enable:boolean;
    duration:number;
    level:string;
    intensity:number;
    serie:string;
    seriesInfo:Series|null;
    path:string;
    id:string;
    imgUrl:string;
    loading:boolean;
}

const CardBoxVideo2 = (Info:CardInfo) => {

    const openInNewTab = (url:string) => {
        if(Info.serie === "RETO_ESTRES_ANSIEDAD"){
            window.open('https://buy.stripe.com/8wMcNr8vZ1LxeYw3cg', '_blank', 'noopener,noreferrer');
        }else{
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };
    
  return (
    <div className={`video-card-box-2 ${Info.loading ? 'is-loading':''} flex flex-col md:flex-row justify-center items-center gap-2 relative`}>
        <div className={`${Info.enable ? "hidden":"absolute"} flex flex-row justify-center items-center gap-2 bg-sb-800 bg-opacity-90 w-full h-full`}>
            <img src="../../assets/icons/Forms/Lock-icon.svg" alt="Lock-icon" />
           <button className='button-2' onClick={() => openInNewTab(Info.seriesInfo ? Info.seriesInfo.payementLink:'https://buy.stripe.com/3csfZDh2v61N03CaEO')}>Desbloquear</button>
        </div>
        <Link to={`/video/${Info.id}`} className='w-full md:w-[220px] h-[100px]'>
        <div className={`${Info.loading ? 'is-loading':''} w-full md:w-[150px] h-[100px] rounded-lg image-box`}>
            <img className="object-cover w-full h-full" src={Info.path ? Info.path:"../../assets/images/Portadas/Video-P-1.jpg"} alt="" />
        </div>
        </Link>
        <div className={`video-details ${Info.loading ? 'is-loading':''}  flex flex-col justify-center items-center w-[250px]`}> 
            <div className='flex flex-col gap-1 items-start justify-start pl-2'>
                <p className='sub-title font-[MADE-Bon-Voyage] text-lg text-left'>{Info.title}</p>
                <p className='sub-title font-[MADE-Bon-Voyage] text-sm'>{Info.serie ? Info.serie:''} </p>
            </div>
            <div className="video-spec flex justify-between gap-1 h-fit pl-1 py-1">
                <div className="icon-box flex gap-1">
                    <img src="../../assets/icons/Forms/reloj.svg" alt="clock-icon" />
                    <p className='font-[Generica]'>{Info.duration + ' Min'}</p>
                </div>
                <div className="vertical-border"></div>
                <div className="icon-box flex gap-1">
                    <p className='font-[Generica]'>{Info.level}</p>
                </div>
                <div className="vertical-border bg-sb-900"></div>
                <div className="icon-box flex gap-1">
                    <img src="../../assets/icons/Forms/Level-4.svg" alt="clock-icon" />
                </div>
            </div>
        </div>

    </div>
  )
}

export default CardBoxVideo2