import React,{useState,useRef} from 'react'
import {Alert,Box,Button,Card,Container,Typography, InputLabel, FormControl,TextField, TextareaAutosize, MenuItem,Select} from '@mui/material';
import {uploadImage } from '../../../../services/googleCloud';
import LinearProgress from '@mui/material/LinearProgress';
import ImageIcon from '@mui/icons-material/Image';
import ProductForm from './ProductForm';
import { modalStyle } from '../GeneralData';
import { addSerie,addPromotionSerie } from '../../../../services/series';

interface TPromotionProps {
    title: string;
    subtitle: string;
    thumbnail?: string | null;
    backgroundImage?: string | null;
    promotionImage?: string | null;
    paymentLink: string;
    duration: string;
    price: number;
    serieId: string;
    productId:string;
    shortDescription: string;
    longDescription: string;
    fullDescription: string;
    listOfProducts: string[];
}

interface Series {
    seriesId: string;
    title: string;
    thumbnail: string;
    description: string;
    listOfVideos: string[];
    intensityLevel: string;
    yogaPositionsCovered: string[];
    payementLink:string;
  }

const useStyles = {
    uploadBox: {
      background: "rgba(255, 255, 255, 0.25)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      width:'45%',
      maxWidth:600,
      backdropFilter: "blur(4px)",
      borderRadius: "10px",
      border: "1px solid rgba(255, 255, 255, 0.18)",
      transition: "transform 0.3s ease-in-out", // Add a transition for a smooth effect
      '&:hover': {
        transform: 'scale(1.05)', // Example: Scale the box on hover
        // Add additional styles as needed
      },
    },
  };


  interface Props {
    refreshData: () => void;
  }



const PromotionForm:React.FC<Props> = ({refreshData}) => {

    const [formData, setFormData] = useState<TPromotionProps>({
        title: '',
        subtitle: '',
        thumbnail: '',
        promotionImage: '',
        paymentLink: '',
        duration: '',
        price: 0,
        serieId: '',
        productId:'',
        shortDescription: '',
        longDescription: '',
        fullDescription: '',
        listOfProducts: [],
    });

    const [formDataSerie, setFormDataSerie]= useState({
        seriesId: '',//productId
        title: '',//title
        thumbnail: '',//thumbnail
        description: '',//shortDescription
        listOfVideos: [],
        intensityLevel: '',
        yogaPositionsCovered: [],
        payementLink:'',
      });
    const [uploading, setUploading] = useState(false);
    const [imgUrl,setImgUrl]= useState('');
    const [folderName, setFolderName] = useState('Series');
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState<String|null>();
    const [message, setMessage] = useState('');
    const [fileImage, setFileImage] = useState<File|null>(null);
    const hiddenImageFileInput = useRef<any|null>(null)

    

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        const parsedValue = name === 'price' ? parseFloat(value) : value;
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: parsedValue,
        }));
    };

      const handleChangeSerie = (e:any) => {
        const { name, value } = e.target;
        setFormDataSerie((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };


      const handleProductList= (list:string[]) =>{
        setFormData((prevData) => ({
          ...prevData,
          ['listOfProducts']: list,
        }));
      }


      const handleImageFileUpload = (event:any) => {
        hiddenImageFileInput.current.click();
      };
  
      const handleImageFileChange = (e:any) => {
        const selectedImage = e.target.files[0];
        setFileImage(selectedImage);
      };
     
      const handleImageUpload= async ():Promise<string | undefined>  => {
        try {
          const formData:any = new FormData();
          formData.append('file', fileImage);
          const response = await uploadImage(folderName,formData);
          setImgUrl(response.data);
          return response.data;
        } catch (error:any) {
          console.error('Error uploading file:', error);
          setError(error);
        }
      };

      const isPromotionDataValid = (): boolean => {
        return (
            typeof formData.title === 'string' && formData.title.trim() !== '' &&
            typeof formData.subtitle === 'string' && formData.subtitle.trim() !== '' &&
            fileImage !== null && // Check if fileImage is not null
            typeof formData.paymentLink === 'string' && formData.paymentLink.trim() !== '' &&
            typeof formData.duration === 'string' && formData.duration.trim() !== '' &&
            typeof formData.price === 'number' && formData.price > 0 &&
            typeof formData.serieId === 'string' && formData.serieId.trim() !== '' &&
            typeof formData.shortDescription === 'string' && formData.shortDescription.trim() !== '' &&
            typeof formData.longDescription === 'string' && formData.longDescription.trim() !== '' &&
            typeof formData.fullDescription === 'string' && formData.fullDescription.trim() !== '' &&
            Array.isArray(formData.listOfProducts) && formData.listOfProducts.length > 0 
        );
    };


      const handleSubmit = async (e:any) => {
        e.preventDefault();
        // Here you can handle form submission, for example, send formData to the server
        const thumbnailPromise: Promise<string | undefined>  =  handleImageUpload();
        const thumbnail: string | undefined = await thumbnailPromise;

        const serieData:Series={
            seriesId: formData.serieId,//productId
            title: formDataSerie.title,//title
            thumbnail: typeof thumbnail==='string' ? thumbnail:'' ,//thumbnail
            description: formData.shortDescription,//shortDescription
            listOfVideos: [],
            intensityLevel: formDataSerie.intensityLevel,
            yogaPositionsCovered: [],
            payementLink:formData.paymentLink,
        }

        const promotionData:TPromotionProps={
            title: formData.title,
            subtitle: formData.subtitle,
            thumbnail: thumbnail,
            promotionImage: thumbnail,
            paymentLink: formData.paymentLink,
            duration: formData.duration,
            price: formData.price,
            serieId: formData.serieId,
            productId:formData.productId,
            shortDescription: formData.shortDescription,
            longDescription: formData.shortDescription,
            fullDescription: formData.fullDescription,
            listOfProducts: formData.listOfProducts,
        }
        const resSerie = await addSerie(serieData);
        const resPromotion = await addPromotionSerie(promotionData);
       setSuccess(true);
      };


      const closeSuccessFully=()=>{
        refreshData();
    }

    return <>
    {uploading ? (<Box sx={modalStyle}>
           <div className={`flex flex-col gap-5 justiy-center items-center p-5`}>
             <div className='is-loading'>
               <img width={64} src="../../assets/icons/Dashboard/fileUpload.gif" alt="" />
             </div>
             <Box sx={{ width: '100%' }}>
               <LinearProgress sx={{height:10,borderRadius: 5}} />
             </Box>
             <Alert severity="info">Subiendo Informacion</Alert>
           </div>
           </Box>):
         (<Box sx={modalStyle} className="overflow-auto">
            {!success ? ( <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Agregar Serie
                </Typography>
            <div className='flex justify-center items-center'>
                <Card
                sx={useStyles.uploadBox}
                className="flex flex-col justify-start items-center p-3 text-center"
                >
                {fileImage ? null:<p className="p-2">Agregar Imagen Promocional</p>}
                <button className="w-fit" onClick={handleImageFileUpload}>
                    <ImageIcon
                    sx={{ fontSize: 64 }}
                    className="cursor-pointer"
                    />
                    <input
                    type="file"
                    name="btn-file"
                    id="btn-file"
                    onChange={handleImageFileChange}
                    accept="image/*"
                    ref={hiddenImageFileInput}
                    className="appearance-none hidden"
                    />
                    {fileImage ? <p>{fileImage.name.length > 20 ? `${fileImage.name.substring(0, 20)}...` : fileImage.name}</p> : null}
                </button>
                </Card>
            </div>
                <form onSubmit={handleSubmit}>
                    <TextField
                    name="title"
                    label="Titulo"
                    value={formData.title}
                    onChange={(e)=>{
                        handleChange(e); 
                        handleChangeSerie(e);
                    }}
                    fullWidth
                    margin="normal"
                    />
                    <TextField
                    name="subtitle"
                    label="Sub Titulo"
                    value={formData.subtitle}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    />
                    <TextField
                    name="serieId"
                    label="Serie Id"
                    value={formData.serieId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    />
                    <TextField
                    name="paymentLink"
                    label="Stripe Link"
                    value={formData.paymentLink}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    />
                    <div className="flex flex-wrap justify-center items-center gap-5 ">
                    <FormControl style={{ width: '45%' }}>
                        <TextField
                        name="price"
                        label="Precio"
                        type="number"
                        value={formData.price}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        />
                    </FormControl>
                    <FormControl style={{ width: '45%' }}>
                    <InputLabel>Intensidad</InputLabel>
                        <Select name="intensityLevel" value={formDataSerie.intensityLevel} onChange={handleChangeSerie}>
                            <MenuItem value="Principiante">Principiante</MenuItem>
                            <MenuItem value="Intermedio">Intermedio</MenuItem>
                            <MenuItem value="Avanzado">Avanzado</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                    <TextField
                    name="duration"
                    label="Duración de Reto"
                    value={formData.duration}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    />
                    <TextareaAutosize
                        name="shortDescription"
                        aria-label="Short Description"
                        placeholder="Promocion"
                        value={formData.shortDescription}
                         onChange={(e)=>{
                        handleChange(e); 
                        handleChangeSerie(e);
                    }}
                        minRows={3}
                        style={{ width: '100%', marginTop: '16px' }}
                    />
                    <TextareaAutosize
                        name="longDescription"
                        aria-label="Short Description"
                        placeholder="Resumen"
                        value={formData.longDescription}
                        onChange={handleChange}
                        minRows={3}
                        style={{ width: '100%', marginTop: '16px' }}
                    />
                    <TextareaAutosize
                        name="fullDescription"
                        aria-label="description"
                        placeholder="Descripción"
                        value={formData.fullDescription}
                        onChange={handleChange}
                        minRows={4}
                        style={{ width: '100%', marginTop: '16px' }}
                    />
                    <ProductForm handleListOfProducts={handleProductList}/>
                   <div className="flex justify-center p-5">
                   <Button type="submit" variant="contained" color="success" disabled={!isPromotionDataValid()}>
                        Agregar Serie
                    </Button>
                    </div>
                </form>
            </Container>):(<div className="flex flex-col gap-5 justify-center items-center">
         <Alert severity="success">{message}</Alert>
           <Button variant="contained" color="success" onClick={closeSuccessFully}>Continuar</Button>
         </div>)}
           
         </Box>)} 
         </>
}

export default PromotionForm