import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import VerticalNavBar from '../../../components/Navbar/PlatformNavBar/VerticalNavBar'
import {
  getVideoBySerieId,
  getVideosInteractionByUser,
} from "../../../services/videos";
import CardBoxVideo2 from '../../../components/CardsBox/CardBoxVideo2';
import './SeriesDetailsPage.css';
import SeriesProgress from '../../../components/SeriesChallengeBox/SeriesProgress';
import CheckIcon from "@mui/icons-material/Check";
import { getSeriesById } from '../../../services/series';
import { checkUserMembership,checkAllowUser } from '../../../utils/checkSubscription';
import ChatComponent from '../../../components/ChatComponent/ChatComponent';

const SeriesDetailsPage = () => {

  const [videosList, setVideosList] = useState([]);
  const [userVideosComplete, setUserVideosComplete] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingVideos, setLoadingVideos] = useState(true);
  const [serie,setSerie]=useState('');
  const [serieInfo,setSerieInfo] = useState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userId = useSelector((state) => state.user.currentUser.username);
  const [currentUser,setCurrentUser] = useState(useSelector((state) => state.user.currentUser));
  const [allowUserList, setAllowUserList] = useState([]);
  const [subcriptionUserList, setSubscriptionUserList] = useState([]);

  useEffect(() => {
    const paramNames = Array.from(urlParams.keys());
    const value = urlParams.get(paramNames[0]);
    setSerie(value);
    getSeriesDetails(value);
    getUserVideoInteraction(userId).then(() => {
      getVideosListBySeries(value);
    });
  }, [userId,currentUser]);


  useEffect(() => {
    checkSubscription().then((result) => {
      setSubscriptionUserList(result);
    }).catch((error) => {
      // Handle errors here
      console.error(error);
    });

    const updateAllowUserListAsync = async () => {
      const updatedAllowUserList = await Promise.all(
        videosList.map(async (item) => {
          let allowUser = false;
  
          if (item.allowedSubscriptions.includes("FREE")) {
            allowUser = true;
          } else if (currentUser && currentUser.subscriptionPlanId) {
            const nonBasicPremiumSubscriptions = currentUser.subscriptionPlanId.filter(subscriptionItem =>
              !['BASICA', 'PREMIUM'].includes(subscriptionItem.trim().toUpperCase())
            );
            allowUser = nonBasicPremiumSubscriptions.some(subscriptionItem =>
              item.allowedSubscriptions.some(allowedSubscription =>
                subscriptionItem.trim().toUpperCase() === allowedSubscription.trim().toUpperCase()
              )
            );
            if (!allowUser && item.allowedSubscriptions.some(subscriptionItem =>
              ['BASICA', 'PREMIUM'].includes(subscriptionItem.trim().toUpperCase())
            )) {
              // Check for active BASICA subscription
              if (item.allowedSubscriptions.includes('BASICA')) {
                allowUser = subcriptionUserList.some((subscriptionStatus) =>
                  subscriptionStatus.subcriptionName === 'BASICA' && subscriptionStatus.active === true
                );
              }
              
              // Check for active PREMIUM subscription if BASICA subscription check failed
              if (!allowUser && item.allowedSubscriptions.includes('PREMIUM')) {
                allowUser = subcriptionUserList.some((subscriptionStatus) =>
                  subscriptionStatus.subcriptionName === 'PREMIUM' && subscriptionStatus.active === true
                );
              }
            }
          }
  
          return allowUser;
        })
      );
  
      // Update the state with the new allowUser list
      setAllowUserList(updatedAllowUserList);
      setLoadingVideos(false);
    };
    updateAllowUserListAsync();
  }, [videosList, currentUser]);


   const getUserVideoInteraction = async (username) => {
     try {
       const response = await getVideosInteractionByUser(username);
       if(response && response.status && response.status === 404){
        console.log("No Interactions")
       }else{
        const data = response;
       setUserVideosComplete(data)
       };
     } catch (error) {
       console.log(error);
     } finally {
      setIsLoading(false);
     }
   };


  const getVideosListBySeries = async (seriesId) => {
    try {
      setIsLoading(true);
      const response = await getVideoBySerieId(seriesId);
      const data = response.data;
      const sortedVideosList = data.sort((a, b) => a.episodeId - b.episodeId);
      setVideosList(sortedVideosList);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSeriesDetails = async(seriesId) => {

    try {
      setIsLoading(true);
      const res = await getSeriesById(seriesId);
      if(res && res.status === 404){
        setSerieInfo(null);
      }else{
        setSerieInfo(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }


  const checkSubscription= async()=>{
    try {
      setIsLoading(true);
      return checkUserMembership(currentUser)
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false);
    }

  }


  return (
    <>
      <section id="series-details" className="index-platform flex">
        <VerticalNavBar />
        <div className="details-container w-full relative parallax">
          <div className="block w-full h-72 relative">
            <img
              className="object-cover image-detail w-full h-full"
              src="../../assets/images/sliders/sara-4.png"
              alt=""
            />
          </div>
          <div className="details flex flex-wrap-reverse gap-5 lg:gap-0 justify-around py-10">
            <div className="flex flex-col gap-5">
              {isLoading ? (
                [...Array(5)].map((e, i, arr) => (
                  <div key={i} className="step-container flex gap-4 justify-center items-center">
                    <div
                      className={`indicator rounded-full bg-so-300  w-10 h-10 flex justify-center items-center ${
                        arr.length - 1 === i ? "last-element" : ""
                      }`}
                    >
                      <p className="text-xl font-500 font-[DIN-Condensed]">
                        {i}
                      </p>
                    </div>
                    <CardBoxVideo2
                      enable={true}
                      title={""}
                      duration={20}
                      level=""
                      intensity={4}
                      path={""}
                      id={""}
                      imgUrl={"Intermedio"}
                      serie={""}
                      loading={true}
                      key={i}
                      seriesInfo={null}
                    />
                  </div>
                ))
              ) : (!allowUserList.length || !videosList.length ? (
                // If no videos are available, render the "no videos available" message
                <div className="flex flex-col justify-center items-center gap-5">
                    <div className="image-container flex flex-col justify center w-24">
                        <img src="../assets/icons/Forms/no-video.gif" alt="not-video" />
                    </div>
                    <h4 className="text-xl text-sb-800 opacity-50 uppercase font-[DIN-Condensed]">
                        No hay video disponibles
                    </h4>
                </div>
            ):
                videosList.map((item, index, arr) => {
                  var lastElement = false;
                  if (arr.length - 1 === index) {
                    lastElement = true;
                  }
                  return (
                    <div key={item.videoId}  className="step-container flex gap-4 justify-center items-center relative">
                      {userVideosComplete && userVideosComplete.some(
                        (videoUser) => videoUser.videoId === item.videoId
                      ) ? (
                        <div
                          className={`indicator-done rounded-full bg-sb-500 bg-  w-10 h-10 hidden sm:flex justify-center items-center ${
                            lastElement ? "last-element" : ""
                          }`}
                        >
                          <p className="text-xl font-500 font-[DIN-Condensed]">
                            <CheckIcon />
                          </p>
                        </div>
                      ) : (
                        <div
                          className={`indicator rounded-full bg-so-300  w-10 h-10 hidden sm:flex justify-center items-center ${
                            lastElement ? "last-element" : ""
                          }`}
                        >
                          <p className="text-xl font-500 font-[DIN-Condensed]">
                            {index + 1}
                          </p>
                        </div>
                      )}

                      <CardBoxVideo2
                        enable={currentUser.email==='sara.asanas@gmail.com' ? true:allowUserList[index]}
                        title={item.title}
                        duration={item.duration}
                        level={item.intensityLevel}
                        intensity={4}
                        path={item.thumbnail}
                        id={item.videoId}
                        imgUrl={item.thumbnail}
                        serie={item.tags[0]}
                        loading={false}
                        key={item.videoId}
                        seriesInfo={serieInfo}
                      />
                    </div>
                  );
                }))
              }
            </div>
            <div className="progress-container flex flex-col gap-5 justify-center md:justify-start overeflow-auto">
              <div className="progress-box mx-auto w-10/12 sm:w-3/4  md:w-[300px] bg-sb-200 h-fit col-span-4 rounded-lg p-5 mx-5 sticky top-0">
                {isLoading ? null : <SeriesProgress serie={serie} />}
                <div className="flex justify-center items-center w-full">
                  <ChatComponent messageType={"CHALLENGE"} referenceId={serie}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SeriesDetailsPage