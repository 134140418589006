export interface YogaButton{
    title:string;
    icon:string;
}

export const yogaTypes:YogaButton[]=[
    {
        title:'AFRICAN_MIX',
        icon:'../../assets/icons/Position-Orange/yoga-pose-5.png'
    },
    {
        title:'ECSTATIC DANCE',
        icon:'../../assets/icons/Position-Orange/yoga-pose-11.webp'
    },
    {
        title:'MANDALA VINYASA',
        icon:'../../assets/icons/Position-Orange/yoga-pose-7.png'
    },
    {
        title:'POWER YOGA',
        icon:'../../assets/icons/Position-Orange/yoga-pose.png'
    },
    {
        title:'VINYASA',
        icon:'../../assets/icons/Position-Orange/yoga-pose-9.png'
    },
    {
        title:'YOGA SUAVE',
        icon:'../../assets/icons/Position-Orange/yoga-pose-12.webp'
    },
    {
        title:'YOGA ABS',
        icon:'../../assets/icons/Position-Orange/yoga-pose-3.png'
    },
    {
        title:'Yoga Pierna',
        icon:'../../assets/icons/Position-Orange/yoga-pose-13.webp'
    },
    {
        title:'YOGA RESTAURATIVO',
        icon:'../../assets/icons/Position-Orange/yoga-pose-2.png'
    },
    {
        title:'MEDITACION',
        icon:'../../assets/icons/Position-Orange/yoga-pose-2.png'
    },
    {
        title:'RETO SEMANA',
        icon:'../../assets/icons/Position-Orange/yoga-pose-2.png'
    }
    
]
