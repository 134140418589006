import {
    publicRequest,
    createAxiosInstance,
  } from "../requestMethods/requestMethods";

  // Function to add a new promotion
export const addPromotion = async (promotion) => {
    try {
      const res = await publicRequest.post("/promotions", promotion);
      return res.data;
    } catch (error) {
      throw new Error('Failed to add promotion');
    }
  };
  
  // Function to fetch all promotions
  export const fetchPromotions = async () => {
    try {
      const res = await publicRequest.get("/promotions");
      return res.data;
    } catch (error) {
      throw new Error('Failed to fetch promotions');
    }
  };
  
  // Function to fetch a single promotion by ID
  export const fetchPromotionById = async (id) => {
    try {
      const res = await publicRequest.get(`/promotions/series/${id}`);
      return res.data;
    } catch (error) {
      throw new Error('Failed to fetch promotion');
    }
  };
  
  // Function to update an existing promotion
  export const updatePromotion = async (id, promotion) => {
    try {
      const res = await publicRequest.put(`/promotions/${id}`, promotion);
      return res.data;
    } catch (error) {
      throw new Error('Failed to update promotion');
    }
  };



  export const changeStatusPromotion = async (seriesId, statusPromotion) => {
    try {

      const dynamicParams = {
        status: statusPromotion,
        // Add more key-value pairs as needed
      };
      const axiosInstance = createAxiosInstance(dynamicParams);

        const res = await axiosInstance.put(`/promotions/updateActiveSatusPromotion/${seriesId}`);
        return res.data;
    } catch (error) {
        console.error('Failed to update promotion:', error);
        throw new Error('Failed to update promotion');
    }
  };
  
  // Function to delete a promotion
  export const deletePromotion = async (id) => {
    try {
      await publicRequest.delete(`/promotions/${id}`);
    } catch (error) {
      throw new Error('Failed to delete promotion');
    }
  };

  export const fetchPromotionsBySeriesId = async (seriesId) => {
    try {
      const res = await publicRequest.get(`/promotions/series/${seriesId}`);
      return res.data;
    } catch (error) {
      throw new Error('Failed to fetch promotions by seriesId');
    }
  };