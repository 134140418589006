import React, { useEffect,useState } from 'react';
import {Alert,Autocomplete,Box,Button,FormControl,InputLabel,TextField,Typography, Chip, Avatar, Grid, Paper, Select, MenuItem } from '@mui/material';
import { updateMandalaById } from '../../../../services/mandalas';
import { MandalaData } from './MandalaData';


const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    maxHeight:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)',
    overflowY:'automatic',
    zIndex:10,
  };

  interface Props {
    refreshData: () => void;
    handleClose:() => void;
    mandala:MandalaData;
  }


const UpdateMandalaForm:React.FC<Props> = ({mandala,refreshData,handleClose}) => {
    const [editing, setEditing] = useState(false);
    const [editedMandala, setEditedMandala] = useState(mandala);
    const [message,setMessage]= useState('');
    const [isLoadingModal, setIsLoadingModal] = useState(true);
    const [success,setSuccess]=useState(false);
    const [error,setError] = useState<String|null>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof MandalaData) => {
        setEditedMandala((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    };

    const handleUpdate= async() =>{
        try {
            const res:any = await updateMandalaById(editedMandala.id, editedMandala);
            if(res && res.status ===200){
              setMessage("Se Actualizo de forma exitosa");
            }
            setSuccess(true);
            
        } catch (error:any) {
            setError(error);
        }

    }

    const handleEditClick = () => {
      setEditing(true);
    };
  
    const handleSaveClick = () => {
      // Here you can handle the saving of edited video data
      handleUpdate();
      setEditing(false);
    };

    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    };

    return (
      <Box sx={modalStyle}>
  {!success ? (
    <Paper sx={{ padding: 2, maxHeight:500, overflowY:'scroll' }}>
      <Typography variant="h4" gutterBottom textAlign={'center'}>
        {editing ? (
          <>
            <p>Nombre de Mandala:</p>
            <TextField
              fullWidth
              value={editedMandala.name}
              onChange={(e) => handleChange(e, 'name')}
            />
          </>
        ) : (
          editedMandala.name
        )}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item>
          <Avatar alt="Thumbnail" src={editedMandala.path} sx={{ width: 150, height: 150 }}/>
        </Grid>
      </Grid>
      <Typography variant="body1" gutterBottom>
      <b>Serie Id: </b>
        {editing ? (
          <>
            <TextField
              fullWidth
              value={editedMandala.serieId}
              onChange={(e) => handleChange(e, 'serieId')}
            />
          </>
        ) : (
          editedMandala.serieId
        )}
      </Typography>
      <Typography variant="body1" gutterBottom>
      <b>Mes: </b>
        {editing ? (
          <>
            <TextField
              fullWidth
              value={editedMandala.month}
              onChange={(e) => handleChange(e, 'month')}
            />
          </>
        ) : (
          editedMandala.month
        )}
      </Typography>


      {editing ? (
        <div className='flex flex-wrap justify-around py-2 px-5'>
          <Button onClick={handleSaveClick} variant="contained" color="primary">
            Actualizar
          </Button>
          <Button onClick={() => setEditing(false)} variant="contained" color="error">
            Cancelar
          </Button>
        </div>
      ) : (
        <Button onClick={handleEditClick} variant="outlined">
          Editar
        </Button>
      )}

    </Paper>
  ) : (
    <div className="flex flex-col gap-5 justify-center items-center">
      <Alert severity="success">{message}</Alert>
      <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
    </div>
  )}
</Box>
    );
    
}

export default UpdateMandalaForm