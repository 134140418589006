import {
  publicRequest,
  createAxiosInstance,
} from "../requestMethods/requestMethods";
import { restartPasswordRequestFailure } from "../redux/userRedux";

export const findOrderById = async (dispatch, orderId) => {
  try {
    const res = await publicRequest.get(`/orders/findByOrderId/${orderId}`);
    return res;
  } catch (err) {
    dispatch(restartPasswordRequestFailure());
    return err;
  }
};

export const checkToken = async (orderId, token) => {
  try {
    const dynamicParams = {
      activationToken: token,
      // Add more key-value pairs as needed
    };
    const axiosInstance = createAxiosInstance(dynamicParams);

    const res = await axiosInstance.post(`/orders/${orderId}/check-token`);
    return res;
  } catch (err) {
    return err;
  }
};

export const activateOrder = async (orderId,token) => {
  try {
      const dynamicParams = {
        activationToken: token,
        // Add more key-value pairs as needed
      };
      const axiosInstance = createAxiosInstance(dynamicParams);

      const res = await axiosInstance.post(`/orders/${orderId}/activate`);
    return res;
  } catch (err) {
    return err;
  }
};

export const resendEmailOrder = async (customerEmail) => {
  console.log(customerEmail);
  try {
    const res = await publicRequest.post(
      `/orders/resend-email/${customerEmail}`
    );
    console.log(res.data);
    return res;
  } catch (err) {
    return err;
  }
};

export const getOrderCountByPlanId = async (planId) => {
  try {
    const res = await publicRequest.get(`/orders/count/${planId}`);
    return res.data;
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return 0;
    }
    console.error(err);
    throw err;
  }
};

