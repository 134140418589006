import React, { useEffect,useState } from 'react';
import {Alert,Autocomplete,Box,Button,FormControl,TextField,Typography, Chip, Avatar, Grid, Paper, Select, MenuItem } from '@mui/material';
import { getAllSeries,getAllActiveSubscriptions } from '../../../../services/series'
import { updateVideoById } from '../../../../services/videos';
import {VideoData,Subscription,Series } from './VideoData';
import { getAllUsers } from '../../../../services/user';
import { yogaTypes } from '../../../../Data/YogaTypes';
import { MandalaData } from '../Mandalas/MandalaData';
import { getAllMandalas } from '../../../../services/mandalas';


const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    maxHeight:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)',
    overflowY:'automatic',
    zIndex:10,
  };

  interface Props {
    refreshData: () => void;
    handleClose:() => void;
    video:VideoData;
  }



  
const UpdateVideoForm:React.FC<Props> = ({video,refreshData,handleClose}) => {
    const [editing, setEditing] = useState(false);
    const [editedVideo, setEditedVideo] = useState(video);
    const [subscriptionList, setSubscriptionList] = useState<Subscription[]>([]);
    const [filterSubscriptionList, setFilterSubscriptionList] = useState<Subscription[]>([]);
    const [message,setMessage]= useState();
    const [userList, setUserList] = useState<Subscription[]>([]);
    const [seriesIdList,setSeriesIdList]=useState<String[]>([]);
    const [isLoadingModal, setIsLoadingModal] = useState(true);
    const [success,setSuccess]=useState(false);
    const [error,setError] = useState<String|null>();


    useEffect(() => {
        filterSubscrition();
      }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof VideoData) => {
      setEditedVideo((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    };
  
    const handleEditClick = async () => {
      getSubscription();
      createSeriesIdList();
      filterSubscrition();
      setEditing(true);
    };

    const handleUpdate= async() =>{
        try {
            const res = await updateVideoById(editedVideo.id,editedVideo);
            setMessage(res);
            setSuccess(true);
            
        } catch (error:any) {
            setError(error);
        }

    }
  
    const handleSaveClick = () => {
      // Here you can handle the saving of edited video data
      handleUpdate();
      setEditing(false);
    };

    const filterSubscrition = async () => {
        try {
          getSubscription()
          .then(subscriptions => {
            const filteredSubscriptions = subscriptions.filter(subscription =>
                editedVideo.allowedSubscriptions.includes(subscription.planId)
              );

            setFilterSubscriptionList(filteredSubscriptions);
          })
          .catch(error => {
            console.error('Error fetching and filtering subscriptions:', error);
            // Handle error appropriately, e.g., display an error message to the user
          });
        } catch (error) {
          console.error('Error filtering subscriptions:', error);
          return []; // Return an empty array in case of error
        }
      };

    const getSubscription = async (): Promise<Subscription[]> => {
      try {
        setIsLoadingModal(true);
        const response:any = await getAllActiveSubscriptions(); // Assuming getAllActiveSubscriptions returns a Promise<ApiResponse<Subscription[]>>
        const data: Subscription[] = response.data; // Assuming the response contains the data property of type Subscription[]
        setSubscriptionList(data);
        return data;
      } catch (error:any) {
        console.error('Error fetching active subscriptions:', error);
        setError(error); // Consider providing more specific error handling based on different error scenarios
        throw error; // Re-throw the error to propagate it further if necessary
      } finally {
        setIsLoadingModal(false);
      }
    };

    const getUsers = async() =>{
      try{     
        setIsLoadingModal(true);
        const response:any = await getAllUsers();
        const data:any = response.data;
        setUserList(data);
      }catch(error:any){
          console.log(error);
          setError(error)
      }finally{
        setIsLoadingModal(false);
      }

    }
  
    const getSeriesList = async() =>{
      try{     
        setIsLoadingModal(true);
        const response:any = await getAllSeries();
        const data:any = response.data;
        return data;
      }catch(error:any){
          console.log(error);
          setError(error)
      }finally{
        setIsLoadingModal(false);
      }

    }

    const getMandalaList = async() =>{
      try{     
        setIsLoadingModal(true);
        const response:any = await getAllMandalas();
        const data:any = response.data;
        return data;
      }catch(error:any){
          console.log(error);
          setError(error)
      }finally{
        setIsLoadingModal(false);
      }

    }

    const createSeriesIdList = async ()=>{
      const series:Series[] = await getSeriesList();
      const mandalas:MandalaData[] = await getMandalaList();
      setSeriesIdList([]);
      series.forEach(item => {
        setSeriesIdList(prevList => [...prevList, item.seriesId]);
      });

      mandalas.forEach(item => {
        setSeriesIdList(prevList => [...prevList, item.serieId]);
      });
    }

    const handleTagsChange = (e: any, values: { title: string }[]) => {
      const selectedTags = values.map((value) => value.title);
      setEditedVideo((prevData:any) => ({
        ...prevData,
        tags: selectedTags,
      }));
    };

    const handleAllowedSubscriptionsChange = (e: any, values: { planId: string }[]) => {
      const selectedPlanIds = values.map((value) => value.planId);
      setEditedVideo((prevData:any) => ({
        ...prevData,
        allowedSubscriptions: selectedPlanIds,
      }));
    };

    const closeSuccessFully=()=>{
      handleClose();
      refreshData();
  }
  
  
    return (

    <Box sx={modalStyle}>
    {!success ? (   <Paper sx={{ padding: 2, maxHeight:500, overflowY:'scroll' }}>
      <Typography variant="h4" gutterBottom textAlign={'center'}>
    
        {editing ? (<>
        <p>Nombre de Video:</p>
          <TextField
            fullWidth
            value={editedVideo.title}
            onChange={(e) => handleChange(e, 'title')}
          />
          </>
        ) : (
            editedVideo.title
        )}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item>
          <Avatar alt="Thumbnail" src={video.thumbnail} sx={{ width: 150, height: 150 }}/>
        </Grid>
      </Grid>
      <Typography variant="body1" gutterBottom>
      <b>Descripción de Video: </b>
        {editing ? (<>
            <TextField
                fullWidth
                multiline
                value={editedVideo.description}
                onChange={(e) => handleChange(e, 'description')}
            />
          </>
        ) : (
            editedVideo.description
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
      <b>Tags: </b>
        {editing ? (
         <FormControl className="p-5" style={{ width: '100%' }}>
         <Autocomplete
           style={{ width: '100%' }}
           multiple
           id="tags-standard"
           options={yogaTypes}
           defaultValue={yogaTypes.filter((yogaType) =>
            editedVideo.tags.includes(yogaType.title))}
           getOptionLabel={(option) => option.title}
           onChange={handleTagsChange}
           renderInput={(params) => (
             <TextField
               {...params}
               variant="standard"
               label="Tags"
               placeholder="Yoga"
             />
           )}
         />
       </FormControl>
    ) : (
        editedVideo.tags.map((tag, index) => (
            <Chip key={index} label={tag} style={{ marginRight: '4px',background:"#FFCD59" }} />
          ))
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
      <b>Duración: </b>
        {editing ? (
          <TextField
            fullWidth
            type="number"
            value={editedVideo.duration}
            onChange={(e) => handleChange(e, 'duration')}
          />
        ) : (
          `${video.duration} minutes`
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Intensidad: </b>
        {editing ? (
        <FormControl style={{ width: '45%', display:'flex', flexDirection:'column' }}>
        <Select name="intensityLevel" value={editedVideo.intensityLevel} onChange={(e:any) => handleChange(e, 'intensityLevel')}>
            <MenuItem value="Principiante">Principiante</MenuItem>
            <MenuItem value="Intermedio">Intermedio</MenuItem>
            <MenuItem value="Avanzado">Avanzado</MenuItem>
        </Select>
        </FormControl>
        ) : (
            editedVideo.intensityLevel
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Series ID:</b>
        {editing ? (
        <FormControl style={{ width: '45%', display:'flex', flexDirection:'column' }}>
            <Select name="seriesId" value={editedVideo.seriesId} onChange={(e:any) => handleChange(e, 'seriesId')}>
            {seriesIdList && seriesIdList.map((item:any, index) => (
            <MenuItem key={index} value={item}>
                {item}
            </MenuItem>
            ))}
          </Select>
      </FormControl>
        ) : (
            editedVideo.seriesId
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Series Episode ID:</b>
        {editing ? (
          <TextField
            fullWidth
            type="number"
            value={editedVideo.seriesEpisodeId || ''}
            onChange={(e) => handleChange(e, 'seriesEpisodeId')}
          />
        ) : (
          video.seriesEpisodeId || 'N/A'
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Usuarios Permitidos:</b>
        {editing ? (
            <FormControl className="p-5" style={{ width: '100%' }}>
            <Autocomplete
                style={{ width: '100%' }}
                multiple
                id="tags-standard"
                options={yogaTypes}
                defaultValue={yogaTypes.filter((yogaType) =>
                editedVideo.tags.includes(yogaType.title))}
                getOptionLabel={(option) => option.title}
                onChange={handleTagsChange}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Tags"
                    placeholder="Yoga"
                />
                )}
            />
            </FormControl>
        ) : (
            editedVideo.allowedUsers.map((tag, index) => (
                <Chip key={index} label={tag} style={{ marginRight: '4px' }} />
              ))
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Allowed Subscriptions:</b>
        {editing ? (
          <FormControl className="p-5" style={{ width: '100%' }}>
          <Autocomplete
            style={{ width: '100%' }}
            multiple
            id="subscriptions-standard"
            options={subscriptionList}
            getOptionLabel={(option) => option.planId}
            defaultValue={filterSubscriptionList}
            onChange={handleAllowedSubscriptionsChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Subscripciones"
              />
            )}
          />
        </FormControl>
        ) : (
            editedVideo.allowedSubscriptions.map((tag, index) => (
                <Chip key={index} label={tag} style={{ marginRight: '4px' }} color="primary"/>
              ))
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Video:</b>
           {editedVideo.filePath}
      </Typography>
      {editing ? (
        <div className='flex flex-wrap justify-around py-2 px-5'>
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          Actualizar
        </Button>
        <Button onClick={()=> setEditing(false)} variant="contained" color="error">
          Cancelar
        </Button>
        </div>
      ) : (
        <Button onClick={handleEditClick} variant="outlined">
          Editar
        </Button>
      )}

    </Paper>):(<div className="flex flex-col gap-5 justify-center items-center">
        <Alert severity="success">{message}</Alert>
          <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
        </div>)}
 
      </Box>
    );
}

export default UpdateVideoForm