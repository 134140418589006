import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const SessionCard = ({ type, sessionNumber, format, title, content }) => {

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#EB6E18', // Set your desired secondary color here
      },
    },
  });


  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ maxWidth: 320, zIndex: 10, margin: '16px',bgcolor:`${format ==='INDIVIDUAL' ? '#54989C':'#DD7C00'}`}}>
        <CardContent className='flex flex-col gap-4'>
          <div className="flex gap-2 justify-between">
            <Avatar
              alt={`${type} ${sessionNumber}`}
              src={`../assets/icons/session/session-${format}.webp`}
              sx={{ bgcolor: '#EAE5C8', padding: '5px', width: 56, height: 56  }}
            />
            <h6 className='font-[FoglihtenNo06] text-[#FFFFFF] text-lg font-bold'>{`${type} ${sessionNumber} ${format} - ${title}`}</h6>
          </div>
          <Typography variant="body2">
            <ul className="list-disc pl-4 text-[#FFFFFF] text-[16px] font-[DIN-Condensed] font-bold">
              {content.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default SessionCard;
