import React from 'react'
import './CardBox1.css'

interface CardInfo{
  title:string;
  desc:string;
  url:string;
  imageUrl:string;
  active:boolean;
}

const CardBoxPogram = (cardInfo:CardInfo) => {
  const openInNewTab = (url:string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
  return <>
    <div className='card-box-1 w-[270px] h-[400px] rounded-xl flex flex-col justify-end items-center gap-5'>
        <img src={cardInfo.imageUrl} className='absolute  w-[270px] h-[400px]  -z-10 rounded-xl' alt="" />
        <div className="flex flex-col items-center justify-center gap-2 text-justify z-10 p-5">
            <h6 className='text-white font-[Generica] font-bold'>{cardInfo.title}</h6>
            <p className='text-white'>{cardInfo.desc}</p>

            {cardInfo.active ? <button className='button-29 w-auto z-10' onClick={() => openInNewTab(cardInfo.url)}>Subscribirme</button>:
            <button className='button-30 w-auto z-10'>Proximamente</button>}
        </div>
        
    </div>
  </>
}

export default CardBoxPogram;