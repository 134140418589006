import React from 'react'

interface CardInfo{
    title:string;
    value:number;
    icon:string;
}

const DashboardCard = (info:CardInfo) => {

    
  return (<>
  <div className='flex flex-col justify-center items-center gap-5 shadow-2xl w-[150px] h-[150px] rounded-xl cursor-pointer hover:scale-110 transition-all'>
    <div className='w-12 h-12'>
        <img className='object-fit w-full' src={info.icon} alt="" />
    </div>
    <div className="title">
        <h5 className='text-sm text-black'>{info.title}</h5>
    </div>
  </div>
  </>
  )
}

export default DashboardCard