import React, { useState } from 'react';
import {Alert,Autocomplete,Box,Button,FormControl,InputLabel,TextField,Typography, Chip, Avatar, Grid, Paper, Select, MenuItem } from '@mui/material';
import { Series } from '../Video/VideoData';
import { modalStyle } from '../GeneralData';
import { deleteSerie,deletePromotionSerie } from '../../../../services/series';

  interface Props {
    refreshData: () => void;
    handleClose:() => void;
    series:Series;
  }
const RemoveSeriesForm:React.FC<Props> = ({series,refreshData,handleClose}) =>{

    const [message,setMessage]= useState();
    const [success,setSuccess]=useState(false);

    const handleRemoveSerie= async (serieId:string) =>{
        try {
            const res:any = await deleteSerie(serieId);
            await deletePromotionSerie(series.seriesId);
            setSuccess(true);
        } catch (error:any) {
            setMessage(error)
        }
    }


    const closeModal=()=>{
        handleClose();
    }

    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    }
  return ( 
    <Box sx={modalStyle}>
        {!success ? (<div>
            <div className="title text-center p-5">
            <h5 className='text-2xl'>¿Desesas Eliminar {series.seriesId}?</h5>
        </div> 
        <Paper sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom>
        {series.title}
        </Typography>
            <Grid container justifyContent="center" padding={3}>
                <Grid item>
                <Avatar alt="Thumbnail" src={series.thumbnail} sx={{ width: 150, height: 150 }}/>
                </Grid>
            </Grid>
            <Typography variant="subtitle1" gutterBottom>
                <b>Id:</b>
                {series.id}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                <b>Serie Id:</b>
                {series.seriesId}
            </Typography>
            <Typography variant="body1" gutterBottom>
            <b> Descripción: </b> {series.description}
            </Typography>
    </Paper>
        
        <div className="actionContainer flex flex-wrap gap-5 justify-center items-center p-5">
            <Button variant='contained' color='success' onClick={()=>handleRemoveSerie(series.id)}>Continuar</Button>
            <Button variant='contained' color='error' onClick={closeModal}>Cancelar</Button>
        </div>

        </div>):<div className="flex flex-col gap-5 justify-center items-center">
        <Alert severity="success">Se Elimino de Forma Exitisa</Alert>
          <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
        </div>}

  </Box>
  )
}

export default RemoveSeriesForm