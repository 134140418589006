import React, { useState } from 'react';
import {Alert,Autocomplete,Box,Button,FormControl,InputLabel,TextField,TextareaAutosize,Typography, Chip, Avatar, Grid, Paper, Switch, MenuItem } from '@mui/material';
import { Promotion } from './SeriesData';
import { modalStyle } from '../GeneralData';
import { changeStatusPromotion } from '../../../../services/promotion';
import ProductForm from './ProductForm';
import UpdateProductForm from './UpdateProductForm';
import { updatePromotion } from '../../../../services/promotion';

  interface Props {
    refreshData: () => void;
    handleClose:() => void;
    promotion:Promotion;
  }


const UpdateSeriesForm:React.FC<Props> = ({promotion,refreshData,handleClose})=> {
    // State variable to track whether the promotion is being edited
    const [isEditing, setIsEditing] = useState(false);
    const [success,setSuccess]=useState(false);
    const [message,setMessage]= useState<string>();

    // State variable to hold the edited promotion
    const [editedPromotion, setEditedPromotion] = useState<Promotion>(promotion);
  
    // Function to handle changes in input fields
    const handleChange = (field: keyof Promotion, value: string | number) => {
      setEditedPromotion((prev) => ({
        ...prev,
        [field]: value,
      }));
    };
  
    // Function to handle edit button click
    const handleEditClick = () => {
      setIsEditing(true);
    };
  
    // Function to handle save button click
    const handleSaveClick = async () => {
      // Save the edited promotion (you can implement the logic to save it to the backend)
      const res = await updatePromotion(editedPromotion.id,editedPromotion);
      setMessage('Se actulizo de forma exitosa');
      setSuccess(true);
  
      // Disable editing mode
      setIsEditing(false);
    };


    const handlePromotionStatus=async(serieId:string,status:boolean) =>{
      const res = await changeStatusPromotion(serieId,status);
      setEditedPromotion((prevData) => ({
        ...prevData,
        ['active']: status,
      }));
      alert(res)
    }

    const handleProductList= (list:string[]) =>{
      setEditedPromotion((prevData) => ({
        ...prevData,
        ['listOfProducts']: list,
      }));
    }


    const closeSuccessFully=()=>{
      handleClose();
      refreshData();
  }

  
    return (
     <Box sx={modalStyle}>
          {!success ? ( <Paper sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
            {promotion.title}
            </Typography>
            {isEditing ? (
            <>
                <Grid container justifyContent="center" padding={3}>
                  <Grid item>
                    <Avatar alt="Thumbnail" src={editedPromotion.thumbnail} sx={{ width: 150, height: 150 }}/>
                  </Grid>
                </Grid>
                <TextField
                label="Titulo"
                value={editedPromotion.title}
                onChange={(e) => handleChange('title', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 1 }}
                />
                <TextField
                label="Subtitulo"
                value={editedPromotion.subtitle}
                onChange={(e) => handleChange('subtitle', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 1 }}
                />
                <TextField
                label="Serie ID"
                value={editedPromotion.serieId}
                onChange={(e) => handleChange('serieId', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 1 }}
                />
                <TextField
                label="Stripe Link"
                value={editedPromotion.paymentLink}
                onChange={(e) => handleChange('paymentLink', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 1 }}
                />
                <TextField
                label="Duracion Desc:"
                value={editedPromotion.duration}
                onChange={(e) => handleChange('duration', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 1 }}
                />
                <TextField
                label="Precio"
                value={editedPromotion.price}
                onChange={(e) => handleChange('price', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 1 }}
                />
                <div className="flex flex-wrap py-5 items-center">
                  <Typography variant="body1" gutterBottom>
                    <b>Status de Promocion:</b>
                  </Typography>
                  <Switch 
                    defaultChecked={editedPromotion.active !== null ? editedPromotion.active : false} 
                    color="success"
                    onChange={() => handlePromotionStatus(editedPromotion.serieId ?? '10', editedPromotion.active !==null ? !editedPromotion.active:false)}
                    />
                </div>
                <TextField
                label="Id de Producto"
                value={editedPromotion.productId}
                onChange={(e) => handleChange('productId', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 1 }}
                />
                <Typography variant="body1" gutterBottom>
                  <b>Descripción Inicial:</b>
                </Typography>
                <TextareaAutosize
                    name="shortDescription"
                    aria-label="Short Description"
                    placeholder="Promocion"
                    value={editedPromotion.shortDescription}
                    onChange={(e)=>{
                    handleChange('shortDescription', e.target.value)}
                  }
                    minRows={3}
                    style={{ width: '100%', marginTop: '16px' }}
                />
                <Typography variant="body1" gutterBottom>
                 <b> Descripción: </b>
                </Typography>
                <TextareaAutosize
                    name="longDescription"
                    aria-label="Short Description"
                    placeholder="Resumen"
                    value={editedPromotion.longDescription}
                    onChange={(e)=>{
                      handleChange('longDescription', e.target.value)}
                    }
                    minRows={3}
                    style={{ width: '100%', marginTop: '16px' }}
                />
                <Typography variant="body1" gutterBottom>
                 <b> Descripción Detallada: </b>
                </Typography>
                <TextareaAutosize
                    name="fullDescription"
                    aria-label="description"
                    placeholder="Descripción Detallada"
                    value={editedPromotion.fullDescription}
                    onChange={(e)=>{
                      handleChange('fullDescription', e.target.value)}
                    }
                    minRows={4}
                    style={{ width: '100%', marginTop: '16px' }}
                />
                 <Typography variant="body1" gutterBottom>
                  <b>Lista de Productos:</b>
                </Typography>
                <UpdateProductForm handleListOfProducts={handleProductList} productList={editedPromotion.listOfProducts}/>
                <div className="flex flex-wrap gap-3 justify-center my-5">
                  <Button onClick={handleSaveClick} variant="contained" color="primary" sx={{ mr: 1 }}>
                    Guardar
                  </Button>
                  <Button onClick={() => setIsEditing(false)} variant="outlined" color="primary">
                    Cancelar
                  </Button>
                </div>
            </>
            ) : (
            <>
                <Grid container justifyContent="center" padding={3}>
                    <Grid item>
                    <Avatar alt="Thumbnail" src={editedPromotion.thumbnail} sx={{ width: 150, height: 150 }}/>
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" gutterBottom>
                    <b>Subtitulo:</b>
                    {editedPromotion.subtitle}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Serie ID: </b>{editedPromotion.serieId}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Stripe Link:</b> {editedPromotion.paymentLink}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Status de Promoción: </b>{editedPromotion.active === true ? 'Activado' : editedPromotion.active === false ? 'Desactivada' : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Duración: </b>{editedPromotion.duration}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Precio:</b> {editedPromotion.price}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Producto ID:</b> {editedPromotion.productId}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Descripción-Inicial </b> {editedPromotion.shortDescription}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b> Descripción: </b> {editedPromotion.longDescription}
                </Typography>
                <Typography variant="body1" gutterBottom>
                <b>Descripción Detallada:</b> {editedPromotion.fullDescription}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                    <b>Lista de Productos: </b>
                </Typography>
                {editedPromotion.listOfProducts && editedPromotion.listOfProducts.map((product, index) => (
                    <Chip key={index} label={product} sx={{ mr: 1, mb: 1 }} />
                ))}
                 <Button onClick={handleEditClick} variant="outlined">
                  Editar
                </Button>
                </Box>
            </>
            )}
        </Paper>):(<div className="flex flex-col gap-5 justify-center items-center">
            <Alert severity="success">{message}</Alert>
            <Button variant="contained" color="success" onClick={closeSuccessFully}>Continue</Button>
        </div>)}
       
      </Box>
    );
}

export default UpdateSeriesForm