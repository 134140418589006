import React,{useState} from 'react'
import { Alert,Box,Button,Typography, Chip, Avatar, Grid, Paper } from '@mui/material';
import { modalStyle } from '../GeneralData';
import { ActiveSubscriptionData } from './ActiveSubscriptionData';
import { deleteActiveSubscription } from '../../../../services/series';


interface Props {
    refreshData: () => void;
    handleClose:() => void;
    activeSubscription:ActiveSubscriptionData;
}


const RemoveActiveSubscriptionForm:React.FC<Props> = ({activeSubscription,refreshData,handleClose}) => {


    const [message,setMessage]= useState();
    const [success,setSuccess]=useState(false);
    const date = new Date(activeSubscription.startDate);
    const options: Intl.DateTimeFormatOptions  = {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('es-ES', options);
    const formattedDate = formatter.format(date);


    const handleRemoveActiveSubcription= async (planId:string) =>{
        try {
            const res = await deleteActiveSubscription(planId);
            setSuccess(true);
        } catch (error:any) {
            setMessage(error)
        }
    }


    const closeModal=()=>{
        handleClose();
    }

    const closeSuccessFully=()=>{
        handleClose();
        refreshData();
    }


  return (
    <Box sx={modalStyle}>
    {!success ? <div>
        <div className="title text-center p-5">
        <h5 className='text-2xl'>¿Desesas Eliminar {activeSubscription.planId}?</h5>
    </div>
    <div className="user-info">
        <Paper sx={{ padding: 2}}>
        <Typography variant="h4" gutterBottom textAlign="center">
            <b>Subscripcion: </b>
            {activeSubscription.planId}
        </Typography>
        <Typography variant="body1" gutterBottom>
            <b>Fecha de Inicio: </b>
            {formattedDate}
  
        </Typography>
        <Typography variant="body1" gutterBottom>
            <b>Status: </b>
            {activeSubscription.isActive ? 'Activa':'Desactivada'}
        </Typography>
        </Paper>
    </div>
    <div className="actionContainer flex flex-wrap gap-5 justify-center items-center p-5">
        <Button variant='contained' color='success' onClick={()=>handleRemoveActiveSubcription(activeSubscription.id)}>Continuar</Button>
        <Button variant='contained' color='error' onClick={closeModal}>Cancelar</Button>
    </div>

    </div>:<div className="flex flex-col gap-5 justify-center items-center">
    <Alert severity="success">{message}</Alert>
      <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
    </div>}

</Box>
  )
}

export default RemoveActiveSubscriptionForm