import React,{useState,useRef} from 'react'
import {
    Alert,
    Box,
    Button,
    Card,
    Container,
    Typography,
    TextField, 
    } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import {uploadImage } from '../../../../services/googleCloud';
import LinearProgress from '@mui/material/LinearProgress';
import {TMandalaData } from './MandalaData';
import { addMandala } from '../../../../services/mandalas';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    maxHeight:'600px',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)',
    overflowY:'automatic',
    zIndex:10,
  };


const useStyles = {
    uploadBox: {
        background: "rgba(255, 255, 255, 0.25)",
        boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
        width:'45%',
        maxWidth:600,
        backdropFilter: "blur(4px)",
        borderRadius: "10px",
        border: "1px solid rgba(255, 255, 255, 0.18)",
        transition: "transform 0.3s ease-in-out", // Add a transition for a smooth effect
        '&:hover': {
        transform: 'scale(1.05)', // Example: Scale the box on hover
        // Add additional styles as needed
        },
    },
};


interface Props {
    refreshData: () => void;
}


const AddMandalaForm:React.FC<Props> = ({refreshData}) => {


    const [formData, setFormData] = useState<TMandalaData>({
        name: '',
        serieId: '',
        path: '',
        month: '',
        active:false,
    });
    const [uploading, setUploading] = useState(false);
    const folderName ='Mandalas';
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState<String|null>();
    const [message, setMessage] = useState('');
    const [fileImage, setFileImage] = useState<File|null>(null);
    const hiddenImageFileInput = useRef<any|null>(null)


    const handleChange = (e:any) => {
        const { name, value } = e.target;    
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const handleImageFileUpload = (event:any) => {
        hiddenImageFileInput.current.click();
      };
  
    const handleImageFileChange = (e:any) => {
    const selectedImage = e.target.files[0];
    setFileImage(selectedImage);
    };
    
    const handleImageUpload= async ():Promise<string | undefined>  => {
        try {
            const formData:any = new FormData();
            formData.append('file', fileImage);
            const response = await uploadImage(folderName,formData);
            return response.data;
        } catch (error:any) {
            console.error('Error uploading file:', error);
            setError(error);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setUploading(true);
        try {
            const thumbnailPromise: Promise<string | undefined> = handleImageUpload();
            const thumbnail: string | undefined = await thumbnailPromise;
    
            const mandalaData: TMandalaData = {
                name: formData.name,
                serieId: formData.serieId,
                path: typeof thumbnail === 'string' ? thumbnail : '',
                month: formData.month,
                active: formData.active,
            }
    
            const res:any = await addMandala(mandalaData);
            console.log(res);

            if(res && res.status === 201){
                setSuccess(true);
                setMessage(res.data)
            }else{
                setError(res.response.data)
            }
          
            
        } catch (error) {
            console.log(error);
            setError('Error Agregar');
            // Handle the error appropriately, for example, display an error message to the user
        }finally{
            setUploading(false);
        }
    };

      const isMandalaDataValid = (): boolean => {
        return (
            typeof formData.name === 'string' && formData.name.trim() !== '' &&
            fileImage !== null && 
            typeof formData.serieId === 'string' && formData.serieId.trim() !== ''
        );
    };

      const closeSuccessFully=()=>{
        refreshData();
    }

  return <>
    {uploading ? (<Box sx={modalStyle}>
           <div className={`flex flex-col gap-5 justiy-center items-center p-5`}>
             <div className='is-loading'>
               <img width={64} src="../../assets/icons/Dashboard/fileUpload.gif" alt="" />
             </div>
             <Box sx={{ width: '100%' }}>
               <LinearProgress sx={{height:10,borderRadius: 5}} />
             </Box>
             <Alert severity="info">Subiendo Informacion</Alert>
           </div>
           </Box>):
         (<Box sx={modalStyle} className="overflow-auto">
            {!success ? ( <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Agregar Mandala
                </Typography>
            <div className='flex justify-center items-center'>
                <Card
                sx={useStyles.uploadBox}
                className="flex flex-col justify-start items-center p-3 text-center"
                >
                {fileImage ? null:<p className="p-2">Agregar Imagen </p>}
                <button className="w-fit" onClick={handleImageFileUpload}>
                    <ImageIcon
                    sx={{ fontSize: 64 }}
                    className="cursor-pointer"
                    />
                    <input
                    type="file"
                    name="btn-file"
                    id="btn-file"
                    onChange={handleImageFileChange}
                    accept="image/*"
                    ref={hiddenImageFileInput}
                    className="appearance-none hidden"
                    />
                    {fileImage ? <p>{fileImage.name.length > 20 ? `${fileImage.name.substring(0, 20)}...` : fileImage.name}</p> : null}
                </button>
                </Card>
            </div>
                <form onSubmit={handleSubmit}>
                    <TextField
                    name="name"
                    label="Nombre"
                    value={formData.name}
                    onChange={(e)=>{
                        handleChange(e); 
                    }}
                    fullWidth
                    margin="normal"
                    />
                    <TextField
                    name="serieId"
                    label="Serie Id"
                    value={formData.serieId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    />
                   <TextField
                    name="month"
                    label="Mes"
                    value={formData.month}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    />
                   {error && (<Alert severity="error">{error}</Alert>)}
                   <div className="flex justify-center p-5">
                   <Button type="submit" variant="contained" color="success" disabled={!isMandalaDataValid()}>
                        Agregar Serie
                    </Button>
                    </div>
                </form>
            </Container>):(<div className="flex flex-col gap-5 justify-center items-center">
         <Alert severity="success">{message}</Alert>
           <Button variant="contained" color="success" onClick={closeSuccessFully}>Continuar</Button>
         </div>)}
           
         </Box>)} 
         </>
}

export default AddMandalaForm