import { useDispatch, useSelector } from 'react-redux';
import { getUserInfoByEmail } from '../services/user';
import { getSubscriptionById } from '../services/stripe';
import { Email } from '@mui/icons-material';
import { UserData } from '../components/Admin/Stepper/User/UserData';



interface Item {
    allowedSubscriptions: string[];
}
  
interface SubscriptionStatus {
subcriptionName: string;
active: boolean;
}
  

export async function checkUserMembership(user: UserData): Promise<SubscriptionStatus[]> {
    console.log("checkUserMembership Start");
    const subscriptionList: SubscriptionStatus[] = [];
    try {
      if (user && user.subscriptionPlanId) {
        const hasBasicOrPremiumSubscription = user.subscriptionPlanId.some((subscriptionItem: string) =>
          ['BASICA', 'PREMIUM'].includes(subscriptionItem.trim().toUpperCase())
        );
        console.log(hasBasicOrPremiumSubscription);
        if (hasBasicOrPremiumSubscription) {
          for (const subscription of user.subscriptions) {
            
            if (['BASICA', 'PREMIUM'].includes(subscription.planId.trim().toUpperCase())) {
              try {
                console.log("fetchedSubscription Start");
                const fetchedSubscription = await getSubscriptionById(subscription.id);
                console.log(fetchedSubscription);
                const currentSubscription: SubscriptionStatus = {
                    subcriptionName: subscription.planId,
                    active: fetchedSubscription.status === 'active'
                };
                subscriptionList.push(currentSubscription);
              } catch (error:any) {
                if (error.response && error.response.status === 404) {
                  // Handle 404 (NOT_FOUND) error
                  console.error('Subscription not found:', error.response.data);
                } else {
                  // Handle other errors
                  console.error('Error fetching subscription:', error.message);
                }
              }
            }
          }
        }
      }
    } catch (error) {
      // Handle other potential errors
      console.error('Error checking user membership:', error);
    }
  
    return subscriptionList;
  }


  export const checkAllowUser = async (item: Item, currentUser: { subscriptionPlanId?: string[] }, subcriptionUserList: SubscriptionStatus[] | undefined) => {

    let allowUser = false;
  
    if (item.allowedSubscriptions.includes("FREE")) {
      allowUser = true;
    } else if (currentUser?.subscriptionPlanId) {
      const nonBasicPremiumSubscriptions = currentUser.subscriptionPlanId.filter(
        subscriptionItem => !['BASICA', 'PREMIUM'].includes(subscriptionItem.trim().toUpperCase())
      );
  
      allowUser = nonBasicPremiumSubscriptions.some(subscriptionItem =>
        item.allowedSubscriptions.some(allowedSubscription =>
          subscriptionItem.trim().toUpperCase() === allowedSubscription.trim().toUpperCase()
        )
      );
  
      if (!allowUser && item.allowedSubscriptions.some(subscriptionItem =>
        ['BASICA', 'PREMIUM'].includes(subscriptionItem.trim().toUpperCase())
      )) {
        // Check for active BASICA subscription
        if (item.allowedSubscriptions.includes('BASICA') && subcriptionUserList) {
          allowUser = subcriptionUserList.some(subscriptionStatus =>
            subscriptionStatus.subcriptionName === 'BASICA' && subscriptionStatus.active === true
          );
        }
        
        // Check for active PREMIUM subscription if BASICA subscription check failed
        if (!allowUser && item.allowedSubscriptions.includes('PREMIUM') && subcriptionUserList) {
          allowUser = subcriptionUserList.some(subscriptionStatus =>
            subscriptionStatus.subcriptionName === 'PREMIUM' && subscriptionStatus.active === true
          );
        }
      }
    }
  
    return allowUser;
  };