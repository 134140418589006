import {
    publicRequest,
    publicHeaderRequest,
  } from "../requestMethods/requestMethods";


  async function uploadVideo(folderName,file) {
    return await publicHeaderRequest.post(`/gcp-cloud/upload/${folderName}`,file);
  }

  async function uploadGcpVideo(folderName,file) {
    return await publicHeaderRequest.post(`/gcp-cloud/upload-chunk/${folderName}`,file);
  }

  async function uploadImage(folderName,file) {
    return await publicHeaderRequest.post(`/gcp-cloud/uploadImage/${folderName}`,file);
  }

  async function getFolder(){
    return await publicRequest.get('/gcp-cloud/getfolders')
  }

  export {getFolder,uploadImage,uploadVideo,uploadGcpVideo};