import {
    publicRequest,
  } from "../requestMethods/requestMethods";

export const getAllSeries = async() =>{
    try {
        const res = await publicRequest.get('/series');
        return res;
    } catch (error) {
        return error;    
    }
}

export const getAllActiveSubscriptions = async() =>{
    try {
        const res = await publicRequest.get('/subscriptions/activeSubscriptions');
        return res;
    } catch (error) {
        return error;    
    }
}


export const addSerie = async(serie) =>{
    try {
        const res = await publicRequest.post(`/series`,serie);
        return res;
    } catch (error) {
        return error;    
    }
}
export const addPromotionSerie = async(promotion) =>{
    try {
        const res = await publicRequest.post(`/promotions`,promotion);
        return res;
    } catch (error) {
        return error;    
    }
}

export const deletePromotionSerie = async(serieId) =>{
    try {
        const res = await publicRequest.delete(`/promotions/removePromotion/${serieId}`);
        return res;
    } catch (error) {
        return error;    
    }
}


export const updateStatusSeries= async(seriesId,status) =>{
    try {
        const res = await publicRequest.put(`/series/updateActiveSerie/${seriesId}`,status);
        return res;
    } catch (error) {
        return error;    
    }
}

export const deleteSerie = async(subscriptionId) =>{
    try {
        const res = await publicRequest.delete(`/series/removeSeries/${subscriptionId}`);
        return res;
    } catch (error) {
        return error;    
    }
}

export const addActiveSubscription = async(subscriptionId) =>{
    try {
        const res = await publicRequest.post(`/subscriptions/addActiveSubscription/${subscriptionId}`);
        return res;
    } catch (error) {
        return error;    
    }
}


export const updateActiveSubscription = async(subscriptionId,status) =>{
    try {
        const res = await publicRequest.put(`/subscriptions/updateActiveSubscription/${subscriptionId}`,status);
        return res;
    } catch (error) {
        return error;    
    }
}


export const deleteActiveSubscription = async(subscriptionId) =>{
    try {
        const res = await publicRequest.delete(`/subscriptions/removeActiveSubscription/${subscriptionId}`);
        return res;
    } catch (error) {
        return error;    
    }
}

export const getSeriesById = async(seriesId) =>{
    try {
        const res = await publicRequest.get(`/series/${seriesId}`);
        return res;
    } catch (error) {
        return error;    
    }
}