import React from 'react'
import './SocialMediaBox.css'

const SocialMediaBox = () => {
  return <>
    <section className="social-media-section bg-sb-600 flex flex-wrap gap-5 md:gap-0 p-5 md:p-10 justify-around items-center">
        <div className="media-description w-11/12 md:w-5/12 text-center">
          <h3 className="text-sc-c2 font-[MADE-Bon-Voyage]">Tomas de</h3>
          <h1 className="text-sc-c2 font-bold font-[DIN-Condensed] uppercase">Consciencia</h1>
          <p className="text-sc-c2 text-center md:text-justify">
            En este espacio te invito a que juntos hagamos introspección y
            tomemos consciencia a través de historias, reflexiones y anécdotas.
            Nos estaremos escuchando los días lunes para hacer nuestra
            <b className='text-2xl'> #tomadeconsciencia</b>
          </p>
          <div  id="social-icons" className="flex gap-5 justify-center items-center pt-5">
              <a href="https://instagram.com/sar.asanas?igshid=MzRlODBiNWFlZA==" className='icon-img'><img src="../../assets/icons/Socials/instagram_icn.svg" alt="instagram-icon" /></a>
              <a href="https://www.facebook.com/profile.php?id=100038357469052&mibextid=LQQJ4d" className='icon-img'><img src="../../assets/icons/Socials/facebook_icn.svg" alt="facebook-icon" /></a>
              <a href="https://youtube.com/@sar.asanas3495?si=iLg2xdpmsI2XwglC" className='icon-img'><img src="../../assets/icons/Socials/play.png" alt="youtube-icon" /></a>
              <a href="https://open.spotify.com/show/5ixh2ge9moyf2nfEw0EANH?si=83d3c37b6e904ac4" className='icon-img'><img src="../../assets/icons/Socials/Spotify.svg" alt="spotify-icon" /></a>
          </div>
        </div>
        <div className="border bg-sc-c2 w-11/12  md:w-1  h-1 md:h-3/4"></div>
        <div className="spotify-box w-full md:w-5/12 flex justify-center items-center">
          <iframe 
            id="lista-spotify" 
            src="https://open.spotify.com/embed/show/5ixh2ge9moyf2nfEw0EANH?utm_source=generator"
            width="100%"
            height="152"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
        
    </section>
  </>
}

export default SocialMediaBox