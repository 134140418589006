import React, {useEffect, useState } from 'react'
import CardBox2 from '../CardsBox/CardBox2'
import './SeriesChallengeBox.css'
import {useSelector } from "react-redux";
import {
  getVideosInteractionByUser, getVideoBySerieId,
  getVideosByTag,
} from "../../services/videos";

const series = [
  {
    title: "Abundancia",
    serieId: "Abundancia",
  },
  {
    title: "Ser Tú",
    serieId: "Ser Tu",
  },
  {
    title: "Semana 1",
    serieId: "RETO SEMANA",
  },
];

const SeriesChallengeBox = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [videosList, setVideosList] = useState([]);
    const [userVideosComplete, setUserVideosComplete] = useState([]);
    const [num, setNum] = useState(2);
    const [currentChallenge, setCurrentChallenge] = useState(
      series[num].serieId
    );
    const userId = useSelector((state) => state.user.currentUser.username);

    useEffect(() => {
      getUserVideoInteraction(userId).then(() => {
        getVideoList(series[num].serieId);
      });
    }, [userId]);

    useEffect(() => {
      setCurrentChallenge(series[num].title);
      getVideoList(series[num].serieId);
    }, [num]);

    const getVideoList = (param) => {
      
      switch (param) {
        case "RETO SEMANA":
          getVideosListByTag(param);
          break;
        default:
          getVideosListBySeries(param);
          break;
      }
    };

    const getVideosListBySeries = async (serieId) => {
      try {
        setIsLoading(true);
        const response = await getVideoBySerieId(serieId);
        const data = response.data;
        setVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getVideosListByTag = async (tag) => {
      try {
        setIsLoading(true);
        const response = await getVideosByTag(tag);
        const data = response.data;
        setVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getUserVideoInteraction = async(username) =>{
       try {
         const response = await getVideosInteractionByUser(username);
         const data = response;
         setUserVideosComplete(data);
       } catch (error) {
         console.log(error);
       } finally {
         setIsLoading(false);
       }

    }

    const handleNext= () =>{
      if(num+1 >= series.length){
        setNum(0);
      }else{
        setNum(num+1);
      }
    }

     const handlePrev = () => {
       if (num - 1 < 0) {
         setNum(series.length-1);
       } else {
         setNum(num - 1);
       }
     };


  return (
    <>
      <div className="seriesBox bg-sb-700 rounded-lg h-[360px] w-[350px] p-5 relative top-2">
        <div className="activeChallenge flex flex-col">
          <p className="font-[MADDE-Bon-Voyage] text-center text-2xl uppercase py-5">
            RETOS SEMANAL
          </p>
          <div className="activeChallenge flex flex-row justify-center items-center w-full pb-2">
            {/*<ChevronLeftIcon
              sx={{ fontSize: 40 }}
              className="cursor-pointer"
              onClick={() => handlePrev()}
            />*/}
            {/*<ChevronRightIcon
              sx={{ fontSize: 40 }}
              className="cursor-pointer"
              onClick={() => handleNext()}
            />*/}
          </div>
        </div>
        <div className="classes-box">
          {isLoading
            ? [...Array(3)].map((e, i) => (
                <CardBox2
                  key={"index"+i}
                  title="Clase de Yoga"
                  yogaType={"card"}
                  enable={false}
                  duration={30}
                  level="Principiante"
                  intensity={4}
                  path=""
                  isLoading={isLoading}
                />
              ))
            : videosList.map((item, index) => {
               const videoCompleted = userVideosComplete.some((interaction) => {
                 return interaction.videoId === item.videoId;
               });
                return (
                  <>
                    <CardBox2
                      key={index}
                      title={item.title}
                      yogaType={item.tags[0]}
                      enable={videoCompleted}
                      duration={item.duration}
                      level={item.intensityLevel}
                      intensity={4}
                      path={item.videoId}
                    />
                    <div className="border-1"></div>
                  </>
                );
              })}
        </div>
      </div>
    </>
  );
}

export default SeriesChallengeBox