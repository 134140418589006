import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import VerticalNavBar from '../../../components/Navbar/PlatformNavBar/VerticalNavBar'
import CardBoxVideo from '../../../components/CardsBox/CardBoxVideo'
import {Pagination,Stack} from '@mui/material';
import {
  getAllVideos,
  getAllVideosByPage,
  getVideoBySerieId,
  getVideosByTag,
  getVideosByDuration,
  getVideosByLevel,
  getVideosByBetweenDuration,
} from "../../../services/videos";
import { getSeriesById } from '../../../services/series';
import {useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { checkUserMembership,checkAllowUser } from '../../../utils/checkSubscription';


const VideoLibray = () => {

    const [videosList, setVideosList] = useState([]);
    const [serieInfo,setSerieInfo] = useState();
    const [filterVideosList, setFilterVideosList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCheck, setIsLoadingCheck] = useState(true);
    const [currentUser,setCurrentUser] = useState(useSelector((state) => state.user.currentUser));
    const [allowUserList, setAllowUserList] = useState([]);
    const [subcriptionUserList, setSubscriptionUserList] = useState([]);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramNames = Array.from(urlParams.keys());
    const param = paramNames[0];
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(16);
    const [totalPages, setTotalPages] = useState(0); // You need to fetch and set the total number of pages from the API
    const history = useNavigate();
    const dispatch = useDispatch();

    const getAllVideosList =  async () => {
      try{     
          setIsLoading(true);
          setVideosList([]);
          setFilterVideosList([]);
          const response = await getAllVideosByPage(page,pageSize);
          const responseData = response.data;
          setTotalPages(responseData.totalPages)
          if (responseData && responseData.content && responseData.content.length > 0) {
              // Assuming responseData.content is the array of videos
              const newVideosList = responseData.content;
              setVideosList(prevVideosList => [...prevVideosList, ...newVideosList]);
              setFilterVideosList(prevFilterVideosList => [...prevFilterVideosList, ...newVideosList]);
          } else {
              console.log('No videos found.');
          }
      }catch(error){
          console.log(error);
      }finally{
          setIsLoading(false);
      }
    };

    useEffect(() => {
      if (param === 'all') {
        getAllVideosList();
    }
    }, [page]);

    const handlePageChange = (event, value) => {
      setPage(value - 1);
    };

    const getVideosListBySerie = async (serieId) => {
      try {
        setIsLoading(true);
        
        const response = await getVideoBySerieId(serieId);
        const data = response.data;
        setVideosList(data);
        setFilterVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getVideosListByTag = async (tag) => {
        try {
          setIsLoading(true);
          const response = await getVideosByTag(tag);
          const data = response.data;
          setVideosList(data);
          setFilterVideosList(data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };


    const getVideosListByIntensity = async (level) => {
      try {
        setIsLoading(true);
        const response = await getVideosByLevel(level);
        const data = response.data;
        setVideosList(data);
        setFilterVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };     

    const getVideosListByDuration = async (tag) => {
      try {
        setIsLoading(true);
        const response = await getVideosByDuration(tag);
        const data = response.data;
        setVideosList(data);
        setFilterVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getVideosListByRange = async () => {
      try {
        const paramNames = Array.from(urlParams.keys());
        const minDuration = urlParams.get(paramNames[0]);
        const maxDuration = urlParams.get(paramNames[1]);
        setIsLoading(true);
        const response = await getVideosByBetweenDuration(
        parseInt(minDuration),
          parseInt(maxDuration)
        );
        const data = response.data;
        setVideosList(data);
        setFilterVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };  
     
    const getVideoList=(param,value) =>{
        switch (param) {
          case "all":
            break;
          case "series":
            getVideosListBySerie(value);
            break;
          case "tag":
            getVideosListByTag(value);
            break;
          case "duration":
            getVideosListByDuration(value);
            break;
          case "range":
            getVideosListByRange();
            break;
          case "intensity":
            getVideosListByIntensity(value);
            break;
          default:
            getAllVideosList();
            break;
        }
    }

    const getSeriesDetails = async(seriesId) => {
      try {
        setIsLoading(true);
        const res = await getSeriesById(seriesId);
        console.log(res);
        if(res && res.status === 404){
          setSerieInfo(null);
        }else{
          setSerieInfo(res.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    const handleSearch=(option) =>{
      if(option.length!==0){
        setFilterVideosList(option);
      }else{
        setFilterVideosList(videosList);
      };      
    }

  
    const checkSubscription= async()=>{
      try {
        setIsLoading(true);
        return checkUserMembership(currentUser)
      } catch (error) {
        console.error(error);
      }finally{
        setIsLoading(false);
      }
  
    }

    const handleGoBack = () => {
      history(-1);
    };


     useEffect(() =>{
      const paramNames = Array.from(urlParams.keys());
      const param = paramNames[0];
      const value = urlParams.get(paramNames[0]);
      getSeriesDetails(value);
      getVideoList(param,value);
      checkSubscription()
      .then(subscriptionResult => {
        setSubscriptionUserList(subscriptionResult);
      })
      .catch(error => {
        console.error("Error while fetching subscription:", error);
      });
    },[])
    
    useEffect(() => {
      const fetchData = async () => {    
        try {
          if (!videosList) {
            throw new Error('Videos list is not available');
          }
    
          const updatedAllowUserList = await Promise.all(
            videosList.map(async (item) => {
              return await checkAllowUser(item, currentUser, subcriptionUserList);
            })
          );
    
          setAllowUserList(updatedAllowUserList);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingCheck(false);
        }
      };
    
      fetchData();
    }, [videosList, currentUser,subcriptionUserList]);



      return (
        <>
          <section className="index-platform flex gap-3">
            <VerticalNavBar />
            <div className="flex flex-col justify-start items-center pb-5 sm:px-8 md:px-12 w-full">
              <div className=" flex flex-col justify-center items-center pb-10">
                <div className="image-container w-12 h-20">
                  <img
                    src="../../assets/icons/Logo/Logo-sm.png"
                    alt="Logo-SA"
                  />
                </div>
                <div className="text text-center">
                  <p className="font-[Generica]">
                    "It's not about being good at something. It's about being
                    good to yourself."
                  </p>
                </div>
                <div className="arrow-back-player flex justify-start items-start">
                  <button className="button-30" onClick={handleGoBack}>
                    Regresar
                  </button>
                </div>
              </div>
              <Stack spacing={3} sx={{ width: "90%", padding: "1.75rem 0" }}>
                {isLoading ? null : (
                  <Autocomplete
                    className="border-[1px] rounded-full w-full px-5 py-2 flex justify-center items-center"
                    multiple
                    id="size-small-outlined-multi"
                    options={videosList}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, newValue) => handleSearch(newValue)}
                    renderInput={(params) => (
                      <>
                        <SearchIcon />
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Busqueda"
                        />
                      </>
                    )}
                  />
                )}
              </Stack>
              <div className="library-section flex flex-wrap gap-5 justify-center items-start">
              {isLoadingCheck ? (
                  // If loading, render a placeholder array
                  <div className="flex flex-wrap gap-5 justify-center">
                      {[...Array(8)].map((e, i) => (
                          <CardBoxVideo
                              enable={true}
                              title={""}
                              duration={20}
                              level=""
                              intensity={4}
                              path={""}
                              id={""}
                              imgUrl={"Intermedio"}
                              serie={""}
                              loading={true}
                              key={i}
                              seriesInfo={null}
                          />
                      ))}
                  </div>
              ) : (
                  // If not loading, check if no videos are available
                  !allowUserList.length || !filterVideosList.length ? (
                      // If no videos are available, render the "no videos available" message
                      <div className="flex flex-col justify-center items-center gap-5">
                          <div className="image-container flex flex-col justify center w-24">
                              <img src="../assets/icons/Forms/no-video.gif" alt="not-video" />
                          </div>
                          <h4 className="text-xl text-sb-800 opacity-50 uppercase font-[DIN-Condensed]">
                              No hay video disponibles
                          </h4>
                      </div>
                  ) : (
                      // If videos are available, render the videos
                      filterVideosList.map((item,index) => (
                          <CardBoxVideo
                              enable={currentUser.email==='sara.asanas@gmail.com' ? true:allowUserList[index]}
                              title={item.title}
                              duration={item.duration}
                              level={item.intensityLevel}
                              intensity={4}
                              path={item.thumbnail}
                              id={item.videoId}
                              imgUrl={item.thumbnail}
                              serie={item.tags[0]}
                              loading={false}
                              key={item.videoId}
                              seriesInfo={serieInfo}
                              allowedSubscription={item.allowedSubscription}
                          />
                      ))
                  )
              )}

              </div>

              {param ==='all' && (
                <Stack spacing={2} justifyContent="center" alignItems="center" mt={2}>
                  <Pagination
                      count={totalPages}
                      page={page + 1} // Pagination component starts page numbering from 1, while backend pagination starts from 0
                      onChange={handlePageChange}
                      color="primary"
                  />
              </Stack>
              )}

            </div>
          </section>
        </>
      );
}

export default VideoLibray