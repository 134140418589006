import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  createBrowserRouter,
} from "react-router-dom";
import FrontPage from './pages/FrontPage/FrontPage';
import AboutPage from './pages/About/AboutPage';
import NavBar from './components/Navbar/NavBar';
import Footer from './components/Footer/Footer';
import SubscriptionPage from './pages/SubscriptionPage/SubscriptionPage';
import ContactPage from './pages/ContactPage/ContactPage';
import LoginPage from './pages/Platform/LoginPage/LoginPage';
import RegisterPage from "./pages/Platform/Register/RegisterPage";
import IndexPlatform from './pages/Platform/IndexPlatform/IndexPlatform';
import VideoLibray from './pages/Platform/VideoLibrary/VideoLibray';
import VideoPlayer from './pages/Platform/VideoPlayer/VideoPlayer';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../src/redux/store"
import AppIndex from './AppIndex';

const router = createBrowserRouter([
  {
    path: "/",
    element: <FrontPage />,
    errorElement: <FrontPage />,
  },
  {
    path: "/Un-Poco-de-Mi",
    element: <AboutPage />,
    errorElement: <FrontPage />,
  },
  {
    path: "/subscripciones",
    element: <SubscriptionPage />,
    errorElement: <FrontPage />,
  },
  {
    path: "/contacto",
    element: <ContactPage />,
    errorElement: <FrontPage />,
  },
  {
    path: "/contacto",
    element: <ContactPage />,
    errorElement: <FrontPage />,
  },
  {
    path: "/index",
    element: <IndexPlatform />,
    errorElement: <FrontPage />,
  },
  {
    path: "/registro",
    element: <RegisterPage />,
    errorElement: <FrontPage />,
  },
  {
    path: "/library",
    element: <VideoLibray />,
  },
  {
    path: "/library/:serie-id",
    element: <VideoLibray />,
  },
  {
    path: "video/:videoid",
    element: <VideoPlayer />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <FrontPage />,
    children: [
      {
        path: "index",
        element: <IndexPlatform />,
      },
      {
        path: "library",
        element: <VideoLibray />,
      },
      {
        path: "video/:videoid",
        element: <VideoPlayer />,
      },
    ],
  },
]);



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AppIndex />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

{
  /*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
  <React.Fragment>
    <RouterProvider router={router}/>
  </React.Fragment>
      </PersistGate>
  </Provider>,
);*/
}


