import React, { useState, useEffect,useR } from "react";
import { DataGrid, GridColDef, GridValueGetterParams, esES } from '@mui/x-data-grid';
import {Modal,Chip,CircularProgress} from '@mui/material';
import { getAllLiveVideos } from "../../../services/liveClasses";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';
import RemoveLiveClassesForm from "../Stepper/LiveClasses/RemoveLiveClassesForm";
import UpdateLiveClassForm from "../Stepper/LiveClasses/UpdateLiveClassForm";
import AddLiveClassForm from "../Stepper/LiveClasses/AddLiveClassForm";


const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES, // x-data-grid translations
  );


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };
  
  const renderDateCell = (params) => (
    <p>{formatDate(params.value)}</p>
  );
  
  const renderSubscriptionPlans = (params) => (
    <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
      {params.value.map((plan, index) => (
        <li key={index}>{plan}</li>
      ))}
    </ul>
  );


const LiveClasses = () => {

    const [videosList, setVideosList] = useState([]);
    const [filteredListLiveClasses,setFilteredListLiveClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentLiveClass,setCurrentLiveClass] = useState();
    const [activeOption,setActiveOption] =useState();
    const [error,setError] = useState();
    const [open, setOpen] = useState(false);

    useEffect(()=> {
        getAllVideosList();
      },[])

      const columns = [
        { field: 'title', headerName: 'Titulo', width: 130 },
        { field: 'date', headerName: 'Fecha', width: 130, renderCell: renderDateCell },
        { field: 'startTime', headerName: 'Hora', width: 130 },
        { field: 'allowedSubscriptions', headerName: 'Subscription Plan ID', width: 300, renderCell: (params) => (
          <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
            {params.value.slice(0, 2).map((plan, index) => (
              <Chip key={index} label={`${plan.substring(0, 10)}...`} style={{ marginRight: '4px',background:'#F9AE00' }} />
              ))}
              {params.value.length > 2 && (
                  <Chip key="more" label={`+${params.value.length - 2} mas`} style={{ marginRight: '4px',background:'#F9AE00' }} />
              )}
          </ul>
          ), },
        { field: 'actions', headerName: 'Acciones', width: 300, renderCell: (params) => (
          <div className="flex gap-2">
            <IconButton aria-label="info" size="small" onClick={() =>updateLiveClass(params.row)}>
              <Info />
            </IconButton>
            <IconButton aria-label="remove" size="small" onClick={() =>removeLiveClass(params.row)}>
              <Delete />
            </IconButton>
          </div>
        ), }
      ];

    const getAllVideosList = async () => {
        try {
            setIsLoading(true);
            const response = await getAllLiveVideos();
            const data = response.data;
            setVideosList(data);
            setFilteredListLiveClasses(data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
      };
    
      const removeLiveClass=(liveClass) =>{
      setCurrentLiveClass(liveClass);
        console.log(liveClass);
        handleOpen('remove');
      }
  
      const updateLiveClass=(liveClass) =>{
        setCurrentLiveClass(liveClass);
        handleOpen('update');
      }

      const handleOpen = async (option) => {
        try {
          setOpen(true);
          setActiveOption(option);
        } catch (error) {
          console.error('Error while opening:', error);
        }
      };

      const handleClose = () => {
        setOpen(false)
      };

      const refreshData=()=>{
        handleClose();
        getAllVideosList();
      }

    const handleLiveClassesOption =(action) =>{

      switch (action) {
        case 'add':
          return <AddLiveClassForm handleClose={handleClose} refreshData={refreshData}/>;
        case 'update':
          return <UpdateLiveClassForm liveClass={currentLiveClass} handleClose={handleClose} refreshData={refreshData}/>;
        case 'remove':
          return <RemoveLiveClassesForm liveClass={currentLiveClass} handleClose={handleClose} refreshData={refreshData}/>;
        default:
          return <UpdateLiveClassForm liveClass={currentLiveClass} handleClose={handleClose} refreshData={refreshData}/>;
      }
    }



  return (
    <div style={{ height: 'auto', width: '100%' }}>
        {isLoading ? (
      <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ): error ? (
        <div>Error: {error.message}</div>
      ) : (

        <ThemeProvider theme={theme}>
            <DataGrid
            rows={videosList}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row.classId}
            autoHeight
          />
          </ThemeProvider>

        )}
        <div className="flex justify-center p-5">
          <button className='button-29 w-auto'  onClick={() => handleOpen('add')}>Agregar LiveClass</button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >        
          {handleLiveClassesOption(activeOption)}
        </Modal>
        </div>
    
  </div>
  )
}

export default LiveClasses