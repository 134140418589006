import React,{useEffect,useState} from 'react'
import {Alert,Box,Modal,CircularProgress,Switch} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, esES } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';
import { getAllSeries } from '../../../services/series';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchPromotionsBySeriesId } from '../../../services/promotion';
import PromotionForm from '../Stepper/Series/PromotionForm';
import UpdateSeriesForm from '../Stepper/Series/UpdateSeriesForm';
import RemoveSeriesForm from '../Stepper/Series/RemoveSeriesForm';
import { updateStatusSeries } from '../../../services/series';


const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    esES, // x-data-grid translations
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    maxWidth:'600px',
    maxHeight:'600px',
    overflowY:'automatic',
    color:'black',
    bgcolor: "#FFFFFF",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    p: 2,
    borderRadius: 5,
    backdropFilter:'blur(4px)'
  };

const SeriesTable = () => {

    const [activeOption,setActiveOption] =useState();
    const [seriesList, setSeriesList] = useState([]);
    const [currentSerie,setCurrentSerie] =useState();
    const [currentPromotion,setCurrentPromotion] = useState();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error,setError] = useState();
    const [errorMessage,setErrorMessage] = useState();

    useEffect(()=>{
      getAllSeriesList();
    },[])


  const columns = [
    { field: 'seriesId', headerName: 'Series ID', width: 150 },
    { field: 'title', headerName: 'Reto', width: 400 },
    { 
      field: 'active', 
      headerName: 'Status', 
      width: 150, 
      renderCell: (params) => {
        return <Switch defaultChecked={params.row.active} color="success" onChange={()=>handleStatus(params.row)}/>
    },
    },
    { field: 'actions', headerName: 'Acciones', width: 300, renderCell: (params) => (
      <div className="flex gap-2">
        <IconButton aria-label="info" size="small" onClick={() =>updateSerie(params.row)}>
          <Info />
        </IconButton>
        <IconButton aria-label="remove" size="small" onClick={() =>removeSerie(params.row)}>
          <Delete />
        </IconButton>
      </div>
    ), }
  ];

  const handleClose = () => {
    setOpen(false)  
  };
  
    const getSerieInfo = async(serie) =>{
      setCurrentSerie(serie);
      const res = await fetchPromotionsBySeriesId(serie.seriesId)
      setCurrentPromotion(res);
      return res;
    }


    const handleStatus=async(params) =>{
      const res = await updateStatusSeries(params.id,params.active);
      alert(res.data)
    }

    const getAllSeriesList =  async () => {
        try{     
            setIsLoading(true);
            const response = await getAllSeries();
            const data = response.data;
            setSeriesList(data)
        }catch(error){
            setErrorMessage(error)
        }finally{
            setIsLoading(false);
        }
      };
      


    const handleOpen = async (option) => {
      try {
        setOpen(true);
        setActiveOption(option);
      } catch (error) {
        console.error('Error while opening:', error);
      }
    };

      const removeSerie=(serie) =>{
        setCurrentSerie(serie);
        handleOpen('remove');
      }
  
      const updateSerie=async(serie) =>{
        setErrorMessage('');
        getSerieInfo(serie)
        .then(promotion => {
          if (promotion) {
            handleOpen('update');
          } else {
            setErrorMessage('Error: Serie info not found');
          }
        })
        .catch(error => {
          setErrorMessage('Error: Serie info not found');
        });
        setCurrentSerie(serie);
        
      }



    const refreshData=()=>{
      handleClose();
      getAllSeriesList();
    }

    const handleSeriesOption =(action,promotion) =>{
      switch (action) {
        case 'add':
          return <PromotionForm refreshData={refreshData}/>;
        case 'update':
          return <UpdateSeriesForm promotion={currentPromotion} handleClose={handleClose} refreshData={refreshData}/>;
        case 'remove':
          return <RemoveSeriesForm series={currentSerie} handleClose={handleClose} refreshData={refreshData}/>;
        default:
          return <PromotionForm refreshData={refreshData}/>;
      }

    }

  return (
    <div style={{ height: 'auto', width: '100%' }}>
         {isLoading ? (
      <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ): error ? (
        <div>Error: {error.message}</div>
      ) : (

        <ThemeProvider theme={theme}>
            <DataGrid
            rows={seriesList}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row.seriesId}
            autoHeight
          />
          </ThemeProvider>

        )}
    {errorMessage && (<div className='flex justify-center p-3'><Alert severity="error">{errorMessage}</Alert></div>)}
    <div className="flex flex-wrap gap-5 justify-center p-5">
      <button className='button-29 w-auto'  onClick={() => handleOpen('add')}>Agregar Serie</button>
      <button className='button-30 w-auto'  onClick={() => handleOpen('weekChallenge')}>Reto de la Semana</button>
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >      
             {handleSeriesOption(activeOption)}
          </Modal>
    </div>
  </div>
  )
}

export default SeriesTable