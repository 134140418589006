import React, { useState } from 'react';
import {Alert,Box,Button,Typography, Avatar, Grid, Paper } from '@mui/material';
import { modalStyle } from '../GeneralData';
import { deleteMandala } from '../../../../services/mandalas';
import { MandalaData } from './MandalaData';
import { error } from 'console';

interface Props {
  refreshData: () => void;
  handleClose:() => void;
  mandala:MandalaData;
}

const RemoveMandalaForm:React.FC<Props> = ({mandala,refreshData,handleClose})  => {
  const [message,setMessage]= useState();
  const [success,setSuccess]=useState(false);
  
  const handleRemoveMandala= async (serieId:string) =>{
    try {
        const res:any = await deleteMandala(serieId);
        setSuccess(true);
    } catch (error:any) {
        setMessage(error)
    }
}


const closeModal=()=>{
    handleClose();
}

const closeSuccessFully=()=>{
    handleClose();
    refreshData();
}
return ( 
<Box sx={modalStyle}>
    {!success ? (<div>
        <div className="title text-center p-5">
        <h5 className='text-2xl'>¿Desesas Eliminar {mandala.name}?</h5>
    </div> 
    <Paper sx={{ p: 2 }}>
    <Typography variant="h4" gutterBottom>
    {mandala.name}
    </Typography>
        <Grid container justifyContent="center" padding={3}>
            <Grid item>
            <Avatar alt="Thumbnail" src={mandala.path} sx={{ width: 150, height: 150 }}/>
            </Grid>
        </Grid>
        <Typography variant="subtitle1" gutterBottom>
            <b>Id:</b>
            {mandala.id}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
            <b>Serie Id:</b>
            {mandala.serieId}
        </Typography>
</Paper>
    
    <div className="actionContainer flex flex-wrap gap-5 justify-center items-center p-5">
        <Button variant='contained' color='success' onClick={()=>handleRemoveMandala(mandala.id)}>Continuar</Button>
        <Button variant='contained' color='error' onClick={closeModal}>Cancelar</Button>
    </div>

    </div>):<div className="flex flex-col gap-5 justify-center items-center">
    <Alert severity="success">Se Elimino de Forma Exitisa</Alert>
      <Button variant="contained" color="success"  onClick={closeSuccessFully}>Continuar</Button>
    </div>}

</Box>
)
}

export default RemoveMandalaForm