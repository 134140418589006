import React, { useState,useEffect } from 'react'
import moment from 'moment'


type ChildComponentProps = {
  onDateChange: (date:string | null) => void;
};

const CalendarSlider: React.FC<ChildComponentProps> = ({ onDateChange })=> {
const [today, setToday] = useState(moment().startOf('day').set('hour', 7)); // Set today at 7 am

  useEffect(() => {
    setToday(moment().startOf('day').set('hour', 7)); // Update today at 7 am on component load
  }, []);



const handleSelectDay = (day: number) => {
    const newDate = moment().startOf('day').set('hour', 7).add(day, 'days');
    setToday(newDate);
    handleDateSelection(newDate);
  };

  const handleDateSelection = (date: moment.Moment) => {
    date.locale('es');
    const formattedDate = date.format('YYYY-MM-DD');
    onDateChange(formattedDate);
  };

  /*const setTodayAtCurrentDay = () => {
    const currentDay = moment().startOf('day').set('hour', 7);
    setToday(currentDay);
    handleDateSelection(currentDay);
  };*/
  
  return (<>
    <div className="calendar-slide flex gap-2">
          {[...Array(7)].map((e, i) => {
            var currentDay=false;
            if(moment().add(i, 'days').format('DD-MM') === today.format('DD-MM')){
                currentDay=true;
            }
            return(
                <div key={"calendar index"+i} onClick={() => handleSelectDay(i)} 
                className={`flex flex-col gap-1 cursor-pointer justify-center items-center rounded-full h-fit w-fit p-2 ${currentDay ? 'bg-sb-500 text-sb-100':'bg-sb-100 border-2 text-sb-800'}`}>
                    <p> {moment().add(i, 'days').format('MMM') }</p>
                    <p> {moment().add(i, 'days').format('D') }</p>
                </div>
            )          
            })}

    </div>
  </>
  )
}

export default CalendarSlider