// Import the functions you need from the SDKs you need
import { EventNote } from "@mui/icons-material";
import { initializeApp } from "@firebase/app";
import { getStorage } from "@firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
  apiKey: "AIzaSyB10-Q9I98yqc014S253gJcS6gcwUBzIdM",
  authDomain: "sara-sasanas-cloud.firebaseapp.com",
  projectId: "sara-sasanas-cloud",
  storageBucket: "sara-sasanas-cloud.appspot.com",
  messagingSenderId: "927252345452",
  appId: "1:927252345452:web:0e4e7e0098be746ada5e49"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
