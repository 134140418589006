export function convertUnixTimeToLocalDate(unixTime: number): Date {
    const milliseconds = unixTime * 1000; // Convert seconds to milliseconds
    const date = new Date(milliseconds);  
    return date;
}

export function convertUnixTimeMexicoToLocalDate(unixTime: number): string {
    const milliseconds = unixTime * 1000; // Convert seconds to milliseconds
    const date = new Date(milliseconds);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long', // Change to 'short' or 'numeric' if you want abbreviated month or numeric month
        day: 'numeric'
    };
    return date.toLocaleDateString('es-MX', options); // 'es-MX' for Spanish (Mexico)
}