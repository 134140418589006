import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./SeriesChallengeBox.css";
import {
  getVideosByTag,getVideosInteractionByUser,
  getVideoBySerieId,
} from "../../services/videos";
import CustomizedSlider from "../ProgressBar/ProgressBar";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import './SeriesChallengeSlider.css'


const series = [
  {
    title: "Semanal",
    serieId: "RETO SEMANA",
  },
  {
    title: "Abundancia",
    serieId: "Abundancia",
  },
  {
    title: "Ser Tú",
    serieId: "Ser Tu",
  },
];

const SeriesChallengeSlider = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [videosList, setVideosList] = useState([]);
    const [userVideosComplete, setUserVideosComplete] = useState([]);
    const [progress, setProgress] = useState(0);
    const [num, setNum] = useState(0);
    const [currentChallenge, setCurrentChallenge] = useState(
    series[num].serieId
    );
    const userId = useSelector((state) => state.user.currentUser.username);
    const [style, setStyle] = React.useState({});

    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${progress}%`,
      };

      setStyle(newStyle);
    }, 200);

    useEffect(() => {
      getUserVideoInteraction(userId).then(() => {
        getVideoList(series[num].serieId);
      });
    }, [userId]);

    useEffect(() => {
      setCurrentChallenge(series[num].title);
      getVideoList(series[num].serieId);
      setLineProgress();
    }, [num]);

    const getVideoList = (param) => {
      switch (param) {
        case "RETO SEMANA":
          getVideosListByTag(param);
          break;
        default:
          getVideosListBySeries(param);
          break;
      }
    };

    useEffect(()=>{
      setLineProgress();
    },[videosList,userVideosComplete])

    const handleNext = () => {
    if (num + 1 >= series.length) {
        setNum(0);
    } else {
        setNum(num + 1);
    }
    };

    const handlePrev = () => {
    if (num - 1 < 0) {
        setNum(series.length - 1);
    } else {
        setNum(num - 1);
    }
    };

    const getVideosListBySeries = async (serieId) => {
        try {
        setIsLoading(true);
        const response = await getVideoBySerieId(serieId);
        const data = response.data;
        setVideosList(data);
        } catch (error) {
        console.log(error);
        } finally {
        setIsLoading(false);
        }
    };

    const getVideosListByTag = async (tag) => {
      try {
        setIsLoading(true);
        const response = await getVideosByTag(tag);
        const data = response.data;
        setVideosList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getUserVideoInteraction = async (username) => {
      try {
        const response = await getVideosInteractionByUser(username);
        const data = response;
        setUserVideosComplete(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const setLineProgress = () =>{
      var videosComplete = 0;
      const commonVideoIds = videosList.filter((video1) =>
        userVideosComplete.some((video2) => video2.videoId === video1.videoId)
      );
      videosComplete = commonVideoIds.length;
      setProgress(videosComplete/videosList.length*100)
    }



  return (
    <div>
      <div className="activeChallenge-slider flex flex-row justify-center items-center w-full pb-2">
        {/*<ChevronLeftIcon
          sx={{ fontSize: 40 }}
          className="cursor-pointer"
          onClick={() => handlePrev()}
        />*/}
        <h6 className="font-[DIN-Condensed] uppercase">{currentChallenge}</h6>
        {/* <ChevronRightIcon
          sx={{ fontSize: 40 }}
          className="cursor-pointer"
          onClick={() => handleNext()}
      />*/}
      </div>
      <div className="flex flex-col items-center">
        <div className="image-box w-1/2 md:w-7/12 max-w-[140] max-h-40">
          <img
            className="max-h-40"
            src="../../assets/icons/Mandala/mandala-8.png"
            alt="mandala"
          />
        </div>
        <div className="progress-info p-3 text-justify w-full">
          {isLoading ? (
            <div className="progress">
              <div className="progress-done" style={style}>
                {0}%
              </div>
            </div>
          ) : (
            <div className="progress">
              <div className="progress-done" style={style}>
                {progress.toFixed(0)}%
              </div>
            </div>
          )}

          <p>“La vida es como el yoga, una práctica de todos los días"</p>
        </div>
      </div>
    </div>
  );
};

export default SeriesChallengeSlider;
