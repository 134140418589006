import React from 'react'
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

interface RatingBoxInfo{
  name:string,
  description:string,
  rating:number
}

const RatingBox = (RatingBoxInfo:RatingBoxInfo) => {
    const [value, setValue] = React.useState<number | null>(4);
  return (
    <Card sx={{ maxWidth: 320,zIndex:10 }}>
      <CardContent>
        <div className="flex justify-between">
        <Avatar alt="Remy Sharp" 
        src="../../assets/icons/Position-Orange/yoga-pose-7.png"sx={{ bgcolor: 'orange' }} />
        <Rating
        name="simple-controlled"
        value= {RatingBoxInfo.rating}
        readOnly
      />
        </div>

        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {RatingBoxInfo.name}
        </Typography>
        <Typography variant="body2">
           {RatingBoxInfo.description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default RatingBox