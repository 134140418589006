import React, {useEffect, useState,useRef} from 'react'
import { getFolder,uploadImage,uploadGcpVideo } from '../../../../services/googleCloud';
import {Alert,Box,Button,Card,TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import LinearProgress from '@mui/material/LinearProgress';
import ImageIcon from '@mui/icons-material/Image';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../../../config/firebaseConfig';

interface VideoUploadProps {
    file: File | null;
    folderName: string | null;
}

const modalStyle = {
  position: "relative",
  color:'black',
  bgcolor: "#FFFFFF",
  p: 2,
  borderRadius: 5,
  backdropFilter:'blur(4px)'
};



const useStyles = {
    uploadBox: {
      background: "rgba(255, 255, 255, 0.25)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      width:'45%',
      maxWidth:600,
      backdropFilter: "blur(4px)",
      borderRadius: "10px",
      border: "1px solid rgba(255, 255, 255, 0.18)",
      transition: "transform 0.3s ease-in-out", // Add a transition for a smooth effect
      '&:hover': {
        transform: 'scale(1.05)', // Example: Scale the box on hover
        // Add additional styles as needed
      },
    },
  };

const filter = createFilterOptions<String>();

interface Props {
  handleNext: () => void;
  handleMediaUrl:(videoUrl:string,imgUrl:string) => void // Define the type of handleNext function
}

const VideoUpload:React.FC<Props> = ({ handleNext,handleMediaUrl}) => {
    const [fileUrl,setFileUrl]= useState('');
    const [imgUrl,setImgUrl]= useState('');
    const [folderList, setFolderList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message,setMessage]=useState('');
    const [error,setError] = useState<String|null>();
    const [file, setFile] = useState<File|null>(null);
    const [fileImage, setFileImage] = useState<File|null>(null);
    const [folderName, setFolderName] = useState<String|null>(null);
    const hiddenFileInput = useRef<any|null>(null);
    const hiddenImageFileInput = useRef<any|null>(null)
    const [downloadURL, setDownloadURL] = useState<string>('');

    useEffect(() =>{
        getFolderNames();
    },[])


    const handleGcpUpload = async () => {
        if (!file) {
          console.error('No file selected');
          return;
        }
      
        const chunkSize: number = 25 * 1024 * 1024; // 25MB chunks
        const totalChunks: number = Math.ceil(file.size / chunkSize);
      
        // Create an array of promises to upload each chunk sequentially
        const uploadPromises = Array.from({ length: totalChunks }, (_, index) => {
          const start = index * chunkSize;
          const end = Math.min((index + 1) * chunkSize, file.size);
          const chunk = file.slice(start, end);
      
          const formData = new FormData();
          formData.append('file', chunk);
          formData.append('chunkNumber', String(index + 1));
          formData.append('totalChunks', String(totalChunks));
          formData.append('filename', file.name);
          formData.append('foldeName', String(folderName));
          // Assuming uploadGcpVideo returns a Promise
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              // Assuming uploadGcpVideo returns a Promise
              uploadGcpVideo(folderName, formData)
                .then(res => {
                  console.log(res); // Handle response appropriately
                  setFileUrl(res.data);
                  resolve(); // Resolve the promise after successful upload
                })
                .catch(error => {
                  console.error('Error uploading chunk:', error);
                  resolve(); // Resolve the promise even if there's an error
                });
            }, 1000 * index);
          });
        });
      
        try {
          await Promise.all(uploadPromises);
          console.log('File uploaded successfully');;
        } catch (error) {
          console.error('Error uploading file:', error);
        }
    };

    const handleUpload = () => {
      if (file) {
        setUploading(true)
        const storageRef = ref(storage, folderName +'/' + file.name); // Specify the folder path here
        uploadBytes(storageRef, file)
          .then((snapshot) => {
            console.log("File uploaded successfully");
            return getDownloadURL(storageRef);
          })
          .then((url) => {
            setDownloadURL(url);
            setUploading(false);
            setSuccess(true);
            console.log(url);
          })
          .catch((error) => {
            console.error("Error uploading file", error);
            setError("Error uploading file");
            setUploading(false);
          });
      } else {
        setError("Please select a file before uploading");
      }
    };

    const getFolderNames = async() =>{
      try{     
          setIsLoading(true);
          const response = await getFolder();
          const data = response.data;
          setFolderList(data)
      }catch(error:any){
          console.log(error);
          setError(error)
      }finally{
        setIsLoading(false);
      }
  
      }

      const handleFileUpload = (event:any) => {
        hiddenFileInput.current.click();
      };
  
      const handleFileChange = (e:any) => {
        const selectedVideo = e.target.files[0];
        setFile(selectedVideo);
      };

      const handleImageFileUpload = (event:any) => {
        hiddenImageFileInput.current.click();
      };
  
      const handleImageFileChange = (e:any) => {
        const selectedImage = e.target.files[0];
        setFileImage(selectedImage);
      };
     
      const handleImageUpload = async () => {
        try {
          const formData:any = new FormData();
          formData.append('file', fileImage);
          const response = await uploadImage(folderName,formData);
          setImgUrl(response.data);
        } catch (error:any) {
          console.error('Error uploading file:', error);
          setError(error);
        }
      };

      const resetValues = () => {
        setFolderName('');
        setFile(null);
        setFileImage(null);
      };

      const handleAddVideo = async () => {
        try {
          setUploading(true);
          setError(null);
      
          if (file && file.size > 700 * 1024 * 1024) {
            setMessage('El archivo es demasiado grande. El tamaño máximo permitido es 600 MB.');
            return false;
          }
          // Upload video to GCP and wait for it to finish
          await handleUpload();
          // Once video upload is complete, upload image
          await handleImageUpload();
          setMessage('Subida Exitosa de Media');
          resetValues();
        } catch (error) {
          console.error('Error while adding video:', error);
          setError('Error al agregar el video.');
        }
      };

      const handleContinue= () =>{

        handleMediaUrl(downloadURL,imgUrl);
        handleNext();
      }

  return <div>
     {uploading ? (
     <Box sx={modalStyle}>
          <div className="flex flex-col gap-5 justiy-center items-center p-5">
            <div className='is-loading'>
              <img width={64} src="../../assets/icons/Dashboard/fileUpload.gif" alt="" />
            </div>
            <Box sx={{ width: '100%' }}>
              <LinearProgress sx={{height:10,borderRadius: 5}} />
            </Box>
            <Alert severity="info">Subiendo Informacion</Alert>
          </div>
          </Box>):
          (<div className={`${success ? 'hidden':'block'} flex flex-col justify-center items-center gap-5`}>
            <div className='flex flex-wrap justify-around gap-3'>
            <Card
              sx={useStyles.uploadBox}
              className="flex flex-col justify-start items-center p-3"
            >
              {file ? null:<p className="p-2">Agregar Video</p>}
              <button className="w-fit" onClick={handleFileUpload}>
                <VideoCallIcon
                  sx={{ fontSize: 64 }}
                  className="cursor-pointer"
                />
                <input
                  type="file"
                  name="btn-file"
                  id="btn-file"
                  onChange={handleFileChange}
                  accept="video/*"
                  ref={hiddenFileInput}
                  className="appearance-none hidden"
                />
                {file ? <p>{file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name}</p> : null}
              </button>
            </Card>
            <Card
              sx={useStyles.uploadBox}
              className="flex flex-col justify-start items-center p-3"
            >
              {fileImage ? null:<p className="p-2">Agregar Imagen</p>}
              <button className="w-fit" onClick={handleImageFileUpload}>
                <ImageIcon
                  sx={{ fontSize: 64 }}
                  className="cursor-pointer"
                />
                <input
                  type="file"
                  name="btn-file"
                  id="btn-file"
                  onChange={handleImageFileChange}
                  accept="image/*"
                  ref={hiddenImageFileInput}
                  className="appearance-none hidden"
                />
                {fileImage ? <p>{fileImage.name.length > 20 ? `${fileImage.name.substring(0, 20)}...` : fileImage.name}</p> : null}
              </button>
            </Card>
             </div>
            <Autocomplete
                value={folderName}
                onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setFolderName(newValue);
                        } else if (newValue) {
                        // Create a new value from the user input
                        setFolderName(newValue);
                        } else {
                            setFolderName(newValue);
                        }
                }}
                filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue);
                }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="folderName"
                options={folderList}
                getOptionLabel={(option:any) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option) {
                    return option;
                    }
                    // Regular option
                    return option;
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label="Nombre de Folder" />
                )}
            />
            <Button className="w-fit p-5 text-white flex gap-2" variant="contained" color="success" onClick={handleAddVideo} disabled={!file || !folderName || !fileImage}>
                <UploadFileIcon/>
                Subir Video
            </Button>
            </div>)
      }
      {error && (<Alert severity="error">{error}</Alert>)}
      {success && (
      <div className="flex flex-col gap-5 justify-center items-center">
        <Alert severity="success">{message}</Alert>
          <Button variant="contained" color="success"  onClick={handleContinue}>Continuar</Button>
        </div>) 
        }    
        </div>;
};

export default VideoUpload;