import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import NavBar from '../../components/Navbar/NavBar';
import "./PromoPageStyle.css"
import SocialMediaBox from '../../components/SocialMediaBox/SocialMediaBox';
import Footer from '../../components/Footer/Footer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import {sessionsOne,sessionsGroup} from './DataSession';
import SessionCard from './SessionCard';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getOrderCountByPlanId } from '../../services/order';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme({
    palette: {
      secondary: {
        main: '#EB6E18', // Set your desired secondary color here
      },
    },
  });

function PromoPage() {
    const [value, setValue] = useState('1');
    const [available,setAvailable] =useState(10);
    const [openDialog, setOpenDialog] = useState(false);
    const [orderCount, setOrderCount] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');


    useEffect(() => {
      const planId = 'RETOMA-ENCIENDE-ACTIVA';
      fetchOrderCount(planId);
    }, []); 

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const fetchOrderCount = async (planId: string) => {
      try {
        setIsLoading(true);
        const count = await getOrderCountByPlanId(planId);
        const countAp = await getOrderCountByPlanId('RETOMA-ENCIENDE-ACTIVA-AP');
        console.log(count);
        setOrderCount(count);
        setAvailable(10-count-countAp)
        return count;
      } catch (error) {
        console.error('Error fetching order count:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleSubscriptionClick = async (url: string) => {
      try {
        const orderCount = await getOrderCountByPlanId('RETOMA-ENCIENDE-ACTIVA');
        const orderCountAp = await getOrderCountByPlanId('RETOMA-ENCIENDE-ACTIVA-AP');
        if ((orderCount+orderCountAp) <= 10) {
          window.open(url, '_blank', 'noopener,noreferrer');
        } else {
          console.log('Product not available');
        }
      } catch (error) {
        console.error('Error checking availability:', error);
      }
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    return (
    <>
    <NavBar/>
    <section className="hero-promo relative flex flex-wrap gap-5 lg:gap-0 justify-center lg:justify-between items-center lg:items-start">
        <div className="flex flex-col gap-4 justify-start pt-5 md:pt-14 xl:pt-20 px-5 md:pl-20 w-full lg:w-[55%] xl:w-1/2">
        <h6 className='font-[DIN-Condensed] font-bold uppercase text-sb-500 italic text-2xl'>6 semanas del 22 de ENERO al 25 FEBRERO</h6>
        <div className="flex font-[DIN-Condensed] font-bold uppercase">
          <h1 className='text-so-300 text-[48px] leading-10'>PROGRAMA RETOMA, ENCIENDE Y ACTIVA  TU PODER </h1>
        </div>
        <div className="flex justify-center w-full sm:hidden ">
          <div className="h-[300px] w-[300px] mt-0 mr-0 lg:mr-[50px] rounded-full overflow-hidden">
            <img
              className="hero-image promo-img"
              src={"../../assets/images/Promo/Promo_2.webp"}
              alt="Slide-1"
            />
          </div>
        </div>
        <div className="flex flex-col">
        <p className="font-[Avenir] italic font-bold text-2xl">$5,555.00mxn</p>
        <p className="italic">
          {available >= 0 ? `Cupo Limitado a 10 personas - * Opción a 2 pagos hasta el 10 de Enero.`:
          'Cupo Limitado: AGOTADO'}
        </p>
        </div>
        <div className="text-content hidden sm:block">
            <p className="text-black text-md font-[Avenir] text-justify">
            Se parte de la primera generación de este programa transformador que he diseñado a partir de mi experiencia individual y profesional como psicóloga y lo que a mi me hubiera gustado encontrar en un solo programa.
Vamos a ir juntas pero al mismo tiempo vamos atender lo individual y personal haciendo el trabajo de adentro hacia afuera.
            </p>
        </div>
        <div className="flex flex-wrap gap-5 pb-10 sm:pb-0">
          {available >= 0 ? <>
            <Link to={`https://buy.stripe.com/3cs4gV9A39dZbMk007`}>
              <button className='button-30 w-fit p-5 rounded-full'>Comprar</button>
            </Link>
            <Link to={`https://buy.stripe.com/8wM28NfYreyj5nW14d`}>
             <button className='button-29 w-fit p-5 rounded-full'>Apartar Mi Lugar</button>
            </Link>
          </>:null}
            
        </div>
        </div>
        <div className="hidden sm:block h-[300px] md:h-[350px] lg:h-[400px] xl:h-[500px] w-[300px] md:w-[350px] lg:w-[400px] xl:w-[500px] mt-0 lg:mt-48 xl:mt-32 mr-0 lg:mr-[50px] rounded-full overflow-hidden">
          <img
            className="hero-image promo-img"
            src={"../../assets/images/Promo/Reto-Enc-Activa/Reto-Enc-Activa-2.jpg"}
            alt="Slide-1"
          />
        </div>
    </section>
    <section className='pt-20 px-10 flex flex-col gap-4'>
        <div className="flex justify-left font-[DIN-Condensed] font-bold uppercase">
            <h1 className='text-[48px] leading-10'>Curso Transformador de 6 Semanas</h1>
        </div>
        <p className='italic text-gray-700 text-lg'>Teórico - Práctico</p>
        <p className='font-[Avenir] text-justify text-md sm:text-xl'>Durante estas semanas, iremos trabajando juntas. Iremos alternando las llamadas grupales con las sesiones individuales para pasar de lo general y colectivo que vivimos y compartimos a lo particular y más personal. 
Vamos a RETOMAR, ACTIVAR Y ENECENDER juntas nuestro PODER y crear una vida diferente en este 2024! 
¿Estás lista para ser la causa y no el efecto de tu vida?</p>
    </section>
    <section className='flex justify-left pt-10 pl-2 sm:pl-10'>
        <ThemeProvider theme={theme}>
        <Box sx={{ width: isMobile ? '100%%' : '90%', typography: 'body1'}}>
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary"> 
                <Tab label="Descripción" value="1" sx={{ '&.Mui-selected': { color: '#EB6E18'}}} />
                <Tab label="Qué Incluye" value="2" sx={{ '&.Mui-selected': { color: '#EB6E18',borderBottom: '2px solid #EB6E18', } }} />
                </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: isMobile ? '0px' : '24px' }}>
                <div className="flex flex-wrap justify-center">
                <h3 className='w-full text-center md:text-left font-[DIN-Condensed] text-3xl uppercase'>Sesiones Individuales</h3>
                {sessionsOne.map((session, index) => (
                    <SessionCard key={index} {...session} />
                ))}
                </div>
                <div className="flex flex-wrap justify-center">
                <h3 className='w-full text-center md:text-left font-[DIN-Condensed] text-3xl uppercase'>Sesiones Grupales</h3>
                {sessionsGroup.map((session, index) => (
                    <SessionCard key={index} {...session} />
                ))}
                </div>
                </TabPanel>
            <TabPanel value="2">
            <ul className="list-disc pl-4">
                <li>3 sesiones online 1:1 de 50min</li>
                <li>3 llamadas grupales</li>
                <li>6 meditaciones de reprogramación del subconsciente (una por semana)</li>
                <li>Workbook para trabajar tu relación con tu dinero</li>
                <li>Comunidad para conectar con otras personas que están en el mismo camino y sostenernos juntos</li>
                <li>Acceso al contenido por 3 meses</li>
            </ul>
            <p>BONUS (si te inscribes en diciembre): 1 mes de regalo a las clases de la plataforma</p>
            </TabPanel>
            </TabContext>
        </Box>
        </ThemeProvider>
    </section>
    <SocialMediaBox/>
    <Footer/>
    <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>AGOTADO</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lo lamenentamos el grupo se a completado, te invitamos estar al pendiente de nuestras redes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
    );
}

export default PromoPage