import React,{useState,useEffect} from 'react'
import CardBox3 from '../CardsBox/CardBox3';
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from 'react-router-dom';
import { getAllSeries } from '../../services/series';
import CircularProgress from '@mui/material/CircularProgress';

const SliderSereiesIndex = () => {

    const [seriesList,setSeriesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getSeriesList = async () =>{
        try{    
            console.log('start'); 
            setIsLoading(true);
            const response = await getAllSeries();
            console.log(response); 
            const data = response.data;
            
            setSeriesList(data)
        }catch(error){
            console.log(error);
        }finally{
            setIsLoading(false);
        }
    }

    useEffect(() =>{
        getSeriesList();
    },[]);


    return (
        <>
        {isLoading ? <CircularProgress />:(<Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={2}
            centeredSlides={true}
            pagination
            initialSlide={0}
            breakpoints={{
              600: {
                slidesPerView: 3,
                spaceBetween: 10,
                centeredSlides: false,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: false,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 30,
                centeredSlides: false,
              },
            }}
            className="mySwiper w-full max-w-[90vw] h-[auto] flex justify-center items-center md:justify-start"
          >
            {seriesList && seriesList.map((item, index) => (
                <SwiperSlide className="flex flex-col justify-center items-center text-center gap-1" key={index}>
                    {item.active ? (
                    <Link to={`/seriesDetails/searchBy?series=${item.seriesId}`} className="flex flex-col justify-center items-center text-center gap-1" >
                        <div className="image-container flex justify-center items-center cursor-pointer">
                        <img src={item.thumbnail} alt={item.seriesId} />
                        </div>
                        <p className="hidden md:block uppercase font-[MADE-Bon-Voyage] text-xl">{item.title}</p>
                    </Link>
                    ) : (
                    <div className="flex flex-col justify-center items-center text-center gap-1">
                        <div className="image-container next flex justify-center items-center">
                            <img src={item.thumbnail} alt={item.seriesId} />
                            <p>Próximamente</p>
                        </div>
                        <p className="hidden md:block uppercase font-[MADE-Bon-Voyage] text-xl">{item.title}</p>
                    </div>
                    )}
                </SwiperSlide>
            ))}
          </Swiper>)}
          
        </>
      );
}

export default SliderSereiesIndex