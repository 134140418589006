import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export default function CheckoutForm({sendPaymentStatus}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(true);


  const handleSuccessPayment = () => {
    sendPaymentStatus(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/liveClasses`,
        receipt_email: email,
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment status:" + paymentIntent.status + "🎖");
      handleSuccessPayment();
      console.log(paymentIntent);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  const handleEmailChange = (e) => {
    console.log("handleEmailChange called");
    const inputValue = e.target.value.toLowerCase();
    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the input value matches the email pattern
    const isValid = emailPattern.test(inputValue);
    setEmail(inputValue);
    setValid(isValid);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <TextField
        label=""
        error={!valid}
        helperText={!valid ? "Correo Invalido" : ""}
        id="outlined-start-adornment"
        sx={{ m: 1, width: "25ch" }}
        value={email}
        onChange={handleEmailChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">Correo</InputAdornment>
          ),
        }}
      />
      <PaymentElement id="payment-element" />
      <div className="flex justify-center">
        <button
          className="button-29"
          disabled={isProcessing || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isProcessing ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
