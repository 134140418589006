import React,{useEffect,useState} from 'react'
import {Alert,Box,Button,Card,CardMedia,Typography, InputLabel, FormControl,TextField, Select, MenuItem} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { addVideo } from '../../../../services/videos';
import { getAllSeries,getAllActiveSubscriptions } from '../../../../services/series';
import { yogaTypes } from '../../../../Data/YogaTypes';
import { getAllMandalas } from '../../../../services/mandalas';
import { MandalaData } from '../Mandalas/MandalaData';
import { Series } from './VideoData';




  interface VideoFormData {
    videoId: string;
    title: string;
    description: string;
    tags: string[];
    duration: number;
    intensityLevel: string;
    seriesId: string;
    seriesEpisodeId: number | null;
    subscriptionRequired: boolean;
    allowedUsers: string[];
    allowedSubscriptions: string[];
    filePath: string;
    thumbnail: string;
  }

  interface Props {
    videoUrl:string;
    imgUrl:string;
    handleNext: () => void; // Define the type of handleNext function
  }

const StepInformationForm:React.FC<Props> = ({handleNext,videoUrl,imgUrl}) => {

    const initialFormData:VideoFormData = {
        videoId: '',
        title: '',
        description: '',
        tags: [],
        duration: 0,
        intensityLevel: '',
        seriesId: '',
        seriesEpisodeId: null,
        subscriptionRequired: true,
        allowedUsers: [],
        allowedSubscriptions: [],
        filePath: '',
        thumbnail: '',
      };
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [seriesList, setSeriesList] = useState([]);
    const [success,setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingModal, setIsLoadingModal] = useState(true);
    const [seriesIdList,setSeriesIdList]=useState<String[]>([]);
    const [uploading, setUploading] = useState(false);
    const [uploadComplete,setUploadComplete] = useState(false);
    const [error,setError] = useState<String|null>();
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [largeFile,setLargeFile] = useState(false);

    const modalStyle = {
      position: "relative",
      color:'black',
      bgcolor: "#FFFFFF",
      p: 2,
      borderRadius: 5,
      backdropFilter:'blur(4px)',
      display: success ? 'none':'block'
    };
  

    const handleOpen = async () => {
        try {
          setOpen(true);
          await Promise.all([
            getSubscription(),
            getSeriesList(),
          ]);
        } catch (error) {
          console.error('Error while opening:', error);
        }
      };
  
      const handleClose = () => {
        setOpen(false)
        resetValues();
      
      };

      useEffect(()=>{
          getSubscription();
          createSeriesIdList();
      },[])
    
      const resetValues = () => {
        setFormData(initialFormData);
        setSubscriptionList([]);
      };
      const handleInputChange = (e:any) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      
      const handleTagsChange = (e: any, values: { title: string }[]) => {
        const selectedTags = values.map((value) => value.title);
        setFormData((prevData:any) => ({
          ...prevData,
          tags: selectedTags,
        }));
      };
      
      const handleAllowedSubscriptionsChange = (e: any, values: { planId: string }[]) => {
        const selectedPlanIds = values.map((value) => value.planId);
        setFormData((prevData:any) => ({
          ...prevData,
          allowedSubscriptions: selectedPlanIds,
        }));
      };
    
      const getSubscription = async() =>{
        try{     
          setIsLoadingModal(true);
          const response:any = await getAllActiveSubscriptions();
          const data:any = response.data;
          setSubscriptionList(data);
        }catch(error:any){
            console.log(error);
            setError(error)
        }finally{
          setIsLoadingModal(false);
        }
  
      }
  
      const getSeriesList = async() =>{
        try{     
          setIsLoadingModal(true);
          const response:any = await getAllSeries();
          const data:any = response.data;
          return data;
        }catch(error:any){
            console.log(error);
            setError(error)
        }finally{
          setIsLoadingModal(false);
        }
  
      }


      const getMandalaList = async() =>{
        try{     
          setIsLoadingModal(true);
          const response:any = await getAllMandalas();
          const data:any = response.data;
          return data;
        }catch(error:any){
            console.log(error);
            setError(error)
        }finally{
          setIsLoadingModal(false);
        }
  
      }

      const createSeriesIdList = async ()=>{
        const series:Series[] = await getSeriesList();
        const mandalas:MandalaData[] = await getMandalaList();
        setSeriesIdList([]);
        series.forEach(item => {
          setSeriesIdList(prevList => [...prevList, item.seriesId]);
        });
  
        mandalas.forEach(item => {
          setSeriesIdList(prevList => [...prevList, item.serieId]);
        });
      }
    
      const areInputsFilled = () => {
        return (
          formData.title.trim() !== '' &&
          formData.description.trim() !== '' &&
          formData.duration > 0 &&
          formData.seriesId !== '' &&
          formData.allowedSubscriptions.length > 0 &&
          subscriptionList.length > 0
        );
      };
  
      const createVideoData = (
        formData: VideoFormData,
        videoUrl: string,
        imgUrl: string
      ): VideoFormData => {
        const normalizedTitle = formData.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^\w\s]/gi, '')
          .replace(/\s+/g, '');
      
        const newVideoId = `${formData.seriesId}-${normalizedTitle}`;
      
        return {
          videoId: newVideoId,
          title: formData.title,
          description: formData.description,
          tags: formData.tags,
          duration: formData.duration,
          intensityLevel: formData.intensityLevel,
          seriesId: formData.seriesId,
          seriesEpisodeId: formData.seriesEpisodeId ? formData.seriesEpisodeId : null,
          subscriptionRequired: formData.subscriptionRequired,
          allowedUsers:[],
          allowedSubscriptions: formData.allowedSubscriptions,
          filePath: videoUrl,
          thumbnail: imgUrl,
        };
      };
  
      const handleAddVideo = async () => {
        try {
          setUploading(true);
          setSuccess(false);
          setError(null);
          const videoData = createVideoData(formData,videoUrl,imgUrl)
          console.log(videoData);
          addVideo(videoData);
          setMessage('Información Agregada de Forma Exitoss');
          setSuccess(true);
          resetValues();
        } catch (error:any) {
          console.error('Error while opening:', error);
          setError(error);
        } finally {
          setUploading(false);
        }
      };

  return <>
   {uploading ? (<Box sx={modalStyle}>
          <div className={`flex flex-col gap-5 justiy-center items-center p-5`}>
            <div className='is-loading'>
              <img width={64} src="../../assets/icons/Dashboard/fileUpload.gif" alt="" />
            </div>
            <Box sx={{ width: '100%' }}>
              <LinearProgress sx={{height:10,borderRadius: 5}} />
            </Box>
            <Alert severity="info">Subiendo Informacion</Alert>
          </div>
          </Box>):
        (<Box sx={modalStyle}>
          <div className={`${success ? 'hidden':'block'} flex flex-col justify-center items-center text-center p-5 gap-3`}>
            <Typography variant="h5">Información de Video</Typography>

          </div>
          <Card sx={{ maxWidth: 345 }} className='relative m-auto'>
              <CardMedia
                sx={{ height: 180 }}
                image={imgUrl ? imgUrl:"../../../../assets/images/Portadas/Video-P-5.jpg"}
                title="imagen-Video"
              />
            </Card>
          <form className='flex flex-wrap gap-3 justify-start py-5'>
          <TextField className={`${isLoadingModal ? 'hiddem':'block'}`} label="Titulo" name="title" value={formData.title} onChange={handleInputChange} style={{ width: '100%' }} />
          <TextField label="Descripción" name="description" value={formData.description} onChange={handleInputChange} style={{ width: '100%' }} />
  
          <FormControl className="p-5" style={{ width: '100%' }}>
            <Autocomplete
              style={{ width: '100%' }}
              multiple
              id="tags-standard"
              options={yogaTypes}
              getOptionLabel={(option) => option.title}
              onChange={handleTagsChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Tags"
                  placeholder="Yoga"
                />
              )}
            />
          </FormControl>
  
          <TextField label="Duration" type="number" name="duration" value={formData.duration}  InputProps={{ inputProps: { min: 0, max: 100, step: 5 }}} onChange={handleInputChange} style={{ width: '45%' }} />
          <FormControl style={{ width: '45%' }}>
        <InputLabel>Intensidad</InputLabel>
        <Select name="intensityLevel" value={formData.intensityLevel} onChange={handleInputChange}>
            <MenuItem value="Principiante">Principiante</MenuItem>
            <MenuItem value="Intermedio">Intermedio</MenuItem>
            <MenuItem value="Avanzado">Avanzado</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: '45%' }}>
          <InputLabel>Series ID</InputLabel>
          <Select name="seriesId" value={formData.seriesId} onChange={handleInputChange}>
          {seriesIdList && seriesIdList.map((item:any, index) => (
            <MenuItem key={index} value={item}>
                {item}
            </MenuItem>
            ))}
            </Select>
        </FormControl>
          <TextField label="Episodio" type="number" InputProps={{ inputProps: { min: 1, max: 100 } }} name="seriesEpisodeId" value={formData.seriesEpisodeId || 0} onChange={handleInputChange} style={{ width: '45%' }} />
          <FormControl className="p-5" style={{ width: '100%' }}>
            <Autocomplete
              style={{ width: '100%' }}
              multiple
              id="subscriptions-standard"
              options={subscriptionList}
              getOptionLabel={(option:any) => option.planId}
              onChange={handleAllowedSubscriptionsChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Subscripciones"
                  placeholder="Subscripciones"
                />
              )}
            />
          </FormControl>
          </form>
          <Button variant="contained" color="primary" onClick={handleAddVideo} disabled={!areInputsFilled()} >
            Subir Video
          </Button>
          <Typography variant="body1" style={{ marginTop: '10px' }}>{message}</Typography>
        </Box>)}
        {success && (
          <div className="flex flex-col gap-5 justify-center items-center">
        <Alert severity="success">{message}</Alert>
          <Button variant="contained" color="success"  onClick={handleNext}>Continuar</Button>
        </div>) 
        }   
        </>
}

export default StepInformationForm