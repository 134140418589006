import React,{useState,useEffect} from 'react'
import CardBox3 from '../CardsBox/CardBox3';
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./SliderCardsChallenge.css"
import { Link } from 'react-router-dom';
import { getAllMandalas } from '../../services/mandalas';

const Mantras =[
  {
      name:'Abundancia',
      serieId:'Abundancia',
      path:'../../assets/images/Mantras/AGOSTO.jpg',
      month:'Agosto',
      active:true
  },
  {
      name:'Ser tú',
      serieId:'Ser Tu',
      path:'../../assets/images/Mantras/SEPTIEMBRE.jpg',
      month:'Septiembre',
      active:true
  },
   {
      name:'Oneness',
      serieId:'Oneness',
      path:'../../assets/images/Mantras/OCTUBRE.jpg',
      month:'Octubre',
      active:true
  },
  {
      name:'Naturaleza',
      serieId:'Naturaleza',
      path:'../../assets/images/Mantras/NOVIEMBRE.jpg',
      month:'Noviembre',
      active:true
  },
  {
      name:'Divinidad',
      serieId:'Divinidad',
      path:'../../assets/images/Mantras/DICIEMBRE.jpg',
      month:'Diciembre',
      active:true
  },
]


const SliderMandalas = () => {
  const [mandalasList,setMandalasList]=useState([]);
  const [isLoading,setIsLoading]=useState(true);


useEffect(()=>{handleMandalasList()},[])


const handleMandalasList = async()=>{
  const res = await getAllMandalas();
  setMandalasList(res.data);

}


  return (
    <>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={2}
        centeredSlides={true}
        pagination
        initialSlide={0}
        breakpoints={{
          600: {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 30,
            centeredSlides: false,
          },
        }}
        className="mySwiper w-full max-w-[90vw] h-[280px] flex justify-center items-center md:justify-start"
        onSwiper={(swiper) => console.log(swiper)}
      >
        {mandalasList.map((item,index) =>{
                        return <SwiperSlide className="mantra-box flex flex-col justify-center items-center text-center gap-1" key={index}>
                            {item.active ? (<Link to={`/seriesDetails/searchBy?series=${item.serieId}`}>
                            <div className="image-container">
                                <img src={item.path} alt={item.name} />
                            </div>
                        </Link>):(<div className="image-container next flex justify-center items-center">
                            <img src={item.path} alt={item.name} />
                            <p>Próximamente</p>
                            </div>)}
                        <p className='uppercase font-[MADE-Bon-Voyage] text-xl'>{item.name}</p>
                        <p className='uppercase font-[MADE-Bon-Voyage] text-lg'>{item.month}</p>
                    </SwiperSlide>
        })}
      </Swiper>
    </>
  );
}

export default SliderMandalas;