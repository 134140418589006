import React from 'react'
import './CardBoxVideo.css'
import { Link } from 'react-router-dom';

interface Series {
    id: string;
    seriesId: string;
    title: string;
    thumbnail: string;
    description: string;
    listOfVideos: string[] | null;
    intensityLevel: string;
    yogaPositionsCovered: string[];
    active: boolean;
    payementLink: string;
  }


interface CardInfo{
    title:string;
    enable:boolean;
    duration:number;
    level:string;
    intensity:number;
    serie:string;
    seriesInfo:Series|null;
    allowSubscription:string[]|null
    path:string;
    id:string;
    imgUrl:string;
    loading:boolean;
}



const CardBoxVideo = (Info:CardInfo) => {
    const openInNewTab = (url:string) => {
        if(!Info.allowSubscription?.includes('BASICA')){
            window.open('https://buy.stripe.com/7sI8xb6nRbm73fO28j', '_blank', 'noopener,noreferrer');
        }else{
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };
  return <>
    <div className={`video-card-box ${Info.loading ? 'is-loading':''} flex flex-col gap-2 relative`}>
        <div className={`${Info.enable ? "hidden":"absolute"} block-card flex flex-col justify-center items-center gap-5`}>
            <img src="../../assets/icons/Forms/Lock-icon.svg" alt="Lock-icon" />
            <button className='button-2' onClick={() => openInNewTab(Info.seriesInfo ? Info.seriesInfo.payementLink:'https://buy.stripe.com/3csfZDh2v61N03CaEO')}>Desbloquear</button>
        </div>
        <Link to={`/video/${Info.id}`}>
        <div className={`${Info.loading ? 'is-loading':''} image-box`}>
            <img src={Info.path ? Info.path:"../../assets/images/Portadas/Video-P-1.jpg"} alt="" />
        </div>
        </Link>
        <div className='flex flex-col gap-1 items-start justify-start pl-2'>
            <p className='sub-title font-[MADE-Bon-Voyage] text-lg text-left'>{Info.title}</p>
            <p className='sub-title font-[MADE-Bon-Voyage] text-sm'>{Info.serie ? Info.serie:''} </p>
        </div>
        <div className="video-spec flex gap-1 h-fit pl-1 py-1">
            <div className="icon-box flex gap-1">
                <img src="../../assets/icons/Forms/reloj.svg" alt="clock-icon" />
                <p className='font-[Generica]'>{Info.duration + ' Min'}</p>
            </div>
            <div className="vertical-border"></div>
            <div className="icon-box flex gap-1">
                <p className='font-[Generica]'>Nivel:</p>
                <p className='font-[Generica]'>{Info.level}</p>
            </div>
            <div className="vertical-border bg-sb-900"></div>
            <div className="icon-box flex gap-1">
                <p className='font-[Generica]'>Intensidad:</p>
                <img src="../../assets/icons/Forms/Level-4.svg" alt="clock-icon" />
            </div>
        </div>
    </div>
  </>
}

export default CardBoxVideo